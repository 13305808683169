"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangePassword400ResponseToJSON = exports.ChangePassword400ResponseFromJSONTyped = exports.ChangePassword400ResponseFromJSON = exports.instanceOfChangePassword400Response = void 0;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the ChangePassword400Response interface.
 */
function instanceOfChangePassword400Response(value) {
    let isInstance = true;
    return isInstance;
}
exports.instanceOfChangePassword400Response = instanceOfChangePassword400Response;
function ChangePassword400ResponseFromJSON(json) {
    return ChangePassword400ResponseFromJSONTyped(json, false);
}
exports.ChangePassword400ResponseFromJSON = ChangePassword400ResponseFromJSON;
function ChangePassword400ResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'errorCode': !(0, runtime_1.exists)(json, 'errorCode') ? undefined : json['errorCode'],
        'errorMessage': !(0, runtime_1.exists)(json, 'errorMessage') ? undefined : json['errorMessage'],
    };
}
exports.ChangePassword400ResponseFromJSONTyped = ChangePassword400ResponseFromJSONTyped;
function ChangePassword400ResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'errorCode': value.errorCode,
        'errorMessage': value.errorMessage,
    };
}
exports.ChangePassword400ResponseToJSON = ChangePassword400ResponseToJSON;
