import { createModel } from '@rematch/core'

const general = createModel()({
  state: {},
  reducers: {},
  effects: () => ({
    // async sendContactMessage (contactMessage) {
    //   await guestApi.sendContactMessage({
    //     contactMessage
    //   })
    // }
  })
})

export default general
