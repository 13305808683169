import { Gallery, Item } from 'react-photoswipe-gallery'
import 'photoswipe/dist/photoswipe.css'
import classNames from 'classnames'
import { createRef, useEffect, useState } from 'react'
import IconUlesU from '../../assets/images/icons/ules_u.jpg'
import IconUlesBankett from '../../assets/images/icons/ules_bankett.jpg'
import IconUlesParty from '../../assets/images/icons/ules_party.jpg'
import IconUlesSzek from '../../assets/images/icons/ules_szek.jpg'
import IconSqm from '../../assets/images/icons/sqm.jpg'
import IconAirCondition from '../../assets/images/icons/icon_aircondition.jpg'
import IconDark from '../../assets/images/icons/icon_dark.jpg'
import IconInternet from '../../assets/images/icons/icon_internet.jpg'
import IconSun from '../../assets/images/icons/icon_sun.jpg'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api'
import IconMarker from '../../assets/images/marker.png'
import ContentLoader from 'react-content-loader'

import { useDispatch, useSelector } from 'react-redux'

import { Element, Link } from 'react-scroll'

const DetailComponent = () => {
  const [searchParams] = useSearchParams()
  const specialoffer = searchParams.get('specialoffer')
  const chunk = (arr, size) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
    )

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { favorites, callForOffer } = useSelector(state => state.venue)

  const handleOnCallForOffer = (venue) => {
    dispatch.venue.setStateCallForOffer(venue)
  }
  const myInputRef = []

  const setref = (ref, offerKey) => {
    const index = myInputRef.push({ ref: createRef(), offerKey })
    myInputRef[index - 1].ref.current = ref
  }

  const handleOnFavorite = (venue) => {
    dispatch.venue.setStateFavorites(venue)
  }

  const PlaceLoader = (props) => (
    <ContentLoader
      viewBox="0 0 400 800"
      height={'100vh'}
      width={400}
      backgroundColor="transparent"
      {...props}
    >
      <circle cx="150" cy="86" r="8" />
      <circle cx="194" cy="86" r="8" />
      <circle cx="238" cy="86" r="8" />
    </ContentLoader>
  )

  const { venueSlug } = useParams()

  const { venue } = useSelector(state => state.venue)

  useEffect(() => {
    window.scroll(0, 0)
    dispatch.venue.getVenue(venueSlug)
  }, [dispatch, venueSlug])

  const [firstRun, setFirstRun] = useState(true)

  useEffect(() => {
    if ((myInputRef.length && specialoffer != null && firstRun)) {
      setFirstRun(false)
      const index = myInputRef.findIndex((item) => item.offerKey === parseInt(specialoffer))
      if (index > -1) {
        myInputRef[index].ref.current.scrollIntoView({
          behavior: 'auto',
          block: 'start',
          inline: 'nearest'
        })

        window.scrollBy(0, -200)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myInputRef])

  const [mapInstance, setMapInstance] = useState(null)
  const [isVisibleModal, setIsVisibleModal] = useState(false)
  const handleVisibleModal = (venueId) => {
    setIsVisibleModal(true)
    dispatch.venue.clickContactData({ venueId })
  }

  const containerStyle = {
    width: '100%',
    height: '350px'
  }

  useEffect(() => {
    if (mapInstance) {
      mapInstance.setCenter(venue.location)
    }
    /*
    if (myInputRef.length && myInputRef[0].current != null) {
      myInputRef[0].current.scrollIntoView()
    }
    */
  }, [mapInstance, venue])

  const checkExist = (type) => {
    switch (type) {
    case 'programs':
      return venue.programs !== ''
    case 'requests':
      return venue.specialOffers.length !== 0
    case 'accommodation':
      return venue.accommedation.bedSum !== 0
    case 'catering':
      return venue.catering.description !== ''
    default:
      return true
    }
  }

  const numOfGalleryImages = 7
  return (

    (venue === null || venue.venueSlug !== venueSlug) ? <section><PlaceLoader/></section>

      : <>
        <div className={classNames('main-register-wrap modal', { 'd-block': isVisibleModal })} style={{ zIndex: 1200 }}>
          <div className="reg-overlay"></div>
          <div className="main-register-holder" style={{ marginTop: '24em' }}>
            <div className="main-register fl-wrap">
              <div className="close-reg color-bg" onClick={() => setIsVisibleModal(false)}>
                <i className="fal fa-times"></i>
              </div>
              {/* tabs */}
              <div id="tabs-container">
                <div className="tab">
                  {/* tab */}
                  <div id="tab-1" className="tab-content d-block">
                    <h3>{venue.name} </h3>
                    <div className="custom-form">
                      <div className="box-widget-list">
                        <ul>
                          <li><span><i className="fal fa-map-marker"></i>Város:</span> {venue.contact.city}</li>
                          <li><span><i className="fal fa-map-marker"></i>Cím:</span> {venue.contact.address}</li>
                          <li><span><i className="fal fa-user"></i>Kapcsolattartó neve:</span> {venue.contact.contactPerson}</li>
                          <li><span><i className="fal fa-phone"></i>Kapcsolattartó telefon:</span> {venue.contact.phone}</li>
                          <li><span><i className="fal fa-browser"></i>Céges weboldal:</span><a href={venue.contact.corporateWebsite} target="_blank" rel="noreferrer">{venue.contact.corporateWebsite}</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* tab end */}
                </div>
                {/* tabs end */}
              </div>
            </div>
          </div>
        </div>
        <div className="grey-blue-bg">
          <div className="list-single-hero" style={{ backgroundImage: `url(${venue.highlightedimage})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>

            <div className="list-single-hero-title fl-wrap">
              <div className="container">
                <div className="row">
                  <div className="col-md-7">
                    <div className="listing-rating-wrap">
                      <div className="listing-rating card-popup-rainingvis" data-starrating2="5"></div>
                    </div>
                    <h2><span>{venue.name}</span></h2>
                    <div className="list-single-header-contacts fl-wrap">
                      <ul>
                        <li><i className="far fa-phone"></i><a href="#">{venue.contact.mobile}</a></li>
                        <li><i className="far fa-map-marker-alt"></i><a href="#">{venue.contact.city}</a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-5 d-flex align-items-end justify-content-end mb-3">
                    {/*  list-single-hero-details */}
                    <div className="ignore-offer-hide list-single-hero-links">
                      <a className="ignore-offer-hide lisd-link" onClick={() => handleOnCallForOffer(venue)}><i className={(callForOffer.find(item => item.venueSlug === venueSlug) ? 'fas' : 'fal') + ' fa-bookmark'}></i>{t('detail:request-for')}</a>
                    </div>
                    {/*  list-single-hero-links end */}
                    <div className="ignore-favorite-hide  list-single-hero-links">
                      <a className="ignore-favorite-hide lisd-link" onClick={() => handleOnFavorite(venue)}><i className={(favorites.find(item => item.venueSlug === venueSlug) ? 'fas' : 'fal') + ' fa-heart'}></i>{t('detail:favorite')}</a>
                    </div>
                    {/*  list-single-hero-details  end */}
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className="grey-blue-bg sticky-top scroll-nav-container p-0" style={{ top: 118 }}>
            {/*  scroll-nav-wrapper  */}
            <div className={classNames('scroll-nav-wrapper with-border w-100 d-block position-sticky py-3 ')}>
              <div className="container-xl">

                <nav className="scroll-nav d-flex justify-content-center" >
                  <ul className="justify-content-center  flex-wrap  ">
                    <li><Link activeClass="active" offset={-170} to="test1" spy={true} smooth={true} duration={500} ><div>{t('detail:quick-nav.top')}</div></Link></li>
                    <li><Link activeClass="active" offset={-170} to="test2" spy={true} smooth={true} duration={500}><div>{t('detail:quick-nav.accessibility')}</div></Link></li>
                    <li><Link activeClass="active" offset={-170} to="test3" spy={true} smooth={true} duration={500} ><div>{t('detail:quick-nav.place')}</div></Link></li>
                    <li><Link activeClass="active" offset={-170} to="map" spy={true} smooth={true} duration={500} ><div>{t('detail:map-title')}</div></Link></li>

                    {checkExist('accommodation') && <li><Link activeClass="active" offset={-170} to="test4" spy={true} smooth={true} duration={500}><div>{t('detail:quick-nav.accommodation')}</div></Link></li> }
                    {checkExist('catering') && <li><Link activeClass="active" offset={-170} to="test5" spy={true} smooth={true} duration={500}><div>{t('detail:quick-nav.catering')}</div></Link></li>}
                    <li><Link activeClass="active" offset={-170} to="test6" spy={true} smooth={true} duration={500}><div> {t('detail:quick-nav.rooms')}</div></Link></li>
                    {checkExist('programs') && <li><Link activeClass="active" offset={-170} to="test7" spy={true} smooth={true} duration={500}><div>   {t('detail:quick-nav.programs')}</div></Link></li>}
                    {checkExist('requests') && <li><Link activeClass="active" offset={-170} to="test8" spy={true} smooth={true} duration={500}><div>   {t('detail:quick-nav.requests-for')}</div></Link></li>}
                    <li className="contact-button"><div onClick={() => handleVisibleModal(venue.id)}>   {t('detail:contact-information')}</div></li>

                  </ul>
                  {/* <p className="ms-auto p-0 fs-6 px-3" style={{ color: 'black' }}>{venue.name}</p> */}
                </nav>

              </div>
            </div>
          </div>

          <div className="container">
            <div className="row pt-5" >
              <div className="col-md-12">

                <Element name="test1" >

                  <div className="list-single-main-media" id="sec2">
                    {/* gallery-items */}
                    <Gallery withCaption>
                      <div className="detail-container" >
                        {/* 1 */}
                        {venue.images.map((image, key) =>

                          <Item

                            cropped
                            key={key}
                            caption={image.description}
                            thumbnail={image.thumbnail}
                            original={image.original}
                            width={image.details.original.width}
                            height={image.details.original.height}
                          >
                            {({ ref, open }) => (
                              <div className={classNames('gallery-item w-100 detail', { 'd-none': key > numOfGalleryImages })} >
                                <div className="box-item" onClick={open}>
                                  <img ref={ref} src={image.thumbnail} />
                                  {key === numOfGalleryImages
                                    ? <div onClick={open} className="more-photos-button dynamic-gal">{t('detail:more-photos')} <span>{venue.images.length} {t('detail:number-of-photos')}</span><i className="far fa-long-arrow-right"></i></div>
                                    : <div className="gal-link popup-image" onClick={open}><i className="fal fa-search"></i></div>}
                                </div>
                              </div>
                            )}
                          </Item>

                        )

                        }
                      </div>
                    </Gallery>
                    {/* end gallery items */}
                  </div>
                </Element>
                {venue.accessibility.car !== '' && venue.accessibility.bus !== '' && venue.accessibility.train !== '' &&
                <Element name="test2">

                  {/*   list-single-main-item */}

                  <div className="list-single-main-item">
                    <div className="list-single-main-item-title fl-wrap">
                      <h3>{t('detail:accessibility')}</h3>
                    </div>
                    {venue.accessibility?.car &&
                        <div>
                          <h4 className="text-start fs-6 mb-3">{t('detail:by-car')}</h4>
                          <p>{venue.accessibility.car}</p>
                        </div>
                    }

                    {venue.accessibility?.bus &&
                        <div>
                          <h4 className="text-start fs-6 mb-3">{t('detail:by-bus')}</h4>
                          <p>{venue.accessibility.bus}</p>
                        </div>
                    }

                    {venue.accessibility?.train &&
                        <div>
                          <h4 className="text-start fs-6 mb-3">{t('detail:by-train')}</h4>
                          <p>{venue.accessibility.train}</p>
                        </div>
                    }
                  </div>

                </Element>
                }
                <Element name="test3">
                  <div className="list-single-main-item " id="sec3">
                    <div className="list-single-main-item-title fl-wrap">
                      <h3>{t('detail:place')}</h3>
                    </div>
                    <h4 className="text-start fs-6 mb-4">
                      {t('detail:type-of-place')}
                    </h4>
                    <div className="listing-features fl-wrap mb-2">
                      <ul>
                        {venue.place.feature.map((feature, key) => <li key={key}><i className="fal fa-check"></i>{feature}</li>)}
                      </ul>
                    </div>
                    <h4 className="text-start fs-6 mb-3">
                      {t('detail:place-desc')}
                    </h4>
                    <p>
                      {venue.place.description}
                    </p>
                    <h4 className="text-start fs-6 mb-3">
                      {t('detail:event-can-be-organized')}
                    </h4>
                    <div className="table-responsive">
                      <table className="details-table table table-striped table-borderless">
                        <thead>
                          <tr>
                            {venue.place.events.map((event, key) => <th key={ key } scope="col">{event.name}</th>)}
                          </tr>
                        </thead>
                        <tbody>
                          {
                            [0, 1, 2, 3, 4, 5, 6].map((column) =>
                              <tr key={ column }>
                                {venue.place.events.map((event, key) => <td key={key + '_' + column } className={event.items[column]?.ready ? null : 'opacity-25' }>{event.items[column]?.name}</td>
                                )}
                              </tr>
                            )
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Element>

                <Element name="map">
                  <div className="list-single-main-item ">
                    <div className="list-single-main-item-title fl-wrap">
                      <h3>{t('detail:map-title')}</h3>
                    </div>
                    <LoadScript
                      googleMapsApiKey="AIzaSyCSi5Dstx0WKFpSVMg_M6bzLnWY-553wWs"
                    >
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        zoom={11}

                        onLoad={(map) => setTimeout(() => setMapInstance(map))}
                      >
                        {mapInstance ? (
                          <MarkerF
                            position={venue.location}
                            icon={IconMarker}
                            animation={false}
                          >
                          </MarkerF>
                        ) : null}
                        <></>
                      </GoogleMap>
                    </LoadScript>
                  </div>
                </Element>
                {checkExist('accommodation') &&
                <Element name="test4" >
                  <div className="list-single-main-item" id="sec4">
                    <div className="list-single-main-item-title fl-wrap">
                      <h3>{t('detail:accommodation-title')}</h3>
                    </div>
                    <h4 className="text-start fs-6 mb-3">
                      {t('detail:accommodation-desc')}
                    </h4>
                    <p>{venue.accommedation.description}</p>
                    <h4 className="text-start fs-6 mb-3 mt-2">{t('detail:accommodation-option')}</h4>
                    <div className="row">
                      <div className="col">
                        <h5 className="text-start fs-7 mb-1">
                          {t('detail:accommodation-possibilities')}
                        </h5>
                        <div className="table-responsive">
                          <table className="details-table table table-striped table-borderless">
                            <thead>
                              <tr>
                                <th scope="col">{t('detail:room-name')}</th>
                                <th scope="col">{t('detail:room-number')}</th>
                                <th scope="col">{t('detail:bed-number')}</th>
                                <th scope="col">{t('detail:sum-bed-number')}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {venue.accommedation.details.map((rooms, key) => <tr key={key}>
                                <td>{rooms.name}</td>
                                <td>{rooms.roomNum}</td>
                                <td>{rooms.bedNum}</td>
                                <td>{rooms.bedSum}</td>
                              </tr>

                              )}

                              <tr>
                                <td>Összes szálláshely</td>
                                <td>{venue.accommedation.roomSum}</td>
                                <td></td>
                                <td>{venue.accommedation.bedSum}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                    </div>
                  </div>
                </Element>
                }
                {checkExist('catering') &&
                <Element name="test5" >
                  <div className="list-single-main-item " id="sec5">
                    <div className="list-single-main-item-title fl-wrap">
                      <h3>{t('detail:catering-title')}</h3>
                    </div>
                    <h4 className="text-start fs-6 mb-3">{t('detail:catering-desc')}</h4>
                    <p>{venue.catering.description}</p>
                    <div className="text-start">
                      <span style={{ paddingRight: 4 }}>{t('common:filter.cateringType.external-kitchen')}</span><i className={venue.catering.external ? 'fal fa-check text-blue' : 'fal fa-times text-red'}></i>
                      <span style={{ paddingLeft: 10, paddingRight: 4 }} >{t('common:filter.cateringType.own-kitchen')}</span><i className={venue.catering.local ? 'fal fa-check text-blue' : 'fal fa-times text-red'}></i>

                    </div>
                  </div>
                </Element>
                }

                <Element name="test6">
                  <div className="list-single-main-item" id="sec6">
                    <div className="list-single-main-item-title fl-wrap">
                      <h3>{t('detail:halls')}</h3>
                    </div>
                    <h4 className="text-start fs-6 mb-3">
                      {t('detail:function-rooms')}
                    </h4>
                    <div className="table-responsive">
                      <table className="table table-borderless">
                        <thead>
                          <tr className="detail-icons">
                            <th scope="col">{t('detail:hall-room-name')}</th>
                            <th scope="col"><div><img src={IconSqm} width={30} /><span className="icon-title">{t('detail:hall-floorSpace')}</span></div></th>
                            <th scope="col"><div><img src={IconUlesSzek} width={30} /><span className="icon-title">{t('detail:hall-benches')}</span></div></th>
                            <th scope="col"><div><img src={IconUlesU} width={30} /><span className="icon-title">{t('detail:hall-uShaped')}</span></div></th>
                            <th scope="col"><div><img src={IconUlesParty} width={30} /><span className="icon-title">{t('detail:hall-standing')}</span></div></th>
                            <th scope="col"><div><img src={IconUlesBankett} width={30} /><span className="icon-title">{t('detail:hall-banquet')}</span></div></th>
                            <th scope="col"><div><img src={IconAirCondition} width={30} /><span className="icon-title">{t('detail:hall-clima')}</span></div></th>
                            <th scope="col"><div><img src={IconDark} width={30} /><span className="icon-title">{t('detail:hall-dimmable')}</span></div></th>
                            <th scope="col"><div><img src={IconSun} width={30} /><span className="icon-title">{t('detail:hall-lightOfDay')}</span></div></th>
                            <th scope="col"><div><img src={IconInternet} width={30} /><span className="icon-title">{t('detail:hall-internet')}</span></div></th>

                          </tr>
                        </thead>
                        <tbody>
                          {venue.conferenceRoom.rooms.map((room, key) =>

                            <tr key={key}>
                              <td>{room.name}</td>
                              <td>{room.floorSpace}</td>
                              <td>{room.benches}</td>
                              <td>{room.uShaped}</td>
                              <td>{room.standing}</td>
                              <td>{room.banquet}</td>
                              <td><i className={room.clima ? 'fal fa-check text-blue' : 'fal fa-times text-red'}></i></td>
                              <td><i className={room.dimmable ? 'fal fa-check text-blue' : 'fal fa-times text-red'}></i></td>
                              <td><i className={room.lightOfDay ? 'fal fa-check text-blue' : 'fal fa-times text-red'}></i></td>
                              <td><i className={room.internet ? 'fal fa-check text-blue' : 'fal fa-times text-red'}></i></td>
                            </tr>

                          )}

                        </tbody>
                      </table>
                    </div>
                    {venue.conferenceRoom.description !== '' &&
                        <div>
                          <h4 className="text-start fs-6 my-3">{t('detail:additional-room-info')}</h4>
                          <p>{venue.conferenceRoom.description}</p>
                        </div>
                    }

                    <h4 className="text-start fs-6 mb-3 mt-2">{t('detail:technical-equipment')}</h4>
                    <div className="table-responsive">
                      <table className="table table-borderless text-start">
                        <tbody>
                          {chunk(venue.conferenceRoom.technical, 3).map((tech, key) =>
                            <tr key={key}>
                              <td><i className={tech[0]?.ready ? 'fal me-2 fa-check text-blue' : 'fal me-2 fa-times text-red'}></i> {tech[0]?.name}</td>
                              <td><i className={tech[1]?.ready ? 'fal me-2 fa-check text-blue' : 'fal me-2 fa-times text-red'}></i> {tech[1]?.name}</td>
                              <td><i className={tech[2]?.ready ? 'fal me-2 fa-check text-blue' : 'fal me-2 fa-times text-red'}></i> {tech[2]?.name}</td>
                            </tr>
                          )}

                        </tbody>
                      </table>
                    </div>
                    {venue.conferenceRoom.chair !== 0 && venue.conferenceRoom.table !== 0 &&
                    <span className="text-start d-block">
                      {t('detail:conference-chair')}: <strong className="fw-bolder">{venue.conferenceRoom.chair}  {t('common:piece')}</strong>, {t('detail:conference-table')}: <strong className="fw-bolder">{venue.conferenceRoom.table}{t('common:piece')}</strong>
                    </span>
                    }
                  </div>

                </Element>
                {checkExist('programs') &&
                <Element name="test7">
                  <div className="list-single-main-item " id="sec7">
                    <div className="list-single-main-item-title fl-wrap">
                      <h3>{t('detail:programs')}</h3>
                    </div>
                    <h4 className="text-start fs-6 mb-3">{t('detail:program-options')}</h4>
                    <p>{venue.programs}</p>
                  </div>

                </Element>
                }
                {checkExist('requests') &&
                <Element name="test8">
                  <div className="list-single-main-item" id="sec8">
                    <div className="list-single-main-item-title fl-wrap">
                      <h3>{t('detail:sales')}</h3>
                    </div>
                    <h4 className="text-start fs-6 mb-3">{t('detail:action-description')}</h4>
                    {venue.specialOffers.map((offer, offerkey) =>
                      <div id={'offer_id_' + offer.id} ref={(ref) => setref(ref, offer.id)}className="pb-3" key={offerkey}>
                        <div className="d-flex justify-content-between">
                          <h4 className="text-start fs-6 mb-2">{offer.name}</h4>
                          <div>{offer.eventTimeFrom} - {offer.eventTimeTo}</div>
                        </div>
                        <p>{offer.description}</p>
                        <span className="text-start d-block">
                          <strong>{t('detail:special-eventTypes')}</strong><p>{offer.eventType.map((event, key) => { return (event.ready === true ? <span key={key}><i className="fal fa-check text-blue mr-3  me-1"></i>{event.name} </span> : null) })}</p>
                        </span>
                        <span className="text-start d-block">
                          <strong>{t('detail:special-actionType')}</strong><p>{offer.type.map((event, key) => { return (event.ready === true ? <span key={key}><i className="fal fa-check text-blue mr-3  me-1"></i>{event.name} </span> : null) })}</p>
                        </span>
                        <span className="text-start d-block">
                          <strong>{t('detail:special-actionCategory')}</strong><p>{offer.category.map((event, key) => { return (event.ready === true ? <span key={key}><i className="fal fa-check text-blue mr-3  me-1"></i>{event.name} </span> : null) })}</p>
                        </span>
                      </div>

                    )}
                  </div>

                </Element>
                }
              </div>

            </div>
          </div>
        </div>
      </>

  )
}

export default DetailComponent
