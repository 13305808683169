"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VenueResourceImagesInnerDetailsToJSON = exports.VenueResourceImagesInnerDetailsFromJSONTyped = exports.VenueResourceImagesInnerDetailsFromJSON = exports.instanceOfVenueResourceImagesInnerDetails = void 0;
const VenueResourceImagesInnerDetailsOriginal_1 = require("./VenueResourceImagesInnerDetailsOriginal");
/**
 * Check if a given object implements the VenueResourceImagesInnerDetails interface.
 */
function instanceOfVenueResourceImagesInnerDetails(value) {
    let isInstance = true;
    isInstance = isInstance && "original" in value;
    isInstance = isInstance && "thumbnail" in value;
    return isInstance;
}
exports.instanceOfVenueResourceImagesInnerDetails = instanceOfVenueResourceImagesInnerDetails;
function VenueResourceImagesInnerDetailsFromJSON(json) {
    return VenueResourceImagesInnerDetailsFromJSONTyped(json, false);
}
exports.VenueResourceImagesInnerDetailsFromJSON = VenueResourceImagesInnerDetailsFromJSON;
function VenueResourceImagesInnerDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'original': (0, VenueResourceImagesInnerDetailsOriginal_1.VenueResourceImagesInnerDetailsOriginalFromJSON)(json['original']),
        'thumbnail': (0, VenueResourceImagesInnerDetailsOriginal_1.VenueResourceImagesInnerDetailsOriginalFromJSON)(json['thumbnail']),
    };
}
exports.VenueResourceImagesInnerDetailsFromJSONTyped = VenueResourceImagesInnerDetailsFromJSONTyped;
function VenueResourceImagesInnerDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'original': (0, VenueResourceImagesInnerDetailsOriginal_1.VenueResourceImagesInnerDetailsOriginalToJSON)(value.original),
        'thumbnail': (0, VenueResourceImagesInnerDetailsOriginal_1.VenueResourceImagesInnerDetailsOriginalToJSON)(value.thumbnail),
    };
}
exports.VenueResourceImagesInnerDetailsToJSON = VenueResourceImagesInnerDetailsToJSON;
