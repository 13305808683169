"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilteredVenuesResourceDataInnerToJSON = exports.FilteredVenuesResourceDataInnerFromJSONTyped = exports.FilteredVenuesResourceDataInnerFromJSON = exports.instanceOfFilteredVenuesResourceDataInner = void 0;
const runtime_1 = require("../runtime");
const FilteredVenuesResourceDataInnerAddress_1 = require("./FilteredVenuesResourceDataInnerAddress");
const FilteredVenuesResourceDataInnerLocation_1 = require("./FilteredVenuesResourceDataInnerLocation");
const FilteredVenuesResourceDataInnerRooms_1 = require("./FilteredVenuesResourceDataInnerRooms");
/**
 * Check if a given object implements the FilteredVenuesResourceDataInner interface.
 */
function instanceOfFilteredVenuesResourceDataInner(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "venueSlug" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "highlightedimage" in value;
    isInstance = isInstance && "rooms" in value;
    isInstance = isInstance && "location" in value;
    return isInstance;
}
exports.instanceOfFilteredVenuesResourceDataInner = instanceOfFilteredVenuesResourceDataInner;
function FilteredVenuesResourceDataInnerFromJSON(json) {
    return FilteredVenuesResourceDataInnerFromJSONTyped(json, false);
}
exports.FilteredVenuesResourceDataInnerFromJSON = FilteredVenuesResourceDataInnerFromJSON;
function FilteredVenuesResourceDataInnerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'venueSlug': json['venue_slug'],
        'name': json['name'],
        'address': (0, FilteredVenuesResourceDataInnerAddress_1.FilteredVenuesResourceDataInnerAddressFromJSON)(json['address']),
        'description': json['description'],
        'highlightedimage': json['highlightedimage'],
        'url': !(0, runtime_1.exists)(json, 'url') ? undefined : json['url'],
        'bannerId': !(0, runtime_1.exists)(json, 'banner_id') ? undefined : json['banner_id'],
        'rooms': (0, FilteredVenuesResourceDataInnerRooms_1.FilteredVenuesResourceDataInnerRoomsFromJSON)(json['rooms']),
        'location': (0, FilteredVenuesResourceDataInnerLocation_1.FilteredVenuesResourceDataInnerLocationFromJSON)(json['location']),
    };
}
exports.FilteredVenuesResourceDataInnerFromJSONTyped = FilteredVenuesResourceDataInnerFromJSONTyped;
function FilteredVenuesResourceDataInnerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'venue_slug': value.venueSlug,
        'name': value.name,
        'address': (0, FilteredVenuesResourceDataInnerAddress_1.FilteredVenuesResourceDataInnerAddressToJSON)(value.address),
        'description': value.description,
        'highlightedimage': value.highlightedimage,
        'url': value.url,
        'banner_id': value.bannerId,
        'rooms': (0, FilteredVenuesResourceDataInnerRooms_1.FilteredVenuesResourceDataInnerRoomsToJSON)(value.rooms),
        'location': (0, FilteredVenuesResourceDataInnerLocation_1.FilteredVenuesResourceDataInnerLocationToJSON)(value.location),
    };
}
exports.FilteredVenuesResourceDataInnerToJSON = FilteredVenuesResourceDataInnerToJSON;
