import useFilterType from 'hooks/useFilterType'
import { useTranslation } from 'react-i18next'
import SelectWidget from 'widgets/SelectWidget'
import { useEffect, useState } from 'react'
import UlesU from '../../assets/images/icons/ules_u.jpg'
import UlesBankett from '../../assets/images/icons/ules_bankett.jpg'
import UlesParty from '../../assets/images/icons/ules_party.jpg'
import UlesSzek from '../../assets/images/icons/ules_szek.jpg'
import { useDispatch, useSelector } from 'react-redux'
import ContentLoader from 'react-content-loader'
import { useNavigate } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import InputNumber from 'rc-input-number'

import { toast } from 'react-toastify'

import 'react-datepicker/dist/react-datepicker.css'

const RequestComponent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    conditionTypes
  } = useFilterType()

  const [request, setRequest] = useState(
    {
      subject: '',
      contactPerson: '',
      email: '',
      phone: '',
      companyName: '',
      fax: '',
      city: '',
      address: '',
      eventStart: new Date(),
      eventEnd: new Date(),
      numberOfParticipants: 1,
      numberOfRoomsRequired: 1,
      accommodation: false,
      requiredAccommodation: '',
      technicalRequirements: '',
      otherRequirements: '',
      validityOfferDate: 7,
      requestCallback: false
    }
  )

  const PlaceLoader = (props) => (
    <ContentLoader
      speed={2}
      width={'100%'}
      height={440}
      viewBox="0 0 300 400"
      {...props}
    >
      <circle cx="31" cy="31" r="15" />
      <rect x="58" y="18" rx="2" ry="2" width="140" height="10" />
      <rect x="58" y="34" rx="2" ry="2" width="140" height="10" />
      <rect x="0" y="60" rx="2" ry="2" width="400" height="400" />
    </ContentLoader>
  )
  const [disableSubmit, setDisableSumbit] = useState(false)
  const [requestVenues, setRequestVenues] = useState(null)
  const { callForOffer } = useSelector(state => state.venue)

  useEffect(() => {
    if (!callForOffer.length) { navigate('/') }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callForOffer])

  const [firstRun, setFirstRun] = useState(true)

  useEffect(() => {
    if (firstRun) {
      setFirstRun(false)
      window.scroll(0, 0)
      async function fetchData () {
        const response = await dispatch.venue.getRequestVenues()
        setRequestVenues(response.data)
      }
      fetchData()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, callForOffer])

  const handleSendRequest = (event) => {
    async function fetchData () {
      const response = await dispatch.venue.saveRequestOffer(request)
      if (typeof response.errors !== 'undefined') {
        toast.warning(response.errors.errorMessage)
      } else {
        navigate('/')
        toast.success(response.message)
      }
    }
    event.preventDefault()
    fetchData()
  }

  const navigateToDetail = (venueSlug) => {
    navigate('/' + venueSlug)
  }

  const testSubmit = (e) => {
    e.preventDefault()
    setDisableSumbit(true)
    e.target.form?.requestSubmit()

    if (!e.target.form?.checkValidity()) { window.scrollTo(0, 0); setDisableSumbit(false) }
  }

  return (
    <div className="content">

      <section className="middle-padding gre y-blue-bg">
        <div className="container">

          <div className="bookiing-form-wrap">

            <div className="section-title"><h2>{t('request:title')}</h2>
              <span className="section-separator"></span>
            </div>
            {/*   list-single-main-item */}
            <div className="list-single-main-item p-0 fl-wrap hidden-section tr-sec">
              <div className="profile-edit-container">
                <div className="custom-form w-100">
                  <form onSubmit={handleSendRequest}>
                    <fieldset className="fl-wrap book_mdf">
                      <div className="row">
                        <div className="col-sm-6">
                          <label className="form-required">{t('request:subject')} </label>
                          <input value={request.subject} type="text" onChange={e => setRequest({ ...request, subject: e.target.value })} required />
                        </div>
                        <div className="col-sm-6">
                          <label className="form-required">{t('request:contact-name')} </label>
                          <input value={request.contactPerson} type="text" onChange={e => setRequest({ ...request, contactPerson: e.target.value })} required />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-6">
                          <label className="form-required">{t('request:email')} </label>
                          <input value={request.email} type="email" onChange={e => setRequest({ ...request, email: e.target.value })} required />
                        </div>
                        <div className="col-sm-6">
                          <label className="form-required">{t('request:phone')}  </label>
                          <input value={request.phone} type="text" onChange={e => setRequest({ ...request, phone: e.target.value })} required />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-6">
                          <label className="form-required">{t('request:company')}  </label>
                          <input value={request.companyName} type="text" onChange={e => setRequest({ ...request, companyName: e.target.value })} required />
                        </div>
                        <div className="col-sm-6">
                          <label>{t('request:fax')}</label>
                          <input value={request.fax} type="text" onChange={e => setRequest({ ...request, fax: e.target.value })} />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-6">
                          <label>{t('request:city')}</label>
                          <input value={request.city} type="text" onChange={e => setRequest({ ...request, city: e.target.value })} />
                        </div>
                        <div className="col-sm-6">
                          <label>{t('request:address')} </label>
                          <input value={request.address} type="text" onChange={e => setRequest({ ...request, address: e.target.value })} />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-6">
                          <label>{t('request:event-start')} </label>

                          <DatePicker minDate={new Date()} selected={request.eventStart} onChange={(date) => setRequest({ ...request, eventStart: date })} />

                        </div>
                        <div className="col-sm-6">
                          <label>{t('request:event-end')} </label>
                          <DatePicker minDate={new Date()} selected={request.eventEnd} onChange={(date) => setRequest({ ...request, eventEnd: date })} />

                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-6 text-start">
                          <label>{t('request:number-of-people')} </label>
                          <InputNumber value={request.numberOfParticipants} min={1} onChange={num =>
                            setRequest({ ...request, numberOfParticipants: num })} />
                        </div>
                        <div className="col-sm-6 text-start">
                          <label>{t('request:number-of-rooms')} </label>
                          <InputNumber value={request.numberOfRoomsRequired} min={1} onChange={num =>
                            setRequest({ ...request, numberOfRoomsRequired: num })} />
                        </div>
                      </div>

                      <div className="row pt-3">
                        <div className="col-sm-6">
                          <label>{t('request:accommodation')}</label>
                          <SelectWidget
                            defaultValue={conditionTypes[1]}
                            placeholder={t('common:select-item')}
                            options={conditionTypes}
                            onChange={e =>
                              setRequest({ ...request, accommodation: e.value === 'yes' })
                            }
                          />
                        </div>
                        <div className="col-sm-6 text-start">
                          <label>{t('request:accommodation-required')} </label>

                          <InputNumber value={request.requiredAccommodation} defaultValue={0} min={0} onChange={num =>
                            setRequest({ ...request, requiredAccommodation: num })} disabled={!request.accommodation} />

                        </div>
                      </div>

                      <div className="row pt-3">
                        <div className="col-sm-6">
                          <label>{t('request:technical-requirements')}</label>
                          <textarea value={request.technicalRequirements} onChange={e => setRequest({ ...request, technicalRequirements: e.target.value })} />
                        </div>
                        <div className="col-sm-6">
                          <label>{t('request:other-requirements')}</label>
                          <textarea value={request.otherRequirements} onChange={e => setRequest({ ...request, otherRequirements: e.target.value })} />
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-sm-6">
                          <label>{t('request:validity-days')}</label>
                          <SelectWidget
                            defaultValue={{ value: '7', label: '7 nap' }}
                            placeholder={t('common:select-item')}
                            options={[{ value: '7', label: '7 nap' }, { value: '14', label: '14 nap' }]}
                            onChange={e => setRequest({ ...request, validityOfferDate: e.value })}

                          />
                        </div>
                        <div className="col-sm-6">
                          <div className="filter-tags d-flex align-items-center ask-callback">
                            <input id="check-a" className="m-0" type="checkbox" name="check" onChange={e =>
                              setRequest({ ...request, requestCallback: e.target.value === 'on' })}
                            />
                            <label htmlFor="check-a" className="text-gray m-0">{t('request:ask-callback')}</label>
                          </div>
                        </div>
                      </div>

                      <span className="fw-separator"></span>
                      <button type="submit" disabled={disableSubmit} onClick={testSubmit} className="next-form action-button btn no-shdow-btn color-bg cursor-pointer">{t('request:send-request')}  <i className="fal fa-angle-right"></i></button>
                    </fieldset>

                  </form>
                </div>

              </div>
            </div>
            {/*   list-single-main-item end */}
          </div>

        </div>
      </section>

      {/* section end */}
      <section className="pt-0">
        <div className="container px-md-0">
          <div className="row">
            { requestVenues === null ? <div className="listing-item col-lg-4"><PlaceLoader /></div>
              : requestVenues.map((place) => {
                return (
                  <div key={place.id} className={'listing-item col-lg-4 hasOneColumn '}>
                    <article className="geodir-category-listing fl-wrap" >
                      <div className="geodir-category-img">
                        <img className="cursor-pointer" src={place.highlightedimage} onClick={() => { navigateToDetail(place.venueSlug) } } alt="" />
                        <div className="geodir-category-opt">
                        </div>
                      </div>
                      <div className="geodir-category-content fl-wrap">
                        <div className="geodir-category-content-title fl-wrap">
                          <div className="geodir-category-content-title-item">
                            <h3 className="title-sin_map cursor-pointer" >{place.name} </h3>
                            <div className="geodir-category-location fl-wrap"><a className="map-item"><i className="fas fa-map-marker-alt"></i>{place.address.city}</a></div>
                          </div>
                        </div>
                        <div>
                          <p className="highlited-description" style={{ width: '100%', padding: 0, marginBottom: 5 }}>{place.description}</p>
                        </div>
                        <div className="dotted-separator"></div>
                        <div className="d-flex flex-column">
                          <span className="pe-3 text-capacity">{t('listing:hall-capacity')}</span>

                          <div className="d-flex align-items-center mw-100 overflow-x-auto justify-content-center">

                            <ul className="facilities-list">
                              <li>
                                <img src={UlesSzek} />
                                <span className="facility-tooltip">Széksoros ültetés (fő)</span>
                                <span className="facility-count">{place.rooms.benches}</span>
                              </li>
                              <li>
                                <img src={UlesU} />
                                <span className="facility-tooltip">U-ültetés (fő)</span>
                                <span className="facility-count">{place.rooms.uShaped}</span>
                              </li>
                              <li>
                                <img src={UlesParty} />
                                <span className="facility-tooltip">Állóhely (fő)</span>
                                <span className="facility-count">{place.rooms.standing}</span>
                              </li>
                              <li>
                                <img src={UlesBankett} />
                                <span className="facility-tooltip"> Banket (fő)</span>
                                <span className="facility-count">{place.rooms.banquet}</span>
                              </li>
                            </ul>
                          </div>
                        </div>

                      </div>
                    </article>
                  </div>
                )
              })}
          </div>
        </div>

      </section>
    </div>
  )
}

export default RequestComponent
