import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from '.././store'
import { ParallaxProvider } from 'react-scroll-parallax'

import './Rend_App.scss'
import '.././App.scss'

import '.././index.css'

import App from '.././App'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ParallaxProvider>
        <App />
      </ParallaxProvider>
    </BrowserRouter>
  </Provider>
)
