import { createModel } from '@rematch/core'
import { venuesApi } from 'services/api'
const lang = localStorage.getItem('rnd-lang') || 'hu'

const IsJsonString = (str) => {
  if (str === null) return false
  try {
    const json = JSON.parse(str)
    return (typeof json === 'object')
  } catch (e) {
    return false
  }
}

const venue = createModel()({

  state: {
    specialPlaces: [],
    highlightedSpecialOffers: IsJsonString(sessionStorage.getItem('high-special-offers')) ? JSON.parse(sessionStorage.getItem('high-special-offers')) : [],
    banners: IsJsonString(sessionStorage.getItem('banners')) ? JSON.parse(sessionStorage.getItem('banners')) : [],
    venue: null,
    callForOffer: IsJsonString(localStorage.getItem('call-for-offer')) ? JSON.parse(localStorage.getItem('call-for-offer')) : [],
    favorites: IsJsonString(localStorage.getItem('favorites')) ? JSON.parse(localStorage.getItem('favorites')) : []
  },
  reducers: {

    setStateCallForOffer (state, payload) {
      const index = state.callForOffer.indexOf(state.callForOffer.find(item => item.key === payload.id))
      if (index === -1) {
        state.callForOffer.push({ key: payload.id, image: payload.highlightedimage, name: payload.name, city: (typeof payload.address !== 'undefined') ? payload.address.city : payload.contact.city, venueSlug: payload.venueSlug })
      } else {
        state.callForOffer.splice(index, 1)
      }
      localStorage.setItem('call-for-offer', JSON.stringify(state.callForOffer))
    },

    resetCallOffer (state) {
      state.callForOffer = []
      localStorage.setItem('call-for-offer', JSON.stringify(state.callForOffer))
    },
    removeStateCallForOffer (state, payload) {
      const index = state.callForOffer.indexOf(state.callForOffer.find(item => item.key === payload.key))
      if (index !== -1) {
        state.callForOffer.splice(index, 1)
        localStorage.setItem('call-for-offer', JSON.stringify(state.callForOffer))
      }
    },
    setStateFavorites (state, payload) {
      const index = state.favorites.indexOf(state.favorites.find(item => item.key === payload.id))
      if (index === -1) {
        state.favorites.push({ key: payload.id, image: payload.highlightedimage, name: payload.name, city: (typeof payload.address !== 'undefined') ? payload.address.city : payload.contact.city, venueSlug: payload.venueSlug })
      } else {
        state.favorites.splice(index, 1)
      }
      localStorage.setItem('favorites', JSON.stringify(state.favorites))
    },

    removeStateFavorite (state, payload) {
      const index = state.favorites.indexOf(state.favorites.find(item => item.key === payload.key))
      if (index !== -1) {
        state.favorites.splice(index, 1)
        localStorage.setItem('favorites', JSON.stringify(state.favorites))
      }
    },
    setStateSpecialPlaces (state, payload) {
      state.specialPlaces = payload
    },
    setStateHighlightedSpecialOffers (state, payload) {
      state.highlightedSpecialOffers = payload
    },

    setStateBanners (state, payload) {
      state.banners = payload
    },
    setStateVenue (state, payload) {
      state.venue = payload
    }
  },
  effects: (dispatch) => ({
    async getSpecialPlaces () {
      const response = await venuesApi.getSpecialPlaces({ acceptLanguage: lang })
      dispatch.venue.setStateSpecialPlaces(response)
    },
    async getHighlightedSpecialOffers (params, state) {
      //   if (typeof state.venue.highlightedSpecialOffers === 'undefined' || state.venue.highlightedSpecialOffers.length === 0) {
      const response = await venuesApi.getHighSpecialOffers({ acceptLanguage: lang })
      dispatch.venue.setStateHighlightedSpecialOffers(response)
      sessionStorage.setItem('high-special-offers', JSON.stringify(response))
    //  }
    },
    async getFilteredVenues (params) {
      const currentParams = {}

      for (const entry of params) {
        if (!(entry[0] in currentParams)) currentParams[entry[0]] = []
        currentParams[entry[0]].push(entry[1])
      }

      const response = await venuesApi.getFilteredVenues({ acceptLanguage: lang, params: JSON.stringify(currentParams) })
      return response
    },

    async getRequestVenues (params, state) {
      const currentParams = state.venue.callForOffer.map((offer) => offer.venueSlug)

      const response = await venuesApi.getRequestVenues({ acceptLanguage: lang, params: JSON.stringify(currentParams) })
      return response
    },

    async clickBanner (params, state) {
      await venuesApi.clickBanner({ clickBanner: { bannerId: params.bannerId } })
    },

    async clickContactData (params, state) {
      await venuesApi.clickContactData({ clickContactData: { venueId: params.venueId } })
    },

    async getBanners () {
      // if (!IsJsonString(sessionStorage.getItem('banners'))) {
      const response = await venuesApi.getBanners({ acceptLanguage: lang })
      dispatch.venue.setStateBanners(response)
      sessionStorage.setItem('banners', JSON.stringify(response))
    //  }
    },
    async getVenue (venueSlug) {
      const response = await venuesApi.getVenue({ acceptLanguage: lang, venueSlug })
      dispatch.venue.setStateVenue(response)
    },

    async saveRequestOffer (request, state) {
      const data = { ...request, venueIds: JSON.stringify(state.venue.callForOffer.map((offer) => offer.key)) }
      try {
        const response = await venuesApi.saveRequestOffer({ acceptLanguage: lang, postRequestOffer: data })

        await dispatch.venue.resetCallOffer()

        return response
      } catch (e) {
        return e
      }
    },
    async getFilteredSpecialOffers (params) {
      const currentParams = {}

      for (const entry of params) {
        if (!(entry[0] in currentParams)) currentParams[entry[0]] = []
        currentParams[entry[0]].push(entry[1])
      }

      const response = await venuesApi.getFilteredSpecialOffers({ acceptLanguage: lang, params: JSON.stringify(currentParams) })
      return response
    }
  })
})

export default venue
