import { useTranslation } from 'react-i18next'
import { GoogleMap, InfoBox, LoadScript, MarkerClusterer, MarkerF, OverlayView } from '@react-google-maps/api'
import { useEffect, useState } from 'react'
import InputNumber from 'rc-input-number'
import 'rc-slider/assets/index.css'
import classNames from 'classnames'
import SelectWidget from 'widgets/SelectWidget'
import useFilterType from 'hooks/useFilterType'
import UlesU from '../../assets/images/icons/ules_u.jpg'
import UlesBankett from '../../assets/images/icons/ules_bankett.jpg'
import UlesParty from '../../assets/images/icons/ules_party.jpg'
import UlesSzek from '../../assets/images/icons/ules_szek.jpg'
import IconMarker from '../../assets/images/marker.png'
import { Link as DomLink, useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

import { Pagination } from 'react-bootstrap'
import ContentLoader from 'react-content-loader'

const ListingComponent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const PlaceLoader = (props) => (
    <ContentLoader
      speed={2}
      width={'100%'}
      height={440}
      viewBox="0 0 300 400"
      {...props}
    >
      <circle cx="31" cy="31" r="15" />
      <rect x="58" y="18" rx="2" ry="2" width="140" height="10" />
      <rect x="58" y="34" rx="2" ry="2" width="140" height="10" />
      <rect x="0" y="60" rx="2" ry="2" width="400" height="400" />
    </ContentLoader>
  )

  const { favorites, callForOffer, banners } = useSelector(state => state.venue)
  const [searchParams, setSearchParams] = useSearchParams()

  const [enableSearch, setEnableSearch] = useState(false)

  const [filteredPlaceVenues, setFilteredPlaceVenues] = useState([])
  const [filteredVenues, setFilteredVenues] = useState(null)
  const [viewedVenues, setViewedVenues] = useState([])
  const [hasOneColumn, setOneColumn] = useState(false)
  const [isMapVisible, setMapVisible] = useState(false)
  const [mapInstance, setMapInstance] = useState(null)
  const regionFilter = searchParams.getAll('region')
  const cityFilter = searchParams.getAll('city')
  const eventFilter = searchParams.get('event')
  const placeFilter = searchParams.getAll('place')
  const locationFilter = searchParams.getAll('location')
  const cateringFilter = searchParams.getAll('catering')
  const accommodationFilter = searchParams.get('accommodation') ?? 0
  const specialOfferFilter = searchParams.get('specialoffer') ?? 'false'
  const specialPlaceFilter = searchParams.get('specialplace')
  const placeNameFilter = searchParams.get('placename')
  const roomNumFilter = searchParams.get('roomnum') ?? 0
  const roomBenches = searchParams.get('room_benches') ?? 0
  const roomBanquet = searchParams.get('room_banquet') ?? 0
  const roomU = searchParams.get('room_u') ?? 0
  const roomStanding = searchParams.get('room_standing') ?? 0
  const roomMinMeter = searchParams.get('room_min_meter') ?? 0
  const roomMaxMeter = searchParams.get('room_max_meter') ?? 0

  const [filterSideBanners, setFilterSideBanners] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [items, setItems] = useState([])
  const [firstStart, setFirstStart] = useState(true)

  const [roomAndNumbersCheck, setRoomAndNumbersCheck] = useState(null)

  const maxRoom = [roomBenches, roomBanquet, roomU, roomStanding]
  const maxRoomnames = ['Benches', 'Banquet', 'U', 'Standing']
  let roomNumHeadDefault = maxRoom[0]
  let largestelement = 0

  for (let i = 0; i < maxRoom.length; i++) {
    if (roomNumHeadDefault < maxRoom[i]) {
      roomNumHeadDefault = maxRoom[i]
      largestelement = i
    }
  }

  const [roomAndNumbersHeadcountCheck, setRoomAndNumbersHeadcountCheck] = useState(maxRoomnames[largestelement])

  const venuesOfPage = 20
  useEffect(() => {
    if (firstStart) {
      const roomparams = ['room_benches', 'room_banquet', 'room_u', 'room_standing']
      let numOfRoomParams = 0
      for (const entry of searchParams.entries()) {
        if (entry[0] === 'room_min_meter' || entry[0] === 'room_max_meter') numOfRoomParams = 2
        if (roomparams.includes(entry[0]) && parseInt(entry[1]) > 0) numOfRoomParams++
      }

      numOfRoomParams > 1 ? setRoomAndNumbersCheck('Detailed') : setRoomAndNumbersCheck('Normal')

      window.scrollTo(0, 0)
      setFirstStart(false)
      dispatch.venue.getBanners()
      async function fetchData () {
        const response = await dispatch.venue.getFilteredVenues(searchParams.entries())
        setFilteredVenues(response.data)
        if (response.data.length && filteredVenues !== null) { setViewedVenues(filteredVenues.slice(0, venuesOfPage)) }
      }
      fetchData()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstStart])

  useEffect(() => {
    async function fetchData () {
      const response = await dispatch.venue.getFilteredVenues(searchParams.entries())
      setFilteredVenues(response.data)
      if (response.data.length) { setViewedVenues(filteredVenues.slice(0, venuesOfPage)) } else setViewedVenues([])
    }
    if (enableSearch) {
      setCurrentPage(1)
      fetchData()
      setEnableSearch(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, enableSearch])

  useEffect(() => {
    const venues = filteredVenues === null ? [] : filteredVenues.filter((res) => typeof res.location !== 'undefined')

    setFilteredPlaceVenues(venues)

    const tmpitems = []
    if (filteredVenues != null && filteredVenues.length) {
      for (let number = 1; number <= Math.ceil(filteredVenues.length / venuesOfPage)
        ; number++) {
        tmpitems.push(
          <Pagination.Item key={number} onClick={() => { setCurrentPage(number); window.scrollTo(0, 0) }} active={number === currentPage}>
            {number}
          </Pagination.Item>
        )
      }
      const startedAt = currentPage * venuesOfPage - venuesOfPage
      setViewedVenues(filteredVenues.slice(startedAt, startedAt + venuesOfPage))
    }

    setItems(tmpitems)
  }, [filteredVenues, currentPage, enableSearch])

  useEffect(() => {
    setFilterSideBanners(banners.filter((res) => res.type === 'FilterSide'))
  }, [banners])

  useEffect(() => {
    updateParams({ value: roomNumHeadDefault }, 'roomnumhead')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomAndNumbersHeadcountCheck, roomAndNumbersCheck])

  const handleOnFavorite = (venue) => {
    dispatch.venue.setStateFavorites(venue)
  }

  const handleOnCallForOffer = (venue) => {
    dispatch.venue.setStateCallForOffer(venue)
  }

  const navigateToDetail = (venueSlug) => {
    navigate('/' + venueSlug)
  }

  const updateParams = (e, target) => {
    if (target === 'accommodation' && e.value === null) {
      e.value = 0
    }

    const currentParams = {}

    for (const entry of searchParams.entries()) {
      if (!(entry[0] in currentParams)) currentParams[entry[0]] = []
      currentParams[entry[0]].push(entry[1])
    }

    if (roomAndNumbersCheck != null && target === 'roomnumhead' && roomAndNumbersCheck === 'Normal') {
      currentParams.room_min_meter = [0]
      currentParams.room_max_meter = [0]
      currentParams.room_benches = [0]
      currentParams.room_banquet = [0]
      currentParams.room_u = [0]
      currentParams.room_standing = [0]
      switch (roomAndNumbersHeadcountCheck) {
      case 'Benches':
        target = 'room_benches'
        break
      case 'Banquet':
        target = 'room_banquet'
        break
      case 'U':
        target = 'room_u'
        break
      case 'Standing':
        target = 'room_standing'
        break
      }
    }

    if (e === null) {
      delete currentParams[target]
      setSearchParams(currentParams)
    } else {
      if (target !== 'roomnumhead') {
        const newParams = { ...currentParams, [target]: (typeof e.length !== 'undefined') ? e.map((item) => item.value) : e.value }
        setSearchParams(newParams)
      }
    }
  }

  const {
    specialPlacesSelect,
    regionTypes,
    cityTypes,
    eventTypes,
    placeTypes,
    locationTypes,
    cateringTypes
  } = useFilterType()

  const containerStyle = {
    width: '100%',
    height: '100%'
  }

  const [center] = useState({
    lat: 47.492552,
    lng: 19.047692
  })

  const [selectedMarker, setSelectedMarker] = useState()
  const [markers, setMarkers] = useState([])

  const handleOnMarkerClick = (marker) => {
    setSelectedMarker(marker)
    mapInstance.panTo(marker.position)
    mapInstance.panBy(0, -120)
  }

  useEffect(() => {
    const list = filteredPlaceVenues.map((place) => {
      return { location: place.location, name: place.name, address: place.address.city, image: place.highlightedimage, venueSlug: place.venueSlug }
    }).map((item, index) => ({
      id: index,
      title: item.name,
      venueSlug: item.venueSlug,
      image: item.image,
      address: item.address,
      place: item.name,
      position: item.location
    }))
    setMarkers(list)
  }, [filteredPlaceVenues])

  useEffect(() => {
    if (mapInstance) {
      mapInstance.setCenter(center)
    }
  }, [center, mapInstance])

  const specialPlaceDefault = specialPlacesSelect.filter(specialplace => specialplace.value === specialPlaceFilter)
  const placeNameDefault = placeNameFilter == null ? '' : placeNameFilter
  const roomNumDefault = roomNumFilter == null ? '' : roomNumFilter

  const regionDefault = regionTypes.filter(countryType => regionFilter.find(region => region === countryType.value))

  const cityDefault = () => {
    const result = []
    if (cityFilter.length) {
      cityTypes.forEach(events => {
        events.options.forEach(option => {
          cityFilter.find(cityValue => cityValue === option.value) && result.push({ value: option.value, label: option.label })
        })
      })
    }

    return result
  }

  const eventDefault = () => {
    let result = null
    if (eventFilter !== null) {
      eventTypes.forEach(events => {
        events.options.forEach(option => {
          if (option.value === eventFilter) {
            result = ({ value: option.value, label: option.label })
          }
        })
      })
    }

    return result
  }

  const placeDefault = placeTypes.filter(placeType => placeFilter.find(place => place === placeType.value))

  const locationDefault = locationTypes.filter(locationType => locationFilter.find(location => location === locationType.value))

  const cateringDefault = cateringTypes.filter(cateringType => cateringFilter.find(catering => catering === cateringType.value))

  return (
    <>

      <section className="grey-blue-bg pt-4">
        <div className="container">
          <div className="row">
            {/* filter sidebar */}
            <div className="col-md-4">
              <div className="fl-wrap filter-sidebar_item fixed-bar">
                <div className= {classNames('filter-sidebar fl-wrap lws_mobile', { 'map-list-overflow': isMapVisible })} >
                  {/* col-list-search-input-item */}
                  {!isMobile &&
                  <div>

                    <a onClick={() => setMapVisible(!isMapVisible)} title="Térkép nézet" className="mapLink js-openMap">
                      <span className="cursor-pointer"></span>
                      <button className="map-show-button" >{isMapVisible ? t('listing:map-hide') : t('listing:map-show')} </button>
                    </a>

                  </div>
                  }
                  <div className="mt-4">
                    <label className="label-style">{t('listing:place-name')} </label>
                    <input className="input-style" defaultValue={placeNameDefault} type="text" onChange={(e) => updateParams(e.currentTarget.value === '' ? null : e.currentTarget, 'placename')}/>
                  </div>

                  <div className="col-list-search-input-item mt-4">
                    <label>{t('common:filter-special-place')}</label>
                  </div>
                  <SelectWidget
                    placeholder={t('listing:special-place')}
                    value={specialPlaceDefault}
                    icon={<i className="fal fa-map-marker text-yellow"></i>}
                    options={specialPlacesSelect}
                    isClearable={(e) => updateParams(e, 'specialplace')}
                    onChange={(e) => updateParams(e, 'specialplace')}
                  />

                  <div className="col-list-search-input-item mt-4">
                    <label>{t('common:filter-countyType')}</label>
                  </div>
                  <SelectWidget
                    isMulti
                    value={regionDefault}
                    placeholder={t('listing:county')}
                    icon={<i className="fal fa-map-marker text-yellow"></i>}
                    options={regionTypes}
                    onChange={(e) => updateParams(e, 'region')}
                  />

                  <div className="col-list-search-input-item mt-4">
                    <label>{t('common:filter-cityType')}</label>
                  </div>
                  <SelectWidget
                    isMulti
                    value={cityDefault()}
                    placeholder={t('listing:city-district')}
                    icon={<i className="fal fa-map-marker text-yellow"></i>}
                    options={cityTypes}
                    onChange={(e) => updateParams(e, 'city')}
                  />

                  <div className="col-list-search-input-item mt-4">
                    <label>{t('common:filter-eventType')}</label>
                  </div>

                  <SelectWidget
                    value={eventDefault()}
                    isClearable={(e) => updateParams(e, 'event')}
                    icon={<i className="fal fa-calendar-check text-yellow"></i>}
                    options={eventTypes}
                    onChange={(e) => updateParams(e, 'event')}
                  />

                  <div className="col-list-search-input-item mt-4">
                    <label>{t('common:filter-placeType')}</label>
                  </div>

                  <SelectWidget
                    isMulti
                    value={placeDefault}
                    icon={<i className="fal fa-building text-yellow"></i>}
                    options={placeTypes}
                    onChange={(e) => updateParams(e, 'place')}
                  />

                  <div className="col-list-search-input-item mt-4">
                    <label>{t('common:filter-locationType')}</label>
                  </div>

                  <SelectWidget
                    isMulti
                    value={locationDefault}
                    icon={<i className="fal fa-map-marker text-yellow"></i>}
                    options={locationTypes}
                    onChange={(e) => updateParams(e, 'location')}
                  />

                  <div className="col-list-search-input-item mt-4">
                    <label>{t('common:filter-cateringType')}</label>
                  </div>

                  <SelectWidget
                    isMulti
                    value={cateringDefault}
                    icon={<i className="fas fa-utensils text-yellow"></i>}
                    options={cateringTypes}
                    onChange={(e) => updateParams(e, 'catering')}
                  />
                  <div className="rommandnumbers">
                    <div className="d-flex flex-column">
                      <div className="col-list-search-input-item mt-4" style={{ marginBottom: 9 }}>
                        <label className="m-0">{t('common:filter-room-and-numbers')}</label>
                      </div>
                      <div className="d-flex" >
                        <div className="form-check d-flex ">
                          <input className="form-check-input" type="radio" value="Normal" checked={roomAndNumbersCheck === 'Normal'} onChange={(e) =>
                            setRoomAndNumbersCheck(e.target.value)
                          }/>
                          <div className="col-list-search-input-item">
                            <label className="m-0">{t('common:filter-normal')}</label>
                          </div>
                        </div>
                        <div className="form-check d-flex ">
                          <input className="form-check-input" type="radio" id="exampleRadios2" value="Detailed" checked={roomAndNumbersCheck === 'Detailed'} onChange={(e) => setRoomAndNumbersCheck(e.target.value)}/>
                          <div className="col-list-search-input-item">
                            <label className="m-0">{t('common:filter-detailed')}</label>
                          </div>
                        </div>
                      </div>

                    </div>
                    {roomAndNumbersCheck === 'Normal' &&
                    <div style={{ marginLeft: 15, marginTop: 15 }}>
                      <div className="d-flex flex-column">
                        <div className="d-flex">
                          <div className="d-flex flex-column">
                            <label className="label-style" style={{ textAlign: 'center' }}>{t('listing:number-of-rooms')} </label>
                            <InputNumber value={roomNumDefault} min={0} step={1} onChange={(e) => updateParams({ value: e }, 'roomnum')} />
                          </div>
                          <div>
                            <label className="label-style" style={{ textAlign: 'center' }}>{t('listing:headcount')} </label>
                            <InputNumber value={roomNumHeadDefault} min={0} step={1} onChange={(e) => updateParams({ value: e }, 'roomnumhead')} />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-column">

                        <div className="d-flex room-and-numbers-head-check">

                          <div className="form-check">
                            <input className="form-check-input" type="radio" value="Benches" checked={roomAndNumbersHeadcountCheck === 'Benches'} onChange={(e) => {
                              setRoomAndNumbersHeadcountCheck(e.target.value)
                            }
                            }/>
                            <div className="col-list-search-input-item">
                              <label>{t('common:filter-room_benches')}</label>
                            </div>
                          </div>

                          <div className="form-check">
                            <input className="form-check-input" type="radio" value="Banquet" checked={roomAndNumbersHeadcountCheck === 'Banquet'} onChange={(e) => {
                              setRoomAndNumbersHeadcountCheck(e.target.value)
                            }
                            }/>
                            <div className="col-list-search-input-item">
                              <label>{t('common:filter-room_banquet')}</label>
                            </div>
                          </div>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" value="U" checked={roomAndNumbersHeadcountCheck === 'U'} onChange={(e) => {
                              setRoomAndNumbersHeadcountCheck(e.target.value)
                            }
                            }/>
                            <div className="col-list-search-input-item">
                              <label>{t('common:filter-room_u')}</label>
                            </div>
                          </div>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" value="Standing" checked={roomAndNumbersHeadcountCheck === 'Standing'} onChange={(e) => {
                              setRoomAndNumbersHeadcountCheck(e.target.value)
                            }
                            }/>
                            <div className="col-list-search-input-item">
                              <label>{t('common:filter-room_standing')}</label>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    }
                    {roomAndNumbersCheck === 'Detailed' &&

                    <div style={{ marginLeft: 15, marginTop: 15 }}>
                      <div className="d-flex flex-column">
                        <div className="d-flex">
                          <div className="d-flex flex-column">
                            <label className="label-style" style={{ textAlign: 'center' }}>{t('listing:number-of-rooms')} </label>
                            <InputNumber value={roomNumDefault} min={0} step={1} onChange={(e) => updateParams({ value: e }, 'roomnum')} />
                          </div>

                        </div>
                      </div>
                      <div>
                        <div className="row">

                          <div className="col-4">
                            <div className="col-list-search-input-item">
                              <label>{t('common:filter-room_benches')}</label>
                            </div>
                            <div className="text-start">
                              <InputNumber value={roomBenches} min={0} step={1} onChange={(e) => updateParams({ value: e }, 'room_benches')} />
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="col-list-search-input-item">
                              <label>{t('common:filter-room_banquet')}</label>
                            </div>
                            <div className=" text-start">
                              <InputNumber value={roomBanquet} min={0} step={1} onChange={(e) => updateParams({ value: e }, 'room_banquet')} />
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="col-list-search-input-item">
                              <label>{t('common:filter-room_standing')}</label>
                            </div>
                            <div className="text-start">
                              <InputNumber value={roomStanding} min={0} step={1} onChange={(e) => updateParams({ value: e }, 'room_standing')} />
                            </div>
                          </div>

                        </div>
                        <div className="row">
                          <div className="col-4">
                            <div className="col-list-search-input-item">
                              <label>{t('common:filter-room_u')}</label>
                            </div>
                            <div className="text-start">
                              <InputNumber value={roomU} min={0} step={1} onChange={(e) => updateParams({ value: e }, 'room_u')} />
                            </div>
                          </div>

                          <div className="col-4">
                            <div className="col-list-search-input-item">
                              <label>Min m2</label>
                            </div>
                            <div className=" text-start">
                              <InputNumber value={roomMinMeter} min={0} step={1} onChange={(e) => updateParams({ value: e }, 'room_min_meter')} />
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="col-list-search-input-item">
                              <label>Max m2</label>
                            </div>
                            <div className=" text-start">
                              <InputNumber value={roomMaxMeter} min={0} step={1} onChange={(e) => updateParams({ value: e }, 'room_max_meter')} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    }
                  </div>

                  <div className="col-list-search-input-item mt-4">
                    <label>{t('common:filter-accommodationType')}</label>
                  </div>
                  <div className="mb-4 text-start">
                    <InputNumber value={accommodationFilter} min={0} step={1} onChange={(e) => updateParams({ value: e }, 'accommodation')} />
                  </div>

                  <div className="col-list-search-input-item">
                    <label>{t('common:filter-saleType')}</label>
                    <div className="fl-wrap filter-tags text-start">
                      <input type="checkbox" name="check" checked ={specialOfferFilter === 'true'} onChange={(e) => updateParams({ value: e.currentTarget.checked }, 'specialoffer')} />
                    </div>

                    {/* col-list-search-input-item  */}
                    {
                      <div className="col-list-search-input-item fl-wrap mt-4 d-flex">
                        <button className={classNames('header-search-button', { 'mb-4': isMapVisible })} onClick={() => { setEnableSearch(true) }} >{t('common:search')} <i className="far fa-search"></i></button>
                        <div className={classNames('listing-trash', { 'mb-4': isMapVisible })} onClick={() => { setSearchParams(); setEnableSearch(true) }} >
                          <i className="fas fa-trash"></i>
                        </div>
                      </div>
                    }
                    {/* col-list-search-input-item end */}
                  </div>

                </div>
              </div>

              { filterSideBanners.map(banner =>
                <div className="geodir-category-img banner-img cursor-pointer" style={{ marginTop: 10 }} onClick={() => {
                  dispatch.venue.clickBanner({ bannerId: banner.bannerId })
                  window.open(banner.url, '_blank')
                }}>
                  <img src={banner.image} alt="" />
                </div>
              )}

            </div>
            {/* filter sidebar end */}
            {/* listing */}
            <div className="col-md-8 pt-3 pt-md-0">

              {isMobile &&
                  <div>

                    <a onClick={() => setMapVisible(!isMapVisible)} title="Térkép nézet" className="mapLink js-openMap">
                      <span className="cursor-pointer"></span>
                      <button className="map-show-button" >{isMapVisible ? t('listing:map-hide') : t('listing:map-show')} </button>
                    </a>

                  </div>
              }

              {isMapVisible
                ? <div className={classNames('map-container fw-map hid-mob-map,big_map', { big_map: isMapVisible, 'h-small ': !isMapVisible })}>
                  <LoadScript
                    googleMapsApiKey="AIzaSyCSi5Dstx0WKFpSVMg_M6bzLnWY-553wWs"
                  >
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      zoom={7}
                      /* options={{ scrollwheel: true }} */
                      onLoad={(map) => setTimeout(() => setMapInstance(map))}
                    >
                      {mapInstance ? (
                        <MarkerClusterer>
                          {(clusterer) => markers?.map((marker) => (
                            <MarkerF
                              key={`marker-${marker.id}`}
                              clusterer={clusterer}
                              position={marker.position}
                              icon={IconMarker}
                              onClick={() => handleOnMarkerClick(marker)}
                              animation={false}
                            >
                            </MarkerF>
                          ))}
                        </MarkerClusterer>
                      ) : null}
                      {selectedMarker ? (
                        <InfoBox
                          options={{ closeBoxURL: '', pixelOffset: { height: -352, width: -152 } }}
                          position={selectedMarker.position}
                          mapPaneName={OverlayView.MAP_PANE}
                        >
                          <div className="map-box">
                            <div className="map-popup-wrap">
                              <div className="map-popup">
                                <div className="infoBox-close" onClick={(e) => { e.preventDefault(); setSelectedMarker(null) }}>
                                  <i className="far fa-times"></i>
                                </div>
                                <a onClick={() => navigateToDetail(selectedMarker.venueSlug)} className="listing-img-content fl-wrap cursor-pointer">
                                  <img src={selectedMarker.image} alt="" />
                                </a>
                                <div className="listing-content fl-wrap">
                                  <div className="card-popup-raining map-card-rainting" data-starrating="5">
                                    <i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i></div>
                                  <div className="listing-title fl-wrap"><h4><a onClick={() => navigateToDetail(selectedMarker.venueSlug)}>{selectedMarker.title}</a></h4><span className="map-popup-location-info"><i className="fas fa-map-marker-alt"></i>{selectedMarker.address}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </InfoBox>
                      ) : null}
                      <></>
                    </GoogleMap>
                  </LoadScript>
                </div>
                : <div>
                  {/* col-list-wrap */}
                  <div className="col-list-wrap fw-col-list-wrap post-container">
                    {/* list-main-wrap */}
                    <div className="list-main-wrap fl-wrap card-listing">
                      {/* list-main-wrap-opt */}
                      <div className="list-main-wrap-opt fl-wrap">

                        { filteredPlaceVenues !== null &&
                    <div className="section-title" >
                      <h2>{t('listing:event-venues-search-results')} {filteredPlaceVenues.length}  {t('common:piece')}</h2>
                    </div>
                        }
                        {/* price-opt */}
                        <div className="grid-opt">
                          <ul>
                            <li onClick={() => setOneColumn(false)}>
                              <span
                                className={classNames('one-col-grid', { 'act-grid-opt': !hasOneColumn })}>
                                <i className="fas fa-th-large"></i>
                              </span>
                            </li>
                            <li onClick={() => setOneColumn(true)}>
                              <span className={classNames('one-col-grid', { 'act-grid-opt': hasOneColumn })}>
                                <i className="fas fa-bars"></i>
                              </span>
                            </li>
                          </ul>
                        </div>
                        {/* price-opt end */}
                      </div>
                      {/* list-main-wrap-opt end */}
                      {/* listing-item-container */}
                      <div className="container-fluid px-0">
                        <div className="row pt-3">
                          { viewedVenues === null ? <div className="listing-item col-lg-6"><PlaceLoader /></div>
                            : (viewedVenues.length === 0 ? <div>{t('listing:no-results')}</div> : viewedVenues.map((place, key) => {
                              return typeof place.venueSlug !== 'undefined'
                                ? (
                                  <div key={key} className={classNames('listing-item col-lg-6', { has_one_column: hasOneColumn })}>
                                    <article className="geodir-category-listing fl-wrap" >
                                      <div className="geodir-category-img">
                                        <DomLink to={`/${place.venueSlug}`} >  <img className="cursor-pointer" src={place.highlightedimage} alt="" /> </DomLink>

                                      </div>
                                      <div className="geodir-category-content fl-wrap">
                                        <div className="geodir-category-content-title fl-wrap">
                                          <div className="geodir-category-content-title-item">
                                            {/*   <h3 className="title-sin_map cursor-pointer" onClick={() => navigateToDetail(place.venueSlug)}>{place.name} </h3> */}
                                            <DomLink to={`/${place.venueSlug}`} ><h3 className="title-sin_map">{place.name}</h3> </DomLink>
                                            <div className="geodir-category-location fl-wrap"><a className="map-item"><i className="fas fa-map-marker-alt"></i>{place.address.city}</a></div>
                                          </div>
                                        </div>
                                        <div>
                                          <p className="highlited-description" style={{ width: '100%', padding: 0, marginBottom: 5 }}>{place.description}</p>
                                        </div>
                                        <div className="dotted-separator"></div>
                                        <div className="d-flex flex-column">
                                          <span className="pe-3 text-capacity">{t('listing:hall-capacity')}</span>

                                          <div className="d-flex align-items-center mw-100 overflow-x-auto justify-content-center">

                                            <ul className="facilities-list">
                                              <li>
                                                <img src={UlesSzek} />
                                                <span className="facility-tooltip">{t('common:filter-room_benches')} (fő)</span>
                                                <span className="facility-count">{place.rooms.benches}</span>
                                              </li>
                                              <li>
                                                <img src={UlesU} />
                                                <span className="facility-tooltip">{t('common:filter-room_u')} (fő)</span>
                                                <span className="facility-count">{place.rooms.uShaped}</span>
                                              </li>
                                              <li>
                                                <img src={UlesParty} />
                                                <span className="facility-tooltip">{t('common:filter-room_standing')} (fő)</span>
                                                <span className="facility-count">{place.rooms.standing}</span>
                                              </li>
                                              <li>
                                                <img src={UlesBankett} />
                                                <span className="facility-tooltip">{t('common:filter-room_banquet')} (fő)</span>
                                                <span className="facility-count">{place.rooms.banquet}</span>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <div className="geodir-category-footer fl-wrap">
                                          <div className="ignore-offer-hide ignore-favorite-hide geodir-opt-list d-flex" style={{ justifyContent: 'space-evenly' }}>
                                            <a className="ignore-offer-hide cursor-pointer" onClick={() => handleOnCallForOffer(place)}><span className="ignore-offer-hide">{t('listing:request-for')}</span><i className={(callForOffer.find(item => item.venueSlug === place.venueSlug) ? 'fas' : 'fal') + ' fa-map-marker-alt'}></i></a>
                                            <a className="ignore-favorite-hide cursor-pointer" onClick={() => handleOnFavorite(place)}><span className="ignore-favorite-hide">{t('listing:favorite')}</span><i className={(favorites.find(item => item.venueSlug === place.venueSlug) ? 'fas' : 'fal') + ' fa-heart ignore-favorite-hide'} ></i></a>
                                          </div>
                                        </div>

                                      </div>
                                    </article>

                                  </div>

                                )
                                : (
                                  <div key={key} className={classNames('listing-item col-lg-6', { has_one_column: hasOneColumn })}>
                                    <div className={classNames('geodir-category-img listing-banner-img', { has_one_column_banner: hasOneColumn })} >
                                      <img className="cursor-pointer" onClick={() => {
                                        dispatch.venue.clickBanner({ bannerId: place.bannerId })
                                        window.open(place.url, '_blank')
                                      }} src={place.highlightedimage} alt="" />
                                    </div>
                                  </div>
                                )
                            }))}
                          { filteredVenues !== null &&
                          <Pagination className="d-flex justify-content-center">
                            {items}
                            {/*
                            <Pagination.Item active>{1}</Pagination.Item>
                            <Pagination.Item>{2}</Pagination.Item>
 */}
                          </Pagination>
                          }

                        </div>
                      </div>

                      {/* listing-item-container end */}
                    </div>

                    {/* list-main-wrap end */}
                  </div>

                </div>
              }

              {/* col-list-wrap end */}
            </div>
            {/* listing  end */}

          </div>

          {/* row end */}
        </div>

        <div className="limit-box fl-wrap"></div>
      </section>
    </>
  )
}

export default ListingComponent
