import { createModel } from '@rematch/core'
import { authenticationApi } from 'services/api'
import Cookies from 'js-cookie'

const lang = localStorage.getItem('rnd-lang') || 'hu'
const auth = createModel()({
  state: {
    visibleModal: null,
    loggedInUser: null
  },
  reducers: {
    setVisibleModal (state, payload) {
      state.visibleModal = payload
    },
    setLoggedInUser (state, payload) {
      state.loggedInUser = payload
    }
  },
  effects: (dispatch) => ({
    async userRegistration (request, state) {
      try {
        const response = await authenticationApi.postRegister({ acceptLanguage: lang, userRegistration: request })

        return response
      } catch (e) {
        return e
      }
    },

    async userLogin (request, state) {
      try {
        const response = await authenticationApi.postLogin({ acceptLanguage: lang, userLogin: request })

        Cookies.set('rendezveny-token', response.token, { expires: 365, sameSite: 'strict', secure: process.env.NODE_ENV === 'production' })
        dispatch.auth.setLoggedInUser(response.user)
        dispatch.auth.setVisibleModal(false)
        return response
      } catch (e) {
        return e
      }
    },

    async userLogout () {
      try {
        authenticationApi.postLogout()
      } catch (e) {
        // Invalid token
      } finally {
        Cookies.remove('rendezveny-token')
        dispatch.auth.setLoggedInUser(null)
      }
    },
    async forgotPassword (email) {
      try {
        const response = await authenticationApi.forgotPassword({
          acceptLanguage: lang,
          userForgotPassword: {
            email
          }
        })
        return response
      } catch (e) {
        return e
      }
    },
    async setVerifiedUser (verified, state) {
      dispatch.auth.setLoggedInUser({
        ...state.auth.loggedInUser,
        emailVerifiedAt: new Date().getTime().toLocaleString()
      })
    },
    async resetPassword (userResetPassword) {
      try {
        const response = await authenticationApi.resetPassword({
          acceptLanguage: lang,
          userResetPassword
        })
        return response
      } catch (e) {
        return e
      }
    },

    async verify (request, state) {
      try {
        const response = await authenticationApi.verifyEmail({ acceptLanguage: lang, id: request.id, hash: request.hash })
        dispatch.auth.setVerifiedUser(true)
        return response
      } catch (e) {
        return e
      }
    },
    async resendVerificationEmail () {
      await authenticationApi.emailVerificationNotification({ acceptLanguage: lang })
    }

  })
})

export default auth
