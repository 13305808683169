import { useEffect, useState } from 'react'

import ContactImage from '../../assets/images/bg/3.png'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import SelectWidget from 'widgets/SelectWidget'
import useFilterType from 'hooks/useFilterType'
import { useDispatch, useSelector } from 'react-redux'

import InputNumber from 'rc-input-number'
import { Paper } from '@mui/material'
import Carousel from 'react-material-ui-carousel'

import ContentLoader from 'react-content-loader'

const HomeComponent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const BannerLoader = (props) => (
    <ContentLoader

      height={520}
      viewBox="0 0 450 400"
      {...props}
    >
      <rect x="43" y="304" rx="4" ry="4" width="271" height="9" />
      <rect x="44" y="323" rx="3" ry="3" width="119" height="6" />
      <rect x="42" y="77" rx="10" ry="10" width="388" height="217" />
    </ContentLoader>
  )

  const HighlightedLoader = (props) => (
    <ContentLoader
      speed={2}
      width={'100%'}
      height={463}
      viewBox="0 0 300 400"
      {...props}
    >

      <rect x="15" y="15" rx="4" ry="4" width="350" height="200" />
      <rect x="15" y="50" rx="2" ry="2" width="350" height="10" />
      <rect x="15" y="230" rx="2" ry="2" width="170" height="50" />

    </ContentLoader>
  )

  const SpecialLoader = (props) => (
    <ContentLoader
      speed={2}
      width={'100%'}
      height={340}
      viewBox="0 0 200 300"
      {...props}
    >

      <rect x="58" y="18" rx="2" ry="2" width="140" height="10" />
      <rect x="58" y="34" rx="2" ry="2" width="140" height="10" />
      <rect x="0" y="60" rx="2" ry="2" width="400" height="400" />
      <circle cx="31" cy="31" r="15" />
    </ContentLoader>
  )
  const [searchParams, setSearchParams] = useState([])
  const { specialPlaces, highlightedSpecialOffers, banners } = useSelector(state => state.venue)
  const [highlightedBanners, setHighlightedBanners] = useState([])
  const [homeFloatBanners, setHomeFloatBanners] = useState(null)

  const updateParams = (e, target) => {
    // const searchParams = { ...currentParams, [target]: (typeof e.length !== 'undefined') ? e.map((item) => item.value) : e.value }
    setSearchParams({ ...searchParams, [target]: e.value })
  }

  useEffect(() => {
    dispatch.venue.getSpecialPlaces()
    dispatch.venue.getHighlightedSpecialOffers()
    dispatch.venue.getBanners()
  }, [dispatch])

  useEffect(() => {
    setHighlightedBanners(banners.filter((res) => res.type === 'Highlighted'))

    const tmpBanners = banners.filter((res) => res.type === 'HomeFloat')
    if (tmpBanners.length) { setHomeFloatBanners(tmpBanners[Math.floor((Math.random() * tmpBanners.length))]) }
  }, [banners])

  const handleOnSearch = () => {
    const params = new URLSearchParams()
    Object.keys(searchParams).forEach((type) => {
      params.append(type, searchParams[type])
    })

    navigate('/listing?' + params.toString())
  }

  const navigateToSpecialListing = (specailSlug) => {
    navigate('/listing?specialplace=' + specailSlug)
  }

  const navigateToDetail = (venueSlug, id = null) => {
    navigate('/' + venueSlug + (id == null ? '' : ('?specialoffer=' + id)))
  }

  const {
    cityTypes,
    placeTypes,
    eventTypes
  } = useFilterType()

  return (
    <>
      {<Carousel

        indicators={false}
        navButtonsAlwaysVisible={highlightedBanners.length > 1}
      >
        {highlightedBanners.length === 0 ? <div style={{ height: 520 }}><section><BannerLoader /></section></div>
          : highlightedBanners.map((banner, key) =>
            <Paper key={key}>
              <div className="d-flex align-items-end" style={{
                height: 520,
                backgroundImage: `url(${banner.image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}>
                <div className="list-single-hero-title fl-wrap">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-7">
                        <div className="listing-rating-wrap">
                          <div className="listing-rating card-popup-rainingvis" data-starrating2="5"></div>
                        </div>
                        <h2><span className="cursor-pointer" onClick={() => {
                          dispatch.venue.clickBanner({ bannerId: banner.bannerId })
                          typeof banner.venue !== 'undefined' ? navigateToDetail(banner.venue.venueSlug) : window.open(banner.url, '_blank')
                        }}>{banner.name}</span></h2>
                        { typeof banner.venue !== 'undefined' &&
                        <div className="list-single-header-contacts fl-wrap">
                          <ul>
                            <li><i className="far fa-phone"></i><a >{banner.venue.tel}</a></li>
                            <li><i className="far fa-map-marker-alt"></i><a >{banner.venue.city}</a></li>
                          </ul>
                        </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Paper>
          )}

      </Carousel>
      }

      {/* section end */}
      {/* section */}

      <section style={{ paddingBottom: 0 }}>
        { homeFloatBanners != null &&
        <div className="d-flex justify-content-end w-100 fix-position bottom-0" >

          <img src={homeFloatBanners.image}

            onClick={ () => {
              dispatch.venue.clickBanner({ bannerId: homeFloatBanners.bannerId })
              window.open(homeFloatBanners.url, '_blank')
            }}

            className="rounded-4 shadow-4 float-end m-2 cursor-pointer" alt=""
            style={{
              width: '20em'
            }}/>
          <div onClick={() => setHomeFloatBanners(null)} className="pe-3"><i className="fas fa-times-circle cursor-pointer"></i></div>
        </div>
        }
        <div className="home-intro home-header">
          <h2>{t('home:welcome-title')}</h2>
          <span className="section-separator"></span>
          <h3>{t('home:welcome-desc')}</h3>
        </div>
        <div className="main-search-input-wrap home-search" >
          <div className="main-search-input d-flex align-items-center" style={{ paddingLeft: 8 }}>
            <div className="main-search-input-item" style={{ border: 'none' }}>
              <SelectWidget
                pure
                placeholder={t('home:county-city')}

                options={cityTypes}
                onChange={(e) => updateParams(e, 'city')}
              />
            </div>
            <div className="main-search-input-item search-item" style={{ paddingTop: 4, width: 209, alignSelf: 'start' }}>

              <label>{t('common:filter-accommodationType')}</label>
              <InputNumber style={{ width: 50, marginLeft: 10, marginBottom: 4 }} defaultValue={0} min={0} max={20} step={1} onChange={(e) => updateParams({ value: e }, 'accommodation')}/>
            </div>
            <div className="main-search-input-item">
              <SelectWidget
                pure
                placeholder={t('home:event-type')}
                options={eventTypes}
                onChange={(e) => updateParams(e, 'event')}
              />
            </div>
            <div className="main-search-input-item">
              <SelectWidget
                pure
                placeholder={t('home:type-of-place')}
                options={placeTypes}
                onChange={(e) => updateParams(e, 'place')}
              />
            </div>
            <button
              className="main-search-input-item main-search-button color2-bg"
              onClick={handleOnSearch}
            >
              {t('home:search')} <i className="fal fa-search"></i>
            </button>
          </div>
        </div>

        {/* container */}
        <div className="grey-blue-bg" style={{ padding: 50 }}>

          <div className="section-title" style={{ marginBottom: 0 }}>
            <h2>{t('home:saled-offers-title')}</h2>
            <span className="section-separator"></span>
            <p>{t('home:saled-offers-desc')}</p>
          </div>

          {/* container end */}
          {/* top sales */}

          <div className="container">
            {/* listing */}
            <div className="row">
              { highlightedSpecialOffers.length === 0 ? <div className="col-md-6 col-xl-3"><HighlightedLoader /></div>
                : highlightedSpecialOffers.map((place, key) => {
                  return (
                    <div key={key} className="col-md-4 highlited-place">
                      <article className="geodir-category-listing fl-wrap cursor-pointer" onClick={() => {
                        dispatch.venue.clickBanner({ bannerId: place.bannerId })
                        typeof place.url !== 'undefined' ? window.open(place.url, '_blank') : navigateToDetail(place.venueSlug, place.id)
                      }}>
                        { typeof place.url === 'undefined'
                          ? <div>
                            <div className="geodir-category-img">
                              <div>
                                <div className="special-offer-container">
                                  <div className="offer-name">
                                    <strong>{place.venueName}</strong>
                                  </div>
                                </div>

                                <img src={place.highlightedimage} alt="" /></div>
                              <div className="geodir-category-opt">
                                <div className="listing-rating card-popup-rainingvis" data-starrating2="5"></div>
                              </div>
                            </div>
                            <div className="geodir-category-content fl-wrap title-sin_item" style={{ minHeight: 'unset' }}>
                              <div className="fl-wrap">
                                <div className="geodir-category-content-title-item">
                                  <div className="geodir-category-location fl-wrap geodir-category-content-title"><a href="#" className="map-item"><i className="fas fa-map-marker-alt"></i>{place.city}</a>
                                    <div className="float-end special-offer-date">
                                      {place.eventTimeFrom} - {place.eventTimeTo}
                                    </div>
                                  </div>
                                  <h3 className="title-sin_map highlited-place-name"><span>{place.name}</span></h3>

                                </div>
                              </div>

                            </div>
                          </div>
                          : <div className="geodir-category-img banner-img" >

                            <img src={place.highlightedimage} alt="" />
                          </div>

                        }
                      </article>

                    </div>
                  )
                })}

            </div>
          </div>
        </div>
        {/* top sales end */}
      </section>
      {/* section end */}
      {/* section */}
      <section id="sec2">
        <div className="container">
          <div className="section-title">
            <h2>{t('home:special-places-title')}</h2>
            <span className="section-separator"></span>
            <p>{t('home:special-places-desc')}</p>
          </div>
        </div>
        {/* portfolio start */}
        <div className="container">
          <div className="row row-special">
            { specialPlaces.length === 0 ? <div className="col-md-4 g-3"><SpecialLoader /></div>
              : specialPlaces.map((place) => {
                return (
                  <div key={place.id} className="col-md-4 g-3">
                    <div className="list-item cursor-pointer" onClick={() => navigateToSpecialListing(place.slug) }>
                      <div className="list-item-image">
                        <img src={place.image} alt={place.name}/>
                      </div>
                      <div className="listing-item-cat">
                        <h3 className="text-white">{place.name}</h3>
                        <div className="weather-grid" data-grcity="Rome"></div>
                        <div className="clearfix"></div>
                        <p>{place.description}</p>
                      </div>
                    </div>
                  </div>)
              })}
          </div>
        </div>
        {/* portfolio end */}
      </section>
      {/* section end */}
      {/* section */}
      <section>
        <div className="container">
          <div className="section-title">
            <h2>{t('home:why-choose-us-title')}</h2>
            <span className="section-separator"></span>
            <p>{t('home:why-choose-us-desc')}</p>
          </div>
          {/* */}
          <div className="row">
            <div className="col-lg-4 d-flex align-items-center">
              <div>
                <img src={ContactImage} className="rounded w-100" />
              </div>
            </div>
            <div className="col-lg-8 d-flex align-items-center">
              <div className="flex-column">

                <h4 className="h4 event-title">{t('home:event-organisation')} <span className="event-title-secondary">{t('home:event-organisation-self')}</span></h4>
                <div className="divider-grey"></div>
                <p className="event-desc">{t('home:event-organisation-self-desc')}</p>

                <h5 className="event-title">{t('home:event-organisation')}<br /><span className="event-title-secondary">{t('home:event-organisation-pro')}</span></h5>
                <div className="divider-grey"></div>
                <p className="event-desc">{t('home:event-organisation-pro-desc')}</p>
              </div>
            </div>
            <section>
              <div >
                <h5 className="event-title text-center">Rendezvény szolgáltatók, rendezvényszervezés </h5>
                <div className="divider-grey"></div>
                <p className="event-desc text-center">
                  <b>Rendezvény szolgáltatók, rendezvényszervezés - konferencia, esküvő szervezés -, rendezvényszervező, catering és egyéb rendezvény szolgáltató cégek, esküvőszervezés, esküvő helyszínek, és egyéb rendezvényhelyszínek.<br/>
Minden, ami rendezvény - esküvő, konferencia - szervezéséhez szükséges! Rendezvényszervezés támogatása, profi rendezvényszervező cégek rendezvény ötletei minden rendezvény fajtára - akár esküvő, konferencia színesítésére!</b><br/><br/>
                  Online rendezvényszervező portálunkat a rendezvényszervezés - elsősorban esküvő és konferencia, de minden egyéb rendezvény szervezésének - támogatására hoztuk létre.<br/>
A sikeres rendezvény kulcsa az igényes rendezvényhelyszín és a megbízható partnerek - rendezvényszervező, catering / party service és más rendezvény szolgáltatók - kiválasztása.<br/>
Legyen az bármilyen rendezvény, a rendezvényszervezés bármely formája - esküvő, konferencia, születésnap vagy egyéb rendezvény szervezése -, nálunk megtalálja hozzá a legjobb rendezvényhelyszín üzemeltető, rendezvényszervező, catering / party service, és rendezvény szolgáltató cégeket.<br/>
Ez önmagában - a rendezvényszervező szakma ismeretének hiányában - még nem garantálja a rendezvényszervezés sikerét, de mi segítséget nyújtunk Önnek a rendezvényszervezés különböző aspektusainak ismertetésével is.
                </p>
              </div>
            </section>

          </div>

          <section className="color-bg hidden-section">
            <div className="wave-bg wave-bg2"></div>
            <div className="container">
              <div className="section-title">
                <h2>Olvassa el ismertetőinket</h2>
                <span className="section-separator"></span>
                <p> &nbsp;</p>
              </div>

              <div className="row">
                <div className="col-md-4">

                  <div className="process-item big-pad-pr-item">
                    <span className="process-count"> </span>
                    <div className="time-line-icon"><i className="fal fa-glass-cheers"></i></div>
                    <h4><a href="#">Rendezvény</a></h4>
                    <p>az általános rendezvény fajtákról</p>
                  </div>

                </div>
                <div className="col-md-4">

                  <div className="process-item big-pad-pr-item">
                    <span className="process-count"> </span>
                    <div className="time-line-icon"><i className="fal fa-calendar-check"></i></div>
                    <h4> <a href="#">Rendezvényszervezés</a></h4>
                    <p>a rendezvényszervezés folyamatainak elemzéséről, a rendezvényszervezés részfeladatairól</p>
                  </div>

                </div>
                <div className="col-md-4">

                  <div className="process-item big-pad-pr-item nodecpre">
                    <span className="process-count"> </span>
                    <div className="time-line-icon"><i className="fal fa-headset"></i></div>
                    <h4><a href="#">Rendezvényszervező</a></h4>
                    <p>a profi rendezvényszervező cégek megbízásának előnyeiről, rendezvényszervező partnereink rendezvény ötleteiről</p>
                  </div>

                </div>
              </div>
              <div className="row pt-4">

                <div className="offset-md-2 col-md-4">

                  <div className="process-item big-pad-pr-item">
                    <span className="process-count"> </span>
                    <div className="time-line-icon"><i className="fal fa-ring"></i></div>
                    <h4><a href="#">Esküvő</a></h4>
                    <p>az esküvő, mint rendezvény, és az esküvő szervezés sajátosságai</p>
                  </div>

                </div>
                <div className="col-md-4">

                  <div className="process-item big-pad-pr-item">
                    <span className="process-count"> </span>
                    <div className="time-line-icon"><i className="fal fa-users"></i></div>
                    <h4> <a href="#">Konferencia</a></h4>
                    <p>a konferencia szervezés specialitása, a konferencia egyéb rendezvény fajtákhoz viszonyított egyedi jellemzői.</p>
                  </div>

                </div>

              </div>
            </div>
          </section>
          <section>
            <div className="row">
              <h5 className="event-title text-center">Ha rendezvény, akkor Ön dönt </h5>
              <div className="divider-grey"></div>
              <p className="event-desc text-center">
            Igénybe veheti hivatásos rendezvényszervező segítségét, vagy akár saját kezébe is veheti a rendezvényszervezés irányítását - a rendezvényhelyszínek, a catering / party service és egyéb rendezvény szolgáltatók kiválasztását.<br/>
Portálunk segítségére lesz, ha üzleti konferencia, vagy esküvő helyszínt keres, de segítünk megtalálni a legjobb rendezvényszervező - akár speciálisan konferencia, vagy esküvő szervezésére szakosodott - cégeket is.<br/><br/>

                <span className="home-desc-title">Ismerkedjen hát meg a rendezvényszervező.com-mal!</span><br/>
Találja meg a tökéletes helyszínt, akár esküvő, akár konferencia, akár más rendezvény szervezésén dolgozik! Kérjen ajánlatot a rendezvényszervező, catering / party service, és egyéb rendezvény szolgáltató partnereinktől! Rendezvényszervezés on-line!<br/><br/>

                <span className="home-desc-title">Sikeres és stresszmentes rendezvényszervezést kíván Önnek:</span><br/>
A rendezvényszervező.com csapata<br/><br/>

                <span className="home-desc-title">Amennyiben ezeket keresi:</span><br/>

                <b>rendezvényhelyszínek, esküvő helyszínek, konferencia helyszínek, konferencia központ, rendezvényhajó, konferenciaterem, rendezvényszervező cégek, catering / party service cégek, rendezvény technika szolgáltatók, rendezvény kellék szolgáltatók, fellépők, rendezvény ötletek, szaktanácsok rendezvényszervezés - konferencia, esküvő szervezés - támogatására, esküvő szervezésére szakosodott rendezvényszervező cégek, konferencia szervezésre specializálódott rendezvényszervező cégek...</b><br/><br/>
                <span className="home-desc-title">a következőkhöz:</span><br/>
                <b>rendezvény, esküvő, konferencia, rendezvényszervezés, esküvő szervezés, konferencia szervezés, családi rendezvény szervezés, céges rendezvény szervezés, nyílt fogyasztói rendezvény szervezés...</b><br/><br/>
                <span className="home-desc-title">rendezvényszervező portálunkon megtalálhatja!</span>
              </p>
            </div>
          </section>
        </div>

      </section>
      {/* section end */}
    </>
  )
}

export default HomeComponent
