import { useTranslation } from 'react-i18next'

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ContentLoader from 'react-content-loader'

const Footer = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const BannerLoader = (props) => (
    <ContentLoader
      foregroundColor="#18458b"
      backgroundColor="#18458b"
      viewBox="0 0 1900 160"
      {...props}
    >
      <rect x="0" y="0" rx="0" ry="0" width="1900" height="180" />
    </ContentLoader>
  )

  const { banners } = useSelector(state => state.venue)

  const [lowerBanners, setLowerBanners] = useState(null)

  useEffect(() => {
    dispatch.venue.getBanners()
  }, [dispatch])

  useEffect(() => {
    const tmpBanners = banners.filter((res) => res.type === 'Lower')
    if (tmpBanners.length) { setLowerBanners(tmpBanners[Math.floor((Math.random() * tmpBanners.length))]) }
  }, [banners])

  return (
    <footer className="main-footer">
      <div className="container">
        { (lowerBanners === null) ? <div></div>
          : <a
            onClick={() => {
              dispatch.venue.clickBanner({ bannerId: lowerBanners.bannerId })
              typeof lowerBanners.venue === 'undefined' ? window.open(lowerBanners.url) : window.open('/' + lowerBanners.venue.venueSlug)
            }}
          >
            <div style={{
              height: 120,
              backgroundImage: `url(${lowerBanners.image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}>
            </div>
          </a>
        }
      </div>
      {/* subscribe-wrap */}
      <div className="subscribe-wrap color-bg  fl-wrap">
        <div className="container">
          <div className="sp-bg"> </div>
          <div className="row">
            <div className="col-md-6">
              <div className="subscribe-header">
                <h3>{t('common:subscribe-title')}</h3>
                <p>{t('common:subscribe-desc')}</p>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="footer-widget fl-wrap">
                <div className="subscribe-widget fl-wrap">
                  <div className="subcribe-form">
                    <form id="subscribe">
                      <input className="enteremail fl-wrap" name="email" id="subscribe-email" placeholder={t('common:enter-your-email')} spellCheck="false" type="text"/>
                      <button type="submit" id="subscribe-button" className="subscribe-button"><i className="fas fa-rss-square"></i> {t('common:subscribe-button')}</button>
                      <label htmlFor="subscribe-email" className="subscribe-message"></label>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wave-bg"></div>
      </div>
      {/* subscribe-wrap end  */}
      {/* footer-inner */}
      <div className="footer-inner">
        <div className="container">
          <div className="row">
            {/* footer-widget  */}
            <div className="col-md-4">
              <div className="footer-widget fl-wrap">
                <h3>{t('common:about-us')}</h3>
                <div className="footer-contacts-widget fl-wrap">
                  <p>{t('common:about-us-desc-1')}</p>
                  <div className="divider"></div>
                  <p>{t('common:about-us-desc-2')}</p>
                </div>
              </div>
            </div>
            {/* footer-widget end */}
            {/* footer-widget  */}
            <div className="col-md-4">
              <div className="footer-widget fl-wrap">
                <h3>{t('common:other')}</h3>
                <div className="row">
                  <div className="col-sm-6 others">
                    <ul>
                      <li className="others-list-item">
                        <a href="#" className="others-link">{t('common:footer.menu-item-1')}</a>
                      </li>
                      <li className="others-list-item">
                        <a href="#" className="others-link">{t('common:footer.menu-item-2')}</a>
                      </li>
                      <li className="others-list-item">
                        <a href="#" className="others-link">{t('common:footer.menu-item-3')}</a>
                      </li>
                      <li className="others-list-item">
                        <a href="#" className="others-link">{t('common:footer.menu-item-4')}</a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-6 others">
                    <ul>
                      <li className="others-list-item">
                        <a href="#" className="others-link">{t('common:footer.menu-item-5')}</a>
                      </li>
                      <li className="others-list-item">
                        <a href="#" className="others-link">{t('common:footer.menu-item-6')}</a>
                      </li>
                      <li className="others-list-item">
                        <a href="#" className="others-link">{t('common:footer.menu-item-7')}</a>
                      </li>
                      <li className="others-list-item">
                        <a href="#" className="others-link">{t('common:footer.menu-item-8')}</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="divider"></div>
              </div>
            </div>
            {/* footer-widget end */}
            {/* footer-widget  */}
            <div className="col-md-4">
              <div className="footer-widget fl-wrap">
                <h3>{t('common:contact')}</h3>
                <div className="footer-contacts-widget fl-wrap">
                  <ul className="footer-contacts fl-wrap">
                    <li><span><i className="fal fa-envelope"></i> {t('common:footer-email-title')} :</span><a href="#" target="_blank">{t('common:footer-email-desc')}</a></li>
                    <li> <span><i className="fal fa-map-marker-alt"></i> {t('common:footer-address-title')} :</span><a href="#" target="_blank">{t('common:footer-address-desc')}</a></li>
                    <li><span><i className="fal fa-phone"></i> {t('common:footer-phone-title')} :</span><a href="#">{t('common:footer-phone-desc')}</a></li>
                  </ul>
                </div>
              </div>
            </div>
            {/* footer-widget end */}
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
      {/* footer-inner end  */}
      <div className="footer-bg">
      </div>
      {/* sub-footer */}
      <div className="sub-footer">
        <div className="container">
          <div className="copyright"> &#169; {t('common:all-rights')}</div>
          <div className="subfooter-nav">
            <ul>
              <li><a target="_blank" href="http://www.rendezvenyszervezo.com/aszf/aszf_140808.doc" rel="noreferrer">{t('common:footer.bottom-item-1')}</a></li>
              <li><a href="#">{t('common:footer.bottom-item-2')}</a></li>
              <li><a href="#">{t('common:footer.bottom-item-3')}</a></li>
            </ul>
          </div>
        </div>
      </div>
      {/* sub-footer end  */}
    </footer>
  )
}

export default Footer
