"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class FilterApi extends runtime.BaseAPI {
    /**
     * Get Cities
     */
    getFilterCitiesRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/filter/cities`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.CityFilterResourceFromJSON));
        });
    }
    /**
     * Get Cities
     */
    getFilterCities(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getFilterCitiesRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get Regions
     */
    getFilterRegionsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/filter/regions`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.RegionFilterResourceFromJSON));
        });
    }
    /**
     * Get Regions
     */
    getFilterRegions(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getFilterRegionsRaw(initOverrides);
            return yield response.value();
        });
    }
}
exports.FilterApi = FilterApi;
