"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BannerResourceToJSON = exports.BannerResourceFromJSONTyped = exports.BannerResourceFromJSON = exports.instanceOfBannerResource = void 0;
const runtime_1 = require("../runtime");
const BannerResourceVenue_1 = require("./BannerResourceVenue");
/**
 * Check if a given object implements the BannerResource interface.
 */
function instanceOfBannerResource(value) {
    let isInstance = true;
    isInstance = isInstance && "bannerId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "image" in value;
    return isInstance;
}
exports.instanceOfBannerResource = instanceOfBannerResource;
function BannerResourceFromJSON(json) {
    return BannerResourceFromJSONTyped(json, false);
}
exports.BannerResourceFromJSON = BannerResourceFromJSON;
function BannerResourceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'bannerId': json['banner_id'],
        'name': json['name'],
        'url': !(0, runtime_1.exists)(json, 'url') ? undefined : json['url'],
        'type': json['type'],
        'venue': !(0, runtime_1.exists)(json, 'venue') ? undefined : (0, BannerResourceVenue_1.BannerResourceVenueFromJSON)(json['venue']),
        'image': json['image'],
    };
}
exports.BannerResourceFromJSONTyped = BannerResourceFromJSONTyped;
function BannerResourceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'banner_id': value.bannerId,
        'name': value.name,
        'url': value.url,
        'type': value.type,
        'venue': (0, BannerResourceVenue_1.BannerResourceVenueToJSON)(value.venue),
        'image': value.image,
    };
}
exports.BannerResourceToJSON = BannerResourceToJSON;
