import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import huMessages from './locales/hu/messages.json'
import enMessages from './locales/en/messages.json'
import huCommon from './locales/hu/common.json'
import enCommon from './locales/en/common.json'
import huHome from './locales/hu/home.json'
import enHome from './locales/en/home.json'
import huListing from './locales/hu/listing.json'
import enListing from './locales/en/listing.json'
import huDetail from './locales/hu/detail.json'
import enDetail from './locales/en/detail.json'
import huLogin from './locales/hu/login.json'
import enLogin from './locales/en/login.json'
import huRequest from './locales/hu/request.json'
import enRequest from './locales/en/request.json'
import huSubscriptionfee from './locales/hu/subscriptionfee.json'
import enSubscriptionfee from './locales/en/subscriptionfee.json'

const lng = localStorage.getItem('rnd-lang') || 'hu'

const resources = {
  hu: {
    messages: huMessages,
    common: huCommon,
    home: huHome,
    listing: huListing,
    detail: huDetail,
    login: huLogin,
    request: huRequest,
    subscriptionfee: huSubscriptionfee
  },
  en: {
    messages: enMessages,
    common: enCommon,
    home: enHome,
    listing: enListing,
    detail: enDetail,
    login: enLogin,
    request: enRequest,
    subscriptionfee: enSubscriptionfee
  }
}

i18n.use(initReactI18next).init({
  lng,
  interpolation: {
    escapeValue: false
  },
  resources,
  returnEmptyString: false
})

export default i18n
