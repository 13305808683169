"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BannerResourceVenueToJSON = exports.BannerResourceVenueFromJSONTyped = exports.BannerResourceVenueFromJSON = exports.instanceOfBannerResourceVenue = void 0;
/**
 * Check if a given object implements the BannerResourceVenue interface.
 */
function instanceOfBannerResourceVenue(value) {
    let isInstance = true;
    isInstance = isInstance && "venueSlug" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "tel" in value;
    isInstance = isInstance && "city" in value;
    return isInstance;
}
exports.instanceOfBannerResourceVenue = instanceOfBannerResourceVenue;
function BannerResourceVenueFromJSON(json) {
    return BannerResourceVenueFromJSONTyped(json, false);
}
exports.BannerResourceVenueFromJSON = BannerResourceVenueFromJSON;
function BannerResourceVenueFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'venueSlug': json['venue_slug'],
        'name': json['name'],
        'tel': json['tel'],
        'city': json['city'],
    };
}
exports.BannerResourceVenueFromJSONTyped = BannerResourceVenueFromJSONTyped;
function BannerResourceVenueToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'venue_slug': value.venueSlug,
        'name': value.name,
        'tel': value.tel,
        'city': value.city,
    };
}
exports.BannerResourceVenueToJSON = BannerResourceVenueToJSON;
