import { init } from '@rematch/core'
import { models } from './models'
import immerPlugin from '@rematch/immer'
import persistPlugin from '@rematch/persist'
import storage from 'redux-persist/lib/storage'
import { createMigrate } from 'redux-persist'
import loadingPlugin from '@rematch/loading'

const migrations = {
  // 1: () => {
  //   return undefined
  // }
}

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
  version: -1,
  migrate: createMigrate(migrations, { debug: false })
}

export const store = init({
  models,
  plugins: [
    immerPlugin(),
    loadingPlugin({ type: 'full' }),
    persistPlugin(persistConfig)
  ],
  redux: {
    devtoolOptions: {
      disabled: process.env.NODE_ENV === 'production'
    }
  }
})
