import Cookies from 'js-cookie'
import { AuthenticationApi, Configuration, FilterApi, UserApi, VenuesApi } from '@rendezveny/api-client'
const AppConfig = require('../config/AppConfig')

class GlobalMiddleware {
  async post (context) {
    if (!context.response.ok) {
      const response = await context.response.json()
      return response
    }
  }
}

const apiClient = new Configuration({
  basePath: AppConfig.ApiUrl,
  accessToken: () => Cookies.get('rendezveny-token'),
  middleware: [new GlobalMiddleware()],
  headers: {
    Accept: 'application/json'
  }
})

export const authenticationApi = new AuthenticationApi(apiClient)
export const userApi = new UserApi(apiClient)
export const venuesApi = new VenuesApi(apiClient)
export const filterApi = new FilterApi(apiClient)
