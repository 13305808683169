"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilteredVenuesResourceDataInnerAddressToJSON = exports.FilteredVenuesResourceDataInnerAddressFromJSONTyped = exports.FilteredVenuesResourceDataInnerAddressFromJSON = exports.instanceOfFilteredVenuesResourceDataInnerAddress = void 0;
/**
 * Check if a given object implements the FilteredVenuesResourceDataInnerAddress interface.
 */
function instanceOfFilteredVenuesResourceDataInnerAddress(value) {
    let isInstance = true;
    isInstance = isInstance && "region" in value;
    isInstance = isInstance && "city" in value;
    return isInstance;
}
exports.instanceOfFilteredVenuesResourceDataInnerAddress = instanceOfFilteredVenuesResourceDataInnerAddress;
function FilteredVenuesResourceDataInnerAddressFromJSON(json) {
    return FilteredVenuesResourceDataInnerAddressFromJSONTyped(json, false);
}
exports.FilteredVenuesResourceDataInnerAddressFromJSON = FilteredVenuesResourceDataInnerAddressFromJSON;
function FilteredVenuesResourceDataInnerAddressFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'region': json['region'],
        'city': json['city'],
    };
}
exports.FilteredVenuesResourceDataInnerAddressFromJSONTyped = FilteredVenuesResourceDataInnerAddressFromJSONTyped;
function FilteredVenuesResourceDataInnerAddressToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'region': value.region,
        'city': value.city,
    };
}
exports.FilteredVenuesResourceDataInnerAddressToJSON = FilteredVenuesResourceDataInnerAddressToJSON;
