"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CityFilterResourceOptionsInnerToJSON = exports.CityFilterResourceOptionsInnerFromJSONTyped = exports.CityFilterResourceOptionsInnerFromJSON = exports.instanceOfCityFilterResourceOptionsInner = void 0;
/**
 * Check if a given object implements the CityFilterResourceOptionsInner interface.
 */
function instanceOfCityFilterResourceOptionsInner(value) {
    let isInstance = true;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "label" in value;
    return isInstance;
}
exports.instanceOfCityFilterResourceOptionsInner = instanceOfCityFilterResourceOptionsInner;
function CityFilterResourceOptionsInnerFromJSON(json) {
    return CityFilterResourceOptionsInnerFromJSONTyped(json, false);
}
exports.CityFilterResourceOptionsInnerFromJSON = CityFilterResourceOptionsInnerFromJSON;
function CityFilterResourceOptionsInnerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'value': json['value'],
        'label': json['label'],
    };
}
exports.CityFilterResourceOptionsInnerFromJSONTyped = CityFilterResourceOptionsInnerFromJSONTyped;
function CityFilterResourceOptionsInnerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'value': value.value,
        'label': value.label,
    };
}
exports.CityFilterResourceOptionsInnerToJSON = CityFilterResourceOptionsInnerToJSON;
