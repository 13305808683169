"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VenueResourceSpecialOffersInnerToJSON = exports.VenueResourceSpecialOffersInnerFromJSONTyped = exports.VenueResourceSpecialOffersInnerFromJSON = exports.instanceOfVenueResourceSpecialOffersInner = void 0;
const VenueResourcePlaceEventsInnerItemsInner_1 = require("./VenueResourcePlaceEventsInnerItemsInner");
/**
 * Check if a given object implements the VenueResourceSpecialOffersInner interface.
 */
function instanceOfVenueResourceSpecialOffersInner(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "highlighted" in value;
    isInstance = isInstance && "eventTimeFrom" in value;
    isInstance = isInstance && "eventTimeTo" in value;
    isInstance = isInstance && "eventType" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "category" in value;
    return isInstance;
}
exports.instanceOfVenueResourceSpecialOffersInner = instanceOfVenueResourceSpecialOffersInner;
function VenueResourceSpecialOffersInnerFromJSON(json) {
    return VenueResourceSpecialOffersInnerFromJSONTyped(json, false);
}
exports.VenueResourceSpecialOffersInnerFromJSON = VenueResourceSpecialOffersInnerFromJSON;
function VenueResourceSpecialOffersInnerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'highlighted': json['highlighted'],
        'eventTimeFrom': json['event_time_from'],
        'eventTimeTo': json['event_time_to'],
        'eventType': (json['event_type'].map(VenueResourcePlaceEventsInnerItemsInner_1.VenueResourcePlaceEventsInnerItemsInnerFromJSON)),
        'type': (json['type'].map(VenueResourcePlaceEventsInnerItemsInner_1.VenueResourcePlaceEventsInnerItemsInnerFromJSON)),
        'category': (json['category'].map(VenueResourcePlaceEventsInnerItemsInner_1.VenueResourcePlaceEventsInnerItemsInnerFromJSON)),
    };
}
exports.VenueResourceSpecialOffersInnerFromJSONTyped = VenueResourceSpecialOffersInnerFromJSONTyped;
function VenueResourceSpecialOffersInnerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'highlighted': value.highlighted,
        'event_time_from': value.eventTimeFrom,
        'event_time_to': value.eventTimeTo,
        'event_type': (value.eventType.map(VenueResourcePlaceEventsInnerItemsInner_1.VenueResourcePlaceEventsInnerItemsInnerToJSON)),
        'type': (value.type.map(VenueResourcePlaceEventsInnerItemsInner_1.VenueResourcePlaceEventsInnerItemsInnerToJSON)),
        'category': (value.category.map(VenueResourcePlaceEventsInnerItemsInner_1.VenueResourcePlaceEventsInnerItemsInnerToJSON)),
    };
}
exports.VenueResourceSpecialOffersInnerToJSON = VenueResourceSpecialOffersInnerToJSON;
