"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VenueResourceImagesInnerToJSON = exports.VenueResourceImagesInnerFromJSONTyped = exports.VenueResourceImagesInnerFromJSON = exports.instanceOfVenueResourceImagesInner = void 0;
const VenueResourceImagesInnerDetails_1 = require("./VenueResourceImagesInnerDetails");
/**
 * Check if a given object implements the VenueResourceImagesInner interface.
 */
function instanceOfVenueResourceImagesInner(value) {
    let isInstance = true;
    isInstance = isInstance && "original" in value;
    isInstance = isInstance && "thumbnail" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "details" in value;
    return isInstance;
}
exports.instanceOfVenueResourceImagesInner = instanceOfVenueResourceImagesInner;
function VenueResourceImagesInnerFromJSON(json) {
    return VenueResourceImagesInnerFromJSONTyped(json, false);
}
exports.VenueResourceImagesInnerFromJSON = VenueResourceImagesInnerFromJSON;
function VenueResourceImagesInnerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'original': json['original'],
        'thumbnail': json['thumbnail'],
        'description': json['description'],
        'details': (0, VenueResourceImagesInnerDetails_1.VenueResourceImagesInnerDetailsFromJSON)(json['details']),
    };
}
exports.VenueResourceImagesInnerFromJSONTyped = VenueResourceImagesInnerFromJSONTyped;
function VenueResourceImagesInnerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'original': value.original,
        'thumbnail': value.thumbnail,
        'description': value.description,
        'details': (0, VenueResourceImagesInnerDetails_1.VenueResourceImagesInnerDetailsToJSON)(value.details),
    };
}
exports.VenueResourceImagesInnerToJSON = VenueResourceImagesInnerToJSON;
