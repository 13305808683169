import auth from './auth'
import general from './general'
import venue from './venue'
import filter from './filter'

export const models = {
  auth,
  general,
  venue,
  filter
}
