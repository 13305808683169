"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VenuesResourceToJSON = exports.VenuesResourceFromJSONTyped = exports.VenuesResourceFromJSON = exports.instanceOfVenuesResource = void 0;
const SpecialOfferResourceMeta_1 = require("./SpecialOfferResourceMeta");
const VenuesResourceDataInner_1 = require("./VenuesResourceDataInner");
/**
 * Check if a given object implements the VenuesResource interface.
 */
function instanceOfVenuesResource(value) {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;
    return isInstance;
}
exports.instanceOfVenuesResource = instanceOfVenuesResource;
function VenuesResourceFromJSON(json) {
    return VenuesResourceFromJSONTyped(json, false);
}
exports.VenuesResourceFromJSON = VenuesResourceFromJSON;
function VenuesResourceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'data': (json['data'].map(VenuesResourceDataInner_1.VenuesResourceDataInnerFromJSON)),
        'meta': (0, SpecialOfferResourceMeta_1.SpecialOfferResourceMetaFromJSON)(json['meta']),
    };
}
exports.VenuesResourceFromJSONTyped = VenuesResourceFromJSONTyped;
function VenuesResourceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'data': (value.data.map(VenuesResourceDataInner_1.VenuesResourceDataInnerToJSON)),
        'meta': (0, SpecialOfferResourceMeta_1.SpecialOfferResourceMetaToJSON)(value.meta),
    };
}
exports.VenuesResourceToJSON = VenuesResourceToJSON;
