"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PostRequestOfferToJSON = exports.PostRequestOfferFromJSONTyped = exports.PostRequestOfferFromJSON = exports.instanceOfPostRequestOffer = void 0;
const runtime_1 = require("../runtime");
/**
 * Check if a given object implements the PostRequestOffer interface.
 */
function instanceOfPostRequestOffer(value) {
    let isInstance = true;
    isInstance = isInstance && "venueIds" in value;
    isInstance = isInstance && "subject" in value;
    isInstance = isInstance && "contactPerson" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "validityOfferDate" in value;
    return isInstance;
}
exports.instanceOfPostRequestOffer = instanceOfPostRequestOffer;
function PostRequestOfferFromJSON(json) {
    return PostRequestOfferFromJSONTyped(json, false);
}
exports.PostRequestOfferFromJSON = PostRequestOfferFromJSON;
function PostRequestOfferFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'venueIds': json['venue_ids'],
        'subject': json['subject'],
        'contactPerson': json['contact_person'],
        'companyName': !(0, runtime_1.exists)(json, 'company_name') ? undefined : json['company_name'],
        'email': json['email'],
        'city': !(0, runtime_1.exists)(json, 'city') ? undefined : json['city'],
        'address': !(0, runtime_1.exists)(json, 'address') ? undefined : json['address'],
        'phone': !(0, runtime_1.exists)(json, 'phone') ? undefined : json['phone'],
        'eventStart': !(0, runtime_1.exists)(json, 'event_start') ? undefined : json['event_start'],
        'eventEnd': !(0, runtime_1.exists)(json, 'event_end') ? undefined : json['event_end'],
        'numberOfParticipants': !(0, runtime_1.exists)(json, 'number_of_participants') ? undefined : json['number_of_participants'],
        'numberOfRoomsRequired': !(0, runtime_1.exists)(json, 'number_of_rooms_required') ? undefined : json['number_of_rooms_required'],
        'accommodation': !(0, runtime_1.exists)(json, 'accommodation') ? undefined : json['accommodation'],
        'requiredAccommodation': !(0, runtime_1.exists)(json, 'required_accommodation') ? undefined : json['required_accommodation'],
        'technicalRequirements': !(0, runtime_1.exists)(json, 'technical_requirements') ? undefined : json['technical_requirements'],
        'otherRequirements': !(0, runtime_1.exists)(json, 'other_requirements') ? undefined : json['other_requirements'],
        'validityOfferDate': json['validity_offer_date'],
        'requestCallback': !(0, runtime_1.exists)(json, 'request_callback') ? undefined : json['request_callback'],
    };
}
exports.PostRequestOfferFromJSONTyped = PostRequestOfferFromJSONTyped;
function PostRequestOfferToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'venue_ids': value.venueIds,
        'subject': value.subject,
        'contact_person': value.contactPerson,
        'company_name': value.companyName,
        'email': value.email,
        'city': value.city,
        'address': value.address,
        'phone': value.phone,
        'event_start': value.eventStart,
        'event_end': value.eventEnd,
        'number_of_participants': value.numberOfParticipants,
        'number_of_rooms_required': value.numberOfRoomsRequired,
        'accommodation': value.accommodation,
        'required_accommodation': value.requiredAccommodation,
        'technical_requirements': value.technicalRequirements,
        'other_requirements': value.otherRequirements,
        'validity_offer_date': value.validityOfferDate,
        'request_callback': value.requestCallback,
    };
}
exports.PostRequestOfferToJSON = PostRequestOfferToJSON;
