import classNames from 'classnames'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ReactSelect, { components } from 'react-select'
import PropTypes from 'prop-types'

const SelectWidget = (props) => {
  const { t } = useTranslation()
  const { pure, icon, className, ...restProps } = props

  const customStyles = useMemo(() => ({
    container: (provided, state) => ({
      ...provided,
      color: '#666'
    }),
    control: (provided, state) => ({
      ...provided,
      border: '1px solid #EEE !important',
      borderRadius: 6,
      height: 42,
      cursor: 'pointer'
    }),
    input: (provided, state) => ({
      ...provided,
      color: '#666'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: '#3AACED',
      width: 32
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#666',
      fontWeight: 400
    })
  }), [])

  const Control = ({ children, ...props }) => (
    <components.Control {...props}>
      <div className="d-flex align-items-center text-start w-100">
        {children}
      </div>
    </components.Control>
  )

  const DropdownIndicator = ({ children, ...props }) => (
    <components.DropdownIndicator {...props}>
      <i className="fal fa-sort-down text-blue fw-bold ps-1 pe-3 pb-1"></i>
    </components.DropdownIndicator>
  )

  Control.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
  }

  DropdownIndicator.propTypes = {
    children: PropTypes.element
  }

  return (
    <ReactSelect
      components={{ Control, DropdownIndicator }}
      classNamePrefix="react-select"
      className={classNames('react-select', className, { 'react-select-pure': pure })}
      placeholder={t('common:select-item')}
      styles={customStyles}
      {...restProps}
    />
  )
}

export default SelectWidget

SelectWidget.propTypes = {
  pure: PropTypes.bool,
  icon: PropTypes.element,
  className: PropTypes.string
}
