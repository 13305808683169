"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthenticationApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class AuthenticationApi extends runtime.BaseAPI {
    /**
     * Change user password
     */
    changePasswordRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters.acceptLanguage !== undefined && requestParameters.acceptLanguage !== null) {
                headerParameters['Accept-Language'] = String(requestParameters.acceptLanguage);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("sanctum", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/change_password`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: (0, models_1.UserChangePasswordToJSON)(requestParameters.userChangePassword),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.ReadyResourceFromJSON)(jsonValue));
        });
    }
    /**
     * Change user password
     */
    changePassword(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.changePasswordRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Email verification notification
     */
    emailVerificationNotificationRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.acceptLanguage !== undefined && requestParameters.acceptLanguage !== null) {
                headerParameters['Accept-Language'] = String(requestParameters.acceptLanguage);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("sanctum", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/email/verification-notification`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.ReadyResourceFromJSON)(jsonValue));
        });
    }
    /**
     * Email verification notification
     */
    emailVerificationNotification(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.emailVerificationNotificationRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Forgot password
     * Forgot password
     */
    forgotPasswordRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters.acceptLanguage !== undefined && requestParameters.acceptLanguage !== null) {
                headerParameters['Accept-Language'] = String(requestParameters.acceptLanguage);
            }
            const response = yield this.request({
                path: `/api/forgot-password`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: (0, models_1.UserForgotPasswordToJSON)(requestParameters.userForgotPassword),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.ReadyResourceFromJSON)(jsonValue));
        });
    }
    /**
     * Forgot password
     * Forgot password
     */
    forgotPassword(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.forgotPasswordRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * User login
     * User login
     */
    postLoginRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters.acceptLanguage !== undefined && requestParameters.acceptLanguage !== null) {
                headerParameters['Accept-Language'] = String(requestParameters.acceptLanguage);
            }
            const response = yield this.request({
                path: `/api/login`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: (0, models_1.UserLoginToJSON)(requestParameters.userLogin),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.LoginSuccessfulFromJSON)(jsonValue));
        });
    }
    /**
     * User login
     * User login
     */
    postLogin(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.postLoginRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * User logout
     */
    postLogoutRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("sanctum", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/logout`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.ReadyResourceFromJSON)(jsonValue));
        });
    }
    /**
     * User logout
     */
    postLogout(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.postLogoutRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * User registration
     * User registration
     */
    postRegisterRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters.acceptLanguage !== undefined && requestParameters.acceptLanguage !== null) {
                headerParameters['Accept-Language'] = String(requestParameters.acceptLanguage);
            }
            const response = yield this.request({
                path: `/api/register`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: (0, models_1.UserRegistrationToJSON)(requestParameters.userRegistration),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.RegistrationSuccessfulFromJSON)(jsonValue));
        });
    }
    /**
     * User registration
     * User registration
     */
    postRegister(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.postRegisterRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Reset password
     * Reset password
     */
    resetPasswordRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters.acceptLanguage !== undefined && requestParameters.acceptLanguage !== null) {
                headerParameters['Accept-Language'] = String(requestParameters.acceptLanguage);
            }
            const response = yield this.request({
                path: `/api/reset-password`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: (0, models_1.UserResetPasswordToJSON)(requestParameters.userResetPassword),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.ReadyResourceFromJSON)(jsonValue));
        });
    }
    /**
     * Reset password
     * Reset password
     */
    resetPassword(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.resetPasswordRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Verify Email
     */
    verifyEmailRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling verifyEmail.');
            }
            if (requestParameters.hash === null || requestParameters.hash === undefined) {
                throw new runtime.RequiredError('hash', 'Required parameter requestParameters.hash was null or undefined when calling verifyEmail.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.acceptLanguage !== undefined && requestParameters.acceptLanguage !== null) {
                headerParameters['Accept-Language'] = String(requestParameters.acceptLanguage);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("sanctum", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/verify-email/{id}/{hash}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"hash"}}`, encodeURIComponent(String(requestParameters.hash))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.ReadyResourceFromJSON)(jsonValue));
        });
    }
    /**
     * Verify Email
     */
    verifyEmail(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.verifyEmailRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
exports.AuthenticationApi = AuthenticationApi;
