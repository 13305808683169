import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ModalType } from 'utils/enum'

import { toast } from 'react-toastify'

const ForgottenPasswordComponent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { visibleModal } = useSelector(state => state.auth)

  const handleHideForgottenPasswordModal = () => {
    dispatch.auth.setVisibleModal(null)
  }

  const isVisibleModal = useMemo(() => visibleModal === ModalType.ForgottenPassword, [visibleModal])

  const [request, setRequest] = useState({

    email: ''

  })

  const onInputChange = e => {
    const { name, value } = e.target
    setRequest(prev => ({
      ...prev,
      [name]: value
    }))
  }

  useEffect(() => {
    document.body.style.overflow = isVisibleModal ? 'hidden' : null
  }, [isVisibleModal])

  const handleForgotPassword = (event) => {
    async function fetchData () {
      const response = await dispatch.auth.forgotPassword(request.email)
      if (typeof response.errors !== 'undefined' || typeof response.exception !== 'undefined') {
        if (typeof response.errors.errorCode !== 'undefined' && typeof response.errors.field !== 'undefined' && typeof response.errors.errorMessage !== 'undefined') { toast.warning(response.errors.errorMessage) } else
        if (typeof response.errors.errorCode !== 'undefined') { toast.warning(t('messages:' + response.errors.errorCode)) } else { toast.warning(response.message ?? response.errors.message) }
      } else {
        dispatch.auth.setVisibleModal(false)
        toast.success(t('login:forgot-email-sent'))
      }
    }
    event.preventDefault()
    fetchData()
  }

  return (
    <div className={classNames('main-register-wrap modal', { 'd-block': isVisibleModal })}>
      <div className="reg-overlay"></div>
      <div className="main-register-holder">
        <div className="main-register fl-wrap">
          <div className="close-reg color-bg" onClick={handleHideForgottenPasswordModal}>
            <i className="fal fa-times"></i>
          </div>
          {/* tabs */}
          <div id="tabs-container">
            <div className="tab">
              {/* tab */}
              <div id="tab-1" className="tab-content d-block">
                <h3>{t('login:forgotten-password')} <br/><span>rendezvény<strong>szervező</strong></span></h3>
                <div className="custom-form">
                  <form onSubmit={handleForgotPassword} >
                    <label className="form-required">{t('login:email-address')} </label>
                    <input name="email" type="email" onChange={onInputChange} required/>
                    <button type="submit" className="log-submit-btn color-bg mb-4"><span>{t('login:reset-password')}</span></button>
                  </form>
                </div>
              </div>
              {/* tab end */}
            </div>
            {/* tabs end */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgottenPasswordComponent
