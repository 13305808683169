"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VenueResourceConferenceRoomToJSON = exports.VenueResourceConferenceRoomFromJSONTyped = exports.VenueResourceConferenceRoomFromJSON = exports.instanceOfVenueResourceConferenceRoom = void 0;
const VenueResourceConferenceRoomRoomsInner_1 = require("./VenueResourceConferenceRoomRoomsInner");
const VenueResourcePlaceEventsInnerItemsInner_1 = require("./VenueResourcePlaceEventsInnerItemsInner");
/**
 * Check if a given object implements the VenueResourceConferenceRoom interface.
 */
function instanceOfVenueResourceConferenceRoom(value) {
    let isInstance = true;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "chair" in value;
    isInstance = isInstance && "table" in value;
    isInstance = isInstance && "rooms" in value;
    isInstance = isInstance && "technical" in value;
    return isInstance;
}
exports.instanceOfVenueResourceConferenceRoom = instanceOfVenueResourceConferenceRoom;
function VenueResourceConferenceRoomFromJSON(json) {
    return VenueResourceConferenceRoomFromJSONTyped(json, false);
}
exports.VenueResourceConferenceRoomFromJSON = VenueResourceConferenceRoomFromJSON;
function VenueResourceConferenceRoomFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'description': json['description'],
        'chair': json['chair'],
        'table': json['table'],
        'rooms': (json['rooms'].map(VenueResourceConferenceRoomRoomsInner_1.VenueResourceConferenceRoomRoomsInnerFromJSON)),
        'technical': (json['technical'].map(VenueResourcePlaceEventsInnerItemsInner_1.VenueResourcePlaceEventsInnerItemsInnerFromJSON)),
    };
}
exports.VenueResourceConferenceRoomFromJSONTyped = VenueResourceConferenceRoomFromJSONTyped;
function VenueResourceConferenceRoomToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'description': value.description,
        'chair': value.chair,
        'table': value.table,
        'rooms': (value.rooms.map(VenueResourceConferenceRoomRoomsInner_1.VenueResourceConferenceRoomRoomsInnerToJSON)),
        'technical': (value.technical.map(VenueResourcePlaceEventsInnerItemsInner_1.VenueResourcePlaceEventsInnerItemsInnerToJSON)),
    };
}
exports.VenueResourceConferenceRoomToJSON = VenueResourceConferenceRoomToJSON;
