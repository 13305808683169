import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ModalType } from 'utils/enum'

import { toast } from 'react-toastify'

const LoginComponent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { visibleModal } = useSelector(state => state.auth)
  const [activeTab, setActiveTab] = useState('login') // login | register

  const [request, setRequest] = useState({
    name: '',
    email: '',
    password: '',
    passwordConfirmation: ''
  })

  const [error, setError] = useState({
    name: '',
    email: '',
    password: '',
    passwordConfirmation: ''
  })

  const onInputChange = e => {
    const { name, value } = e.target
    setRequest(prev => ({
      ...prev,
      [name]: value
    }))
    validateInput(e)
  }

  const handleRegRequest = (event) => {
    async function fetchData () {
      const response = await dispatch.auth.userRegistration(request)
      if (typeof response.errors !== 'undefined' || typeof response.exception !== 'undefined') {
        if (typeof response.errors.errorCode !== 'undefined' && typeof response.errors.field !== 'undefined' && typeof response.errors.errorMessage !== 'undefined') { toast.warning(response.errors.errorMessage) } else
        if (typeof response.errors.errorCode !== 'undefined') { toast.warning(t('messages:' + response.errors.errorCode)) } else { toast.warning(response.message ?? response.errors.message) }
      } else {
        toast.success(t('login:registration-successful'))
      }
    }
    event.preventDefault()
    fetchData()
  }

  const handleLoginRequest = (event) => {
    async function fetchData () {
      const response = await dispatch.auth.userLogin(request)
      if (typeof response.errors !== 'undefined' || typeof response.exception !== 'undefined') {
        if (typeof response.errors.errorCode !== 'undefined' && typeof response.errors.field !== 'undefined' && typeof response.errors.errorMessage !== 'undefined') { toast.warning(response.errors.errorMessage) } else
        if (typeof response.errors.errorCode !== 'undefined') { toast.warning(t('messages:' + response.errors.errorCode)) } else { toast.warning(response.message ?? response.errors.message) }
      } else {
        toast.success(t('login:login-successful'))
      }
    }
    event.preventDefault()
    fetchData()
  }

  const validateInput = e => {
    const { name, value } = e.target
    setError(prev => {
      const stateObj = { ...prev, [name]: '' }

      switch (name) {
      case 'passwordConfirmation':
        if (request.password && value !== request.password) {
          stateObj[name] = t('login:config-password-not-match')
        }
        break

      default:
        break
      }

      return stateObj
    })
  }

  const isVisibleModal = useMemo(() => visibleModal === ModalType.LoginRegister, [visibleModal])

  const handleHideLoginModal = () => {
    dispatch.auth.setVisibleModal(null)
  }

  const handleSetForgottenPasswordModalVisible = () => {
    dispatch.auth.setVisibleModal(ModalType.ForgottenPassword)
  }

  useEffect(() => {
    document.body.style.overflow = isVisibleModal ? 'hidden' : null
  }, [isVisibleModal])

  return (
    <div className={classNames('main-register-wrap modal', { 'd-block': isVisibleModal })}>
      <div className="reg-overlay"></div>
      <div className="main-register-holder">
        <div className="main-register fl-wrap">
          <div className="close-reg color-bg" onClick={handleHideLoginModal}>
            <i className="fal fa-times"></i>
          </div>
          <ul className="tabs-menu">
            <li className={classNames({ current: activeTab === 'login' })}>
              <a href="#tab-1" onClick={() => setActiveTab('login')}>
                <i className="fal fa-sign-in-alt"></i> {t('login:login')}
              </a>
            </li>
            <li className={classNames({ current: activeTab === 'register' })}>
              <a href="#tab-2" onClick={() => setActiveTab('register')}>
                <i className="fal fa-user-plus"></i> {t('login:register')}
              </a>
            </li>
          </ul>
          {/* tabs */}
          <div id="tabs-container">
            <div className="tab">
              {/* tab */}
              <div id="tab-1" className={classNames('tab-content', { 'd-block': activeTab === 'login' })}>
                <h3>{t('login:sign-in')} <br/><span>rendezvény<strong>szervező</strong></span></h3>
                <div className="custom-form">
                  <form onSubmit={handleLoginRequest} >
                    <label className="form-required">{t('login:email-address')} </label>
                    <input name="email" type="email" value={request.email} onChange={onInputChange} required/>
                    <label className="form-required">{t('login:password')} </label>
                    <input name="password" type="password" value={request.password} onChange={onInputChange} required/>
                    <button type="submit" className="log-submit-btn color-bg"><span>{t('login:login')}</span></button>
                    <div className="clearfix"></div>
                    <div className="filter-tags">
                      <input id="check-a" type="checkbox" name="check" />
                      <label htmlFor="check-a">{t('login:remember-me')}</label>
                    </div>
                  </form>
                  <div className="lost_password">
                    <a href="#" onClick={handleSetForgottenPasswordModalVisible}>{t('login:lost-your-password')}</a>
                  </div>
                </div>
              </div>
              {/* tab end */}
              {/* tab */}
              <div className="tab">
                <div id="tab-2" className={classNames('tab-content', { 'd-block': activeTab === 'register' })}>
                  <h3>{t('login:sign-up')} <br/><span>rendezvény<strong>szervező</strong></span></h3>
                  <div className="custom-form">
                    <form onSubmit={handleRegRequest} className="main-register-form" id="main-register-form2">
                      <label className="form-required">{t('login:fullname')}</label>
                      <input name="name" type="text" value={request.name} onChange={onInputChange} required/>
                      <label className="form-required">{t('login:email-address')} </label>
                      <input name="email" type="email" value={request.email} onChange={onInputChange} required
                      />
                      <label className="form-required"> {t('login:password')} </label>
                      <input name="password" type="password" value={request.password} onChange={onInputChange} required/>
                      <label className="form-required" >{t('login:password_confirmation')} </label>
                      <input name="passwordConfirmation" type="password" value={request.passwordConfirmation}
                        onChange={onInputChange} required />

                      {error.passwordConfirmation && <div><span className="error">{error.passwordConfirmation}</span></div>}
                      <button type="submit" className="log-submit-btn color-bg mb-3" ><span>{t('login:register')}</span></button>
                    </form>
                  </div>
                </div>
              </div>
              {/* tab end */}
            </div>
            {/* tabs end */}
            {/*
            <div className="log-separator fl-wrap"><span>{t('login:or')}</span></div>
            <div className="soc-log fl-wrap">
              <p>{t('login:social-account')}</p>
              <a href="#" className="facebook-log"><i className="fab fa-facebook-f"></i>{t('login:connect-with-facebook')}</a>
            </div>
            */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginComponent
