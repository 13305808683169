import LogoImage from '../../assets/images/logo.png'
import LanEn from '../../assets/images/lan/1.png'
// import LanFr from '../../assets/images/lan/2.png'
// import LanEs from '../../assets/images/lan/3.png'
// import LanDe from '../../assets/images/lan/4.png'
// import LanIt from '../../assets/images/lan/5.png'
import LanHu from '../../assets/images/lan/6.png'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import i18n from 'i18n'
import { useDispatch, useSelector } from 'react-redux'
import { ModalType } from 'utils/enum'
import { useNavigate } from 'react-router-dom'

import ContentLoader from 'react-content-loader'
import { toast } from 'react-toastify'

const Header = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const BannerLoader = (props) => (
    <ContentLoader
      foregroundColor="#18458b"
      backgroundColor="#18458b"
      viewBox="0 0 1900 160"
      {...props}
    >
      <rect x="0" y="0" rx="0" ry="0" width="1900" height="180" />
    </ContentLoader>
  )

  const [isFavoritesVisible, setFavoritesVisible] = useState(null)
  const [isOfferVisible, setOfferVisible] = useState(null)
  const [isMenuVisible, setMenuVisible] = useState(false)
  const [lang, setLang] = useState(localStorage.getItem('rnd-lang') || 'hu')
  const { favorites, callForOffer } = useSelector(state => state.venue)

  const { loggedInUser } = useSelector((state) => state.auth)
  const { banners } = useSelector(state => state.venue)

  const [upperBanners, setUpperBanners] = useState(null)

  const setLanguage = (val) => {
    setLang(val)
    localStorage.setItem('rnd-lang', val)
    i18n.changeLanguage(val)
    window.location.reload(false)
  }

  const refFavorites = useRef()
  const refOffer = useRef()

  useEffect(() => {
    dispatch.venue.getBanners()
  }, [dispatch])

  useEffect(() => {
    const tmpBanners = banners.filter((res) => res.type === 'Upper')
    if (tmpBanners.length) { setUpperBanners(tmpBanners[Math.floor((Math.random() * tmpBanners.length))]) }
  }, [banners])

  useEffect(() => {
    if (isFavoritesVisible === null) { setFavoritesVisible(false) } else { setFavoritesVisible(true) }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favorites])

  useEffect(() => {
    if (isOfferVisible === null) { setOfferVisible(false) } else { setOfferVisible(true) }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callForOffer])

  useEffect(() => {
    const handleClick = (event) => {
      if (refFavorites.current && !refFavorites.current.contains(event.target) &&
        (event.target.className.indexOf('ignore-favorite-hide') === -1 && event.target.parentElement.className.indexOf('ignore-favorite-hide') === -1)) {
        setFavoritesVisible(false)
      }

      if (refOffer.current && !refOffer.current.contains(event.target) &&
      (event.target.className.indexOf('ignore-offer-hide') === -1 && event.target.parentElement.className.indexOf('ignore-offer-hide') === -1)) {
        setOfferVisible(false)
      }
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [refFavorites, dispatch])

  const handleShowLoginModal = () => {
    dispatch.auth.setVisibleModal(ModalType.LoginRegister)
  }

  const handleLogout = () => {
    dispatch.auth.userLogout()
    toast.success(t('login:logout-successful'))
  }

  const handleNavigateToRequestPage = () => {
    setOfferVisible(false)
    navigate('/request')
  }

  const handleNavigateToListingPage = () => {
    navigate('/listing')
  }

  const navigateToDetail = (venueSlug) => {
    setFavoritesVisible(false)
    navigate('/' + venueSlug)
  }

  return (
    <>    <header className="main-header">

      {/* header-top */}
      <div className="header-top fl-wrap">
        <div className="container">
          { upperBanners == null ? <div></div> : upperBanners.length === 0 ? <div style={{ height: 120 }}><BannerLoader /></div>
            : <a

              onClick={() => {
                dispatch.venue.clickBanner({ bannerId: upperBanners.bannerId })
                typeof upperBanners.venue === 'undefined' ? window.open(upperBanners.url) : window.open('/' + upperBanners.venue.venueSlug)
              }}

            >
              <div style={{
                height: 120,
                backgroundImage: `url(${upperBanners.image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}>
              </div>
            </a>
          }
          <div className="row">
            <div className="logo-holder" style={{ width: 1 }}>
              <a href="/"><img src={LogoImage} alt="" /></a>
            </div>

            <div className="col">
              {loggedInUser == null
                ? <div className="show-reg-form modal-open" onClick={handleShowLoginModal}>
                  <i className="fa fa-user"></i>
                </div>
                : <div className="show-reg-form modal-open" onClick={handleLogout}>
                  <i className="fa fa-sign-in"></i>
                </div>

              }
              {/* wishlist-wrap end */}

              <div className="lang-wrap">
                <div className="show-lang">
                  {lang === 'hu' ? (
                    <>
                      <img src={LanHu} alt=""/><span>Hun</span><i className="fa fa-caret-down"></i>
                    </>
                  ) : (
                    <>
                      <img src={LanEn} alt=""/><span>Eng</span><i className="fa fa-caret-down"></i>
                    </>
                  )}

                </div>
                <ul className="lang-tooltip green-bg">
                  {lang === 'hu' ? (
                    <li><a href="#" onClick={() => setLanguage('en')}><img src={LanEn} alt=""/> Eng</a></li>
                  ) : (
                    <li><a href="#" onClick={() => setLanguage('hu')}><img src={LanHu} alt=""/> Hun</a></li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* header-top end */}
      {/* header-inner */}
      <div className="header-inner fl-wrap">
        <div className="container">
          <div className="show-search-button" onClick={handleNavigateToListingPage}><span>{t('common:search')}</span> <i className="fas fa-search"></i> </div>
          <div className="ignore-favorite-hide wishlist-link" onClick={() => setFavoritesVisible(!isFavoritesVisible)}>
            <i className={(favorites.length ? 'fas' : 'fal') + ' ignore-favorite-hide  fa-heart'}></i><span className="wl_counter">{favorites.length}</span>
          </div>
          <div href="" className="ignore-offer-hide  add-hotel" onClick={() => setOfferVisible(!isOfferVisible)}>
            {t('common:request-page')}  <span className="wl_counter" style={{ right: 10 }}>{callForOffer.length}</span>
          </div>
          {/* wishlist-wrap */}
          {
            <div ref={refOffer} className={classNames('wishlist-wrap scrollbar-inner', { 'wishlist-visible': isOfferVisible, 'wishlist-hidden': !isOfferVisible }) } style={{ top: 50 }}>
              <div className="box-widget-content">
                <div className="widget-posts">
                  <div className="popup-title">
                    <h3>{t('common:accessibilities')}</h3>
                  </div>
                  {callForOffer.length === 0 && <div>
                    <p>
                      {t('common:no-accessibilities-selected')}
                    </p>
                  </div>}
                  <ul>
                    {callForOffer.map(offer =>
                      <li key={offer.key} className="clearfix">
                        <a href="#" className="widget-posts-img"><img src={offer.image} className="respimg" alt=""/></a>
                        <div className="widget-posts-descr">
                          <a className="cursor-pointer" onClick={() => navigateToDetail(offer.venueSlug)} title="">{offer.name} </a>
                          <div className="d-flex ">
                            <div className="geodir-category-location fl-wrap cursor-pointer">
                              <a onClick={() => navigateToDetail(offer.venueSlug)}><i className="fas fa-map-marker-alt"></i>{offer.city} </a>
                            </div>
                            <div>
                              <a onClick={() => dispatch.venue.removeStateCallForOffer(offer)}><i className="ignore-offer-hide fas fa-trash header-trash"></i></a>
                            </div>
                          </div>

                        </div>
                      </li>
                    )}
                  </ul>
                  {callForOffer.length !== 0 &&
                  <div style={{ paddingBottom: 47 }}>
                    <button className="header-search-button" onClick={handleNavigateToRequestPage}>{t('common:request-page')}</button>
                  </div>
                  }
                </div>
              </div>
            </div>
          }

          <div className="home-btn"><a href="/"><i className="fas fa-home"></i></a></div>
          {/* nav-button-wrap */}
          <div className="nav-button-wrap color-bg" onClick={() => setMenuVisible(!isMenuVisible)}>
            <div className="nav-button">
              <span></span><span></span><span></span>
            </div>
          </div>
          {/* nav-button-wrap end */}
          {/*  navigation */}
          <div className={classNames('nav-holder main-menu', { 'is-mobile-visible': isMenuVisible })}>
            <nav>
              <ul>
                <li>
                  <a href="/listing">{t('common:menu.event-venues')} {/* <i className="fas fa-caret-down"></i> */}</a>
                </li>
                <li>
                  <a href="/specail-offer-listing">{t('common:menu.offers')} {/* <i className="fas fa-caret-down"></i> */}</a>
                  {/* second level */}
                  {/*  <ul>
                    <li><a href="about.html">About</a></li>
                    <li><a href="contacts.html">Contacts</a></li>
                    <li><a href="author-single.html">User single</a></li>
                    <li><a href="help.html">Help FAQ</a></li>
                    <li><a href="pricing-tables.html">Pricing</a></li>
                    <li><a href="booking-single.html">Booking</a></li>
                    <li><a href="dashboard.html">User Dashboard</a></li>
                    <li><a href="blog2.html">Blog Classik</a></li>
                    <li><a href="blog-single.html">Blog Single</a></li>
                    <li><a href="dashboard-add-listing.html">Add Listing</a></li>
                    <li><a href="404.html">404</a></li>
                    <li><a href="invoice.html">Invoice</a></li>
                    <li><a href="coming-soon.html">Coming Soon</a></li>
                  </ul> */}
                  {/* second level end */}
                </li>
                <li>
                  <a href="/advertisement">{t('common:menu.advertisement')} {/* <i className="fas fa-caret-down"></i> */}</a>
                </li>
                <li>
                  <a href="/subscriptionfee">{t('common:menu.subscriptionfee')} {/* <i className="fas fa-caret-down"></i> */}</a>
                </li>
              </ul>
            </nav>
          </div>
          {/* navigation  end */}
          {/* wishlist-wrap */}
          {
            <div ref={refFavorites} className={classNames('wishlist-wrap scrollbar-inner', { 'wishlist-visible': isFavoritesVisible, 'wishlist-hidden': !isFavoritesVisible })}>
              <div className="box-widget-content">
                <div className="widget-posts">
                  <div className="popup-title">
                    <h3>{t('common:favorites')}</h3>
                  </div>
                  {favorites.length === 0 && <div>
                    <p>
                      {t('common:no-favorites-selected')}
                    </p>
                  </div>}
                  <ul>
                    {favorites.map(favorite =>
                      <li key={favorite.key} className="clearfix">
                        <a href="#" className="widget-posts-img"><img src={favorite.image} className="respimg" alt=""/></a>
                        <div className="widget-posts-descr">
                          <a className="cursor-pointer" onClick={() => navigateToDetail(favorite.venueSlug)} title="">{favorite.name} </a>
                          <div className="d-flex ">
                            <div className="geodir-category-location fl-wrap cursor-pointer">
                              <a onClick={() => navigateToDetail(favorite.venueSlug)}><i className="fas fa-map-marker-alt"></i>{favorite.city} </a>
                            </div>
                            <div>
                              <a onClick={() => dispatch.venue.removeStateFavorite(favorite)}><i className="ignore-favorite-hide fas fa-trash header-trash"></i></a>
                            </div>
                          </div>

                        </div>
                      </li>
                    )}
                  </ul>

                  {/*
                <div className="header-offer">
                  <button onClick={() => dispatch.venue.clearStatefavorites()}><span>{t('common:clear-offers')}</span></button>
                </div>
                   */}

                </div>
              </div>
            </div>
          }
          {/* wishlist-wrap end */}
        </div>
      </div>
      {/* header-inner end */}
      {/* header-search */}
      <div className="header-search vis-search">
        <div className="container">
          <div className="row">
            {/* header-search-input-item */}
            <div className="col-sm-4">
              <div className="header-search-input-item fl-wrap location autocomplete-container">
                <label>Destination or Hotel Name</label>
                <span className="header-search-input-item-icon"><i className="fal fa-map-marker-alt"></i></span>
                <input type="text" placeholder="Location" className="autocomplete-input" id="autocompleteid" />
                <a href="#"><i className="fal fa-dot-circle"></i></a>
              </div>
            </div>
            {/* header-search-input-item end */}
            {/* header-search-input-item */}
            <div className="col-sm-3">
              <div className="header-search-input-item fl-wrap date-parent">
                <label>Date In-Out </label>
                <span className="header-search-input-item-icon"><i className="fal fa-calendar-check"></i></span>
                <input type="text" placeholder="When" name="header-search" />
              </div>
            </div>
            {/* header-search-input-item end */}
            {/* header-search-input-item */}
            <div className="col-sm-3">
              <div className="header-search-input-item fl-wrap">
                <div className="quantity-item">
                  <label>Rooms</label>
                  <div className="quantity">
                    <input type="number" min="1" max="3" step="1" />
                  </div>
                </div>
                <div className="quantity-item">
                  <label>Adults</label>
                  <div className="quantity">
                    <input type="number" min="1" max="3" step="1" />
                  </div>
                </div>
                <div className="quantity-item">
                  <label>Children</label>
                  <div className="quantity">
                    <input type="number" min="0" max="3" step="1" />
                  </div>
                </div>
              </div>
            </div>
            {/* header-search-input-item end */}
            {/* header-search-input-item */}
            <div className="col-sm-2">
              <div className="header-search-input-item fl-wrap">
                <button className="header-search-button">Search <i className="far fa-search"></i></button>
              </div>
            </div>
            {/* header-search-input-item end */}
          </div>
        </div>
        <div className="close-header-search"><i className="fal fa-angle-double-up"></i></div>
      </div>
      {/* header-search  end */}
    </header>
    {upperBanners != null && <div style={{ paddingTop: 120 }}>

    </div>}
    </>

  )
}

export default Header
