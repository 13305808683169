import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
const useFilterType = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { cityTypes, regionTypes } = useSelector(state => state.filter)
  const { specialPlaces } = useSelector(state => state.venue)

  const [specialPlacesSelect, setspecialPlacesSelect] = useState([])
  useEffect(() => {
    dispatch.venue.getSpecialPlaces()
    dispatch.filter.getCityTypes()
    dispatch.filter.getRegionTypes()
  }, [dispatch])

  useEffect(() => {
    setspecialPlacesSelect(specialPlaces.map(place => (
      { value: place.slug, label: place.name }
    )))
  }, [specialPlaces])

  const eventTypes = [
    {
      label: t('common:filter.eventGroup.private'),
      options: [
        { value: 'wedding', label: t('common:filter.eventType.wedding') },
        { value: 'birthday', label: t('common:filter.eventType.birthday') },
        { value: 'childish', label: t('common:filter.eventType.childish') },
        { value: 'bachelor-party', label: t('common:filter.eventType.bachelor-party') },
        { value: 'wedding-party', label: t('common:filter.eventType.wedding-party') },
        { value: 'other', label: t('common:filter.eventType.other') }
      ]
    }, {
      label: t('common:filter.eventGroup.representation'),
      options: [
        { value: 'classic', label: t('common:filter.eventType.classic') },
        { value: 'party', label: t('common:filter.eventType.party') },
        { value: 'bal', label: t('common:filter.eventType.bal') },
        { value: 'dramatic', label: t('common:filter.eventType.dramatic-performance') },
        { value: 'team-building', label: t('common:filter.eventType.team-building') },
        { value: 'concert', label: t('common:filter.eventType.concert') },
        { value: 'banquet', label: t('common:filter.eventType.banquet') }
      ]
    }, {
      label: t('common:filter.eventGroup.conference'),
      options: [
        { value: 'business', label: t('common:filter.eventType.business') },
        { value: 'congress', label: t('common:filter.eventType.congress') },
        { value: 'meeting', label: t('common:filter.eventType.meeting') },
        { value: 'news-conference', label: t('common:filter.eventType.news-conference') },
        { value: 'training', label: t('common:filter.eventType.training') }
      ]
    }, {
      label: t('common:filter.eventGroup.consumer-event'),
      options: [
        { value: 'gala', label: t('common:filter.eventType.gala') },
        { value: 'family-day', label: t('common:filter.eventType.family-day') },
        { value: 'festival', label: t('common:filter.eventType.festival') },
        { value: 'consumer-concert', label: t('common:filter.eventType.consumer-concert') },
        { value: 'sport', label: t('common:filter.eventType.sport') },
        { value: 'road-show', label: t('common:filter.eventType.road-show') }
      ]
    }, {
      label: t('common:filter.eventGroup.premier'),
      options: [
        { value: 'product', label: t('common:filter.eventType.product') },
        { value: 'exhibition', label: t('common:filter.eventType.exhibition-fair') },
        { value: 'promotion', label: t('common:filter.eventType.promotion') }
      ]
    }
  ]

  const placeTypes = [
    { value: 'hotel', label: t('common:filter.placeType.hotel') },
    { value: 'castle', label: t('common:filter.placeType.castle') },
    { value: 'outdoor', label: t('common:filter.placeType.outdoor') },
    { value: 'aquapark', label: t('common:filter.placeType.aquapark') },
    { value: 'musical-entertainment', label: t('common:filter.placeType.musical-entertainment') },
    { value: 'event-boat', label: t('common:filter.placeType.event-boat') },
    { value: 'theater-cinema', label: t('common:filter.placeType.theater-cinema') },
    { value: 'conference-central', label: t('common:filter.placeType.conference-central') },
    { value: 'conference-room', label: t('common:filter.placeType.conference-room') },
    { value: 'education', label: t('common:filter.placeType.education') },
    { value: 'museum', label: t('common:filter.placeType.museum') },
    { value: 'restaurant', label: t('common:filter.placeType.restaurant') },
    { value: 'sport-freetime', label: t('common:filter.placeType.sport-freetime') },
    { value: 'cultural-central', label: t('common:filter.placeType.cultural-central') },
    { value: 'other', label: t('common:filter.placeType.other') },
    { value: 'event-house', label: t('common:filter.placeType.event-house') }
  ]

  const locationTypes = [
    { value: 'waterfront', label: t('common:filter.locationType.waterfront') },
    { value: 'forest', label: t('common:filter.locationType.forest') },
    { value: 'near-water', label: t('common:filter.locationType.near-water') },
    { value: 'central', label: t('common:filter.locationType.central') }
  ]

  const cateringTypes = [
    { value: 'local', label: t('common:filter.cateringType.own-kitchen') },
    { value: 'external', label: t('common:filter.cateringType.external-kitchen') }
  ]

  const conditionTypes = [
    { value: 'yes', label: t('common:filter.conditionTypes.yes') },
    { value: 'no', label: t('common:filter.conditionTypes.no') }
  ]

  const allCityTypes = [{
    label: 'Budapest',
    options: [
      { value: '336', label: 'Budapest 01. ker.' },
      { value: '337', label: 'Budapest 02. ker.' },
      { value: '338', label: 'Budapest 03. ker.' },
      { value: '339', label: 'Budapest 04. ker.' },
      { value: '340', label: 'Budapest 05. ker.' },
      { value: '341', label: 'Budapest 06. ker.' },
      { value: '342', label: 'Budapest 07. ker.' },
      { value: '343', label: 'Budapest 08. ker.' },
      { value: '344', label: 'Budapest 09. ker.' },
      { value: '345', label: 'Budapest 10. ker.' },
      { value: '346', label: 'Budapest 11. ker.' },
      { value: '347', label: 'Budapest 12. ker.' },
      { value: '348', label: 'Budapest 13. ker.' },
      { value: '349', label: 'Budapest 14. ker.' },
      { value: '350', label: 'Budapest 15. ker.' },
      { value: '351', label: 'Budapest 16. ker.' },
      { value: '352', label: 'Budapest 17. ker.' },
      { value: '353', label: 'Budapest 18. ker.' },
      { value: '354', label: 'Budapest 19. ker.' },
      { value: '355', label: 'Budapest 20. ker.' },
      { value: '356', label: 'Budapest 21. ker.' },
      { value: '357', label: 'Budapest 22. ker.' },
      { value: '358', label: 'Budapest 23. ker.' }
    ]
  },
  {
    label: 'Baranya',
    options: [
      { value: '3', label: 'Abaliget' },
      { value: '35', label: 'Almamellék' },
      { value: '38', label: 'Almáskeresztúr' },
      { value: '42', label: 'Alsómocsolád' },
      { value: '53', label: 'Alsószentmárton' },
      { value: '69', label: 'Apátvarasd' },
      { value: '90', label: 'Babarc' },
      { value: '111', label: 'Bakóca' },
      { value: '113', label: 'Bakonya' },
      { value: '182', label: 'Bár' },
      { value: '187', label: 'Baranyahidvég' },
      { value: '188', label: 'Baranyajenő' },
      { value: '195', label: 'Basal' },
      { value: '229', label: 'Belvárdgyula' },
      { value: '239', label: 'Beremend' },
      { value: '252', label: 'Bezedek' },
      { value: '264', label: 'Bikal' },
      { value: '273', label: 'Boda' },
      { value: '276', label: 'Bodolyabér' },
      { value: '287', label: 'Bogád' },
      { value: '288', label: 'Bogdása' },
      { value: '294', label: 'Boldogasszonyfa' },
      { value: '298', label: 'Bóly' },
      { value: '303', label: 'Borjád' },
      { value: '367', label: 'Bükkösd' },
      { value: '400', label: 'Csányoszró' },
      { value: '405', label: 'Csarnóta' },
      { value: '436', label: 'Cserkút' },
      { value: '439', label: 'Csertő' },
      { value: '509', label: 'Dinnyeberki' },
      { value: '542', label: 'Drávacsehi' },
      { value: '543', label: 'Drávacsepely' },
      { value: '544', label: 'Drávafok' },
      { value: '561', label: 'Dunaszekcső' },
      { value: '597', label: 'Egyházasharaszti' },
      { value: '601', label: 'Egyházaskozár' },
      { value: '604', label: 'Ellend' },
      { value: '623', label: 'Erdősmecske' },
      { value: '649', label: 'Fazekasboda' },
      { value: '655', label: 'Feked' },
      { value: '675', label: 'Felsőszentmárton' },
      { value: '739', label: 'Garé' },
      { value: '752', label: 'Geresdlak' },
      { value: '765', label: 'Gordisa' },
      { value: '770', label: 'Gödre' },
      { value: '776', label: 'Görcsönydoboka' },
      { value: '797', label: 'Gyöngyösmellék' },
      { value: '854', label: 'Harkány' },
      { value: '867', label: 'Hegyszentmárton' },
      { value: '875', label: 'Helesfa' },
      { value: '906', label: 'Hidas' },
      { value: '912', label: 'Hobol' },
      { value: '920', label: 'Homorúd' },
      { value: '926', label: 'Hosszúhetény' },
      { value: '936', label: 'Ibafa' },
      { value: '949', label: 'Illocska' },
      { value: '955', label: 'Ipacsfa' },
      { value: '972', label: 'Ivándárda' },
      { value: '1018', label: 'Kákics' },
      { value: '1069', label: 'Katádfa' },
      { value: '1123', label: 'Keszü' },
      { value: '1128', label: 'Kétújfalu' },
      { value: '1135', label: 'Királyegyháza' },
      { value: '1144', label: 'Kisbeszterce' },
      { value: '1154', label: 'Kisharsány' },
      { value: '1176', label: 'Kisnyárád' },
      { value: '1184', label: 'Kistamási' },
      { value: '1185', label: 'Kistapolca' },
      { value: '1213', label: 'Komló' },
      { value: '1231', label: 'Kozármisleny' },
      { value: '1237', label: 'Kölked' },
      { value: '1306', label: 'Lengyel' },
      { value: '1318', label: 'Liget' },
      { value: '1341', label: 'Mágocs' },
      { value: '1348', label: 'Magyaregregy' },
      { value: '1354', label: 'Magyarmecske' },
      { value: '1359', label: 'Magyarszék' },
      { value: '1362', label: 'Majs' },
      { value: '1371', label: 'Mánfa' },
      { value: '1374', label: 'Márfa' },
      { value: '1377', label: 'Máriakéménd' },
      { value: '1385', label: 'Marócsa' },
      { value: '1386', label: 'Márok' },
      { value: '1406', label: 'Matty' },
      { value: '1408', label: 'Máza' },
      { value: '1409', label: 'Mecsekpölöske' },
      { value: '1420', label: 'Mekényes' },
      { value: '1481', label: 'Mohács' },
      { value: '1530', label: 'Nagydobsza' },
      { value: '1539', label: 'Nagyhajmás' },
      { value: '1541', label: 'Nagyharsány' },
      { value: '1570', label: 'Nagynyárád' },
      { value: '1573', label: 'Nagypall' },
      { value: '1591', label: 'Nagytótfalu' },
      { value: '1595', label: 'Nagyváty' },
      { value: '1696', label: 'Ócsárd' },
      { value: '1697', label: 'Ófalu' },
      { value: '1702', label: 'Okorág' },
      { value: '1703', label: 'Olasz' },
      { value: '1716', label: 'Orfő' },
      { value: '1731', label: 'Ózdfalu' },
      { value: '1766', label: 'Palkonya' },
      { value: '1769', label: 'Palotabozsok' },
      { value: '1804', label: 'Pécs' },
      { value: '1806', label: 'Pécsudvard' },
      { value: '1807', label: 'Pellérd' },
      { value: '1853', label: 'Pogány' },
      { value: '2021', label: 'Sátorhely' },
      { value: '2059', label: 'Somogyhárságy' },
      { value: '2060', label: 'Somogyhatvan' },
      { value: '2105', label: 'Szágy' },
      { value: '2106', label: 'Szajk' },
      { value: '2119', label: 'Szalatnak' },
      { value: '2142', label: 'Szászvár' },
      { value: '2146', label: 'Szava' },
      { value: '2148', label: 'Szebény' },
      { value: '2152', label: 'Szederkény' },
      { value: '2161', label: 'Székelyszabar' },
      { value: '2168', label: 'Szellő' },
      { value: '2169', label: 'Szemely' },
      { value: '2177', label: 'Szentdénes' },
      { value: '2179', label: 'Szentegát' },
      { value: '2189', label: 'Szentkatalin' },
      { value: '2192', label: 'Szentlászló' },
      { value: '2193', label: 'Szentlőrinc' },
      { value: '2214', label: 'Szigetvár' },
      { value: '2219', label: 'Szilágy' },
      { value: '2242', label: 'Szőke' },
      { value: '2244', label: 'Szőr' },
      { value: '2311', label: 'Tékes' },
      { value: '2312', label: 'Teklafalu' },
      { value: '2328', label: 'Tésenfa' },
      { value: '2329', label: 'Téseny' },
      { value: '2400', label: 'Tófő' },
      { value: '2433', label: 'Töttös' },
      { value: '2442', label: 'Udvar' },
      { value: '2455', label: 'Újpetre' },
      { value: '2491', label: 'Vajszló' },
      { value: '2515', label: 'Varga' },
      { value: '2524', label: 'Vásárosbéc' },
      { value: '2525', label: 'Vásárosdombó' },
      { value: '2546', label: 'Vékény' },
      { value: '2550', label: 'Velény' },
      { value: '2551', label: 'Véménd' },
      { value: '2558', label: 'Versend' },
      { value: '2572', label: 'Villány' },
      { value: '2573', label: 'Villánykövesd' },
      { value: '2593', label: 'Vokány' },
      { value: '2599', label: 'Zádor' },
      { value: '2624', label: 'Zaláta' },
      { value: '2642', label: 'Zók' }

    ]
  },
  {
    label: 'Bács-Kiskun',
    options: [
      { value: '21', label: 'Ágasegyháza' },
      { value: '28', label: 'Akasztó' },
      { value: '72', label: 'Apostag' },
      { value: '94', label: 'Bácsalmás' },
      { value: '95', label: 'Bácsbokod' },
      { value: '96', label: 'Bácsborsód' },
      { value: '97', label: 'Bácsszentgyörgy' },
      { value: '98', label: 'Bácsszőlős' },
      { value: '105', label: 'Baja' },
      { value: '170', label: 'Ballószög' },
      { value: '173', label: 'Balotaszállás' },
      { value: '200', label: 'Bátmonostor' },
      { value: '205', label: 'Bátya' },
      { value: '270', label: 'Bócsa' },
      { value: '304', label: 'Borota' },
      { value: '359', label: 'Bugacpusztaháza' },
      { value: '408', label: 'Császártöltés' },
      { value: '410', label: 'Csátalja' },
      { value: '413', label: 'Csávoly' },
      { value: '426', label: 'Csengőd' },
      { value: '445', label: 'Csikéria' },
      { value: '458', label: 'Csólyospálos' },
      { value: '492', label: 'Dávod' },
      { value: '541', label: 'Drágszél' },
      { value: '552', label: 'Dunaegyháza' },
      { value: '553', label: 'Dunafalva' },
      { value: '558', label: 'Dunapataj' },
      { value: '562', label: 'Dunaszentbenedek' },
      { value: '567', label: 'Dunatetétlen' },
      { value: '570', label: 'Dunavecse' },
      { value: '571', label: 'Dusnok' },
      { value: '628', label: 'Érsekcsanád' },
      { value: '629', label: 'Érsekhalma' },
      { value: '644', label: 'Fajsz' },
      { value: '665', label: 'Felsőlajos' },
      { value: '674', label: 'Felsőszentiván' },
      { value: '694', label: 'Foktő' },
      { value: '707', label: 'Fülöpháza' },
      { value: '708', label: 'Fülöpjakab' },
      { value: '709', label: 'Fülöpszállás' },
      { value: '734', label: 'Gara' },
      { value: '741', label: 'Gátér' },
      { value: '744', label: 'Géderlak' },
      { value: '841', label: 'Hajós' },
      { value: '853', label: 'Harkakötöny' },
      { value: '858', label: 'Harta' },
      { value: '876', label: 'Helvécia' },
      { value: '881', label: 'Hercegszántó' },
      { value: '918', label: 'Homokmégy' },
      { value: '951', label: 'Imrehegy' },
      { value: '974', label: 'Izsák' },
      { value: '977', label: 'Jakabszállás' },
      { value: '982', label: 'Jánoshalma' },
      { value: '1004', label: 'Jászszentlászló' },
      { value: '1028', label: 'Kalocsa' },
      { value: '1029', label: 'Kalocsa' },
      { value: '1065', label: 'Kaskantyú' },
      { value: '1070', label: 'Katymár' },
      { value: '1077', label: 'Kecel' },
      { value: '1079', label: 'Kecskemét' },
      { value: '1085', label: 'Kelebia' },
      { value: '1088', label: 'Kéleshalom' },
      { value: '1109', label: 'Kerekegyháza' },
      { value: '1162', label: 'Kiskőrös' },
      { value: '1163', label: 'Kiskunfélegyháza' },
      { value: '1164', label: 'Kiskunhalas' },
      { value: '1166', label: 'Kiskunmajsa' },
      { value: '1197', label: 'Kisszállás' },
      { value: '1241', label: 'Kömpöc' },
      { value: '1262', label: 'Kunadacs' },
      { value: '1264', label: 'Kunbaja' },
      { value: '1265', label: 'Kunbaracs' },
      { value: '1267', label: 'Kunfehértó' },
      { value: '1270', label: 'Kunpeszér' },
      { value: '1271', label: 'Kunszállás' },
      { value: '1273', label: 'Kunszentmiklós' },
      { value: '1288', label: 'Ladánybene' },
      { value: '1291', label: 'Lajosmizse' },
      { value: '1292', label: 'Lakitelek' },
      { value: '1338', label: 'Madaras' },
      { value: '1395', label: 'Mátételke' },
      { value: '1421', label: 'Mélykút' },
      { value: '1473', label: 'Miske' },
      { value: '1492', label: 'Móricgát' },
      { value: '1519', label: 'Nagybaracska' },
      { value: '1624', label: 'Nemesnádudvar' },
      { value: '1653', label: 'Nyárlőrinc' },
      { value: '1715', label: 'Ordas' },
      { value: '1717', label: 'Orgovány' },
      { value: '1742', label: 'Öregcsertő' },
      { value: '1759', label: 'Páhi' },
      { value: '1768', label: 'Pálmonostora' },
      { value: '1828', label: 'Petőfiszállás' },
      { value: '1848', label: 'Pirtó' },
      { value: '1932', label: 'Rém' },
      { value: '2042', label: 'Solt' },
      { value: '2043', label: 'Soltszentimre' },
      { value: '2044', label: 'Soltvadkert' },
      { value: '2089', label: 'Sükösd' },
      { value: '2099', label: 'Szabadszállás' },
      { value: '2113', label: 'Szakmár' },
      { value: '2121', label: 'Szalkszentmárton' },
      { value: '2130', label: 'Szank' },
      { value: '2190', label: 'Szentkirály' },
      { value: '2203', label: 'Szeremle' },
      { value: '2254', label: 'Tabdi' },
      { value: '2302', label: 'Tass' },
      { value: '2307', label: 'Tataháza' },
      { value: '2309', label: 'Tázlár' },
      { value: '2340', label: 'Tiszaalpár' },
      { value: '2364', label: 'Tiszakécske' },
      { value: '2411', label: 'Tompa' },
      { value: '2457', label: 'Újsolt' },
      { value: '2463', label: 'Újtelek' },
      { value: '2477', label: 'Uszód' },
      { value: '2518', label: 'Városföld' },
      { value: '2533', label: 'Vaskút' },
      { value: '2649', label: 'Zsana' }

    ]
  },
  {
    label: 'Békés',
    options: [
      { value: '37', label: 'Almáskamarás' },
      { value: '204', label: 'Battonya' },
      { value: '216', label: 'Békés' },
      { value: '217', label: 'Békéscsaba' },
      { value: '218', label: 'Békéssámson' },
      { value: '219', label: 'Békésszentandrás' },
      { value: '227', label: 'Bélmegyer' },
      { value: '262', label: 'Biharugra' },
      { value: '328', label: 'Bucsa' },
      { value: '385', label: 'Csabacsőd' },
      { value: '386', label: 'Csabaszabadi' },
      { value: '396', label: 'Csanádapáca' },
      { value: '404', label: 'Csárdaszállás' },
      { value: '463', label: 'Csorvás' },
      { value: '507', label: 'Dévaványa' },
      { value: '516', label: 'Doboz' },
      { value: '520', label: 'Dombegyház' },
      { value: '521', label: 'Dombiratos' },
      { value: '576', label: 'Ecsegfalva' },
      { value: '603', label: 'Elek' },
      { value: '715', label: 'Füzesgyarmat' },
      { value: '721', label: 'Gádoros' },
      { value: '751', label: 'Gerendás' },
      { value: '755', label: 'Geszt' },
      { value: '790', label: 'Gyomaendrőd' },
      { value: '819', label: 'Gyula' },
      { value: '820', label: 'Gyula' },
      { value: '934', label: 'Hunya' },
      { value: '1033', label: 'Kamut' },
      { value: '1060', label: 'Kardos' },
      { value: '1061', label: 'Kardoskút' },
      { value: '1067', label: 'Kaszaper' },
      { value: '1117', label: 'Kertészsziget' },
      { value: '1124', label: 'Kétegyháza' },
      { value: '1127', label: 'Kétsoprony' },
      { value: '1130', label: 'Kevermes' },
      { value: '1147', label: 'Kisdombegyház' },
      { value: '1219', label: 'Kondoros' },
      { value: '1246', label: 'Körösladány' },
      { value: '1247', label: 'Körösnagyharsány' },
      { value: '1248', label: 'Köröstarcsa' },
      { value: '1250', label: 'Körösújfalu' },
      { value: '1257', label: 'Kötegyán' },
      { value: '1263', label: 'Kunágota' },
      { value: '1329', label: 'Lőkösháza' },
      { value: '1345', label: 'Magyarbánhegyes' },
      { value: '1347', label: 'Magyardombegyház' },
      { value: '1411', label: 'Medgyesbodzás' },
      { value: '1412', label: 'Medgyesegyháza' },
      { value: '1418', label: 'Méhkerék' },
      { value: '1434', label: 'Mezőberény' },
      { value: '1438', label: 'Mezőgyán' },
      { value: '1439', label: 'Mezőhegyes' },
      { value: '1443', label: 'Mezőkovácsháza' },
      { value: '1506', label: 'Murony' },
      { value: '1518', label: 'Nagybánhegyes' },
      { value: '1546', label: 'Nagykamarás' },
      { value: '1584', label: 'Nagyszénás' },
      { value: '1701', label: 'Okány' },
      { value: '1719', label: 'Orosháza' },
      { value: '1748', label: 'Örménykút' },
      { value: '1876', label: 'Pusztaföldvár' },
      { value: '1882', label: 'Pusztaottlaka' },
      { value: '2001', label: 'Sarkad' },
      { value: '2002', label: 'Sarkadkeresztúr' },
      { value: '2098', label: 'Szabadkígyós' },
      { value: '2138', label: 'Szarvas' },
      { value: '2157', label: 'Szeghalom' },
      { value: '2287', label: 'Tarhos' },
      { value: '2314', label: 'Telekgerendás' },
      { value: '2421', label: 'Tótkomlós' },
      { value: '2451', label: 'Újkígyós' },
      { value: '2458', label: 'Újszalonta' },
      { value: '2545', label: 'Végegyháza' },
      { value: '2570', label: 'Vésztő' },
      { value: '2645', label: 'Zsadány' }

    ]
  },
  {
    label: 'Borsod-Abaúj-Zemplén',
    options: [
      { value: '5', label: 'Abaújalpár' },
      { value: '6', label: 'Abaújszolnok' },
      { value: '8', label: 'Abod' },
      { value: '23', label: 'Aggtelek' },
      { value: '29', label: 'Alacska' },
      { value: '40', label: 'Alsóberecki' },
      { value: '41', label: 'Alsódobsza' },
      { value: '51', label: 'Alsóregmec' },
      { value: '56', label: 'Alsóvadász' },
      { value: '57', label: 'Alsózsolca' },
      { value: '74', label: 'Arka' },
      { value: '75', label: 'Arló' },
      { value: '76', label: 'Arnót' },
      { value: '77', label: 'Ároktő' },
      { value: '83', label: 'Aszaló' },
      { value: '133', label: 'Baktakék' },
      { value: '178', label: 'Bánhorváti' },
      { value: '181', label: 'Bánréve' },
      { value: '215', label: 'Bekecs' },
      { value: '245', label: 'Berzék' },
      { value: '280', label: 'Bodroghalom' },
      { value: '281', label: 'Bodrogkeresztúr' },
      { value: '282', label: 'Bodrogkisfalud' },
      { value: '283', label: 'Bodrogolaszi' },
      { value: '284', label: 'Bódvarákó' },
      { value: '285', label: 'Bódvaszilas' },
      { value: '286', label: 'Bogács' },
      { value: '295', label: 'Boldogkőújfalu' },
      { value: '306', label: 'Borsodbóta' },
      { value: '307', label: 'Borsodgeszt' },
      { value: '308', label: 'Borsodivánka' },
      { value: '309', label: 'Borsodnádasd' },
      { value: '310', label: 'Borsodszentgyörgy' },
      { value: '311', label: 'Borsodszirák' },
      { value: '317', label: 'Bőcs' },
      { value: '365', label: 'Bükkábrány' },
      { value: '366', label: 'Bükkaranyos' },
      { value: '370', label: 'Bükkszentkereszt' },
      { value: '371', label: 'Bükkzsérc' },
      { value: '382', label: 'Cigánd' },
      { value: '431', label: 'Cserépfalu' },
      { value: '432', label: 'Cserépváralja' },
      { value: '437', label: 'Csernely' },
      { value: '448', label: 'Csincse' },
      { value: '451', label: 'Csobád' },
      { value: '452', label: 'Csobaj' },
      { value: '456', label: 'Csokvaomány' },
      { value: '485', label: 'Dámóc' },
      { value: '495', label: 'Dédestapolcsány' },
      { value: '505', label: 'Detek' },
      { value: '540', label: 'Dövény' },
      { value: '547', label: 'Dubicsány' },
      { value: '580', label: 'Edelény' },
      { value: '587', label: 'Egerlövő' },
      { value: '590', label: 'Égerszög' },
      { value: '606', label: 'Emőd' },
      { value: '607', label: 'Encs' },
      { value: '618', label: 'Erdőbénye' },
      { value: '619', label: 'Erdőhorváti' },
      { value: '643', label: 'Fáj' },
      { value: '645', label: 'Fancsal' },
      { value: '662', label: 'Felsődobsza' },
      { value: '664', label: 'Felsőkelecsény' },
      { value: '678', label: 'Felsőtelekes' },
      { value: '680', label: 'Felsővadász' },
      { value: '681', label: 'Felsőzsolca' },
      { value: '698', label: 'Forró' },
      { value: '702', label: 'Fulókércs' },
      { value: '712', label: 'Füzér' },
      { value: '713', label: 'Füzérradvány' },
      { value: '720', label: 'Gadna' },
      { value: '722', label: 'Gagyapáti' },
      { value: '723', label: 'Gagybátor' },
      { value: '724', label: 'Gagyvendégi' },
      { value: '730', label: 'Galvács' },
      { value: '736', label: 'Garadna' },
      { value: '746', label: 'Gelej' },
      { value: '756', label: 'Gesztely' },
      { value: '759', label: 'Girincs' },
      { value: '761', label: 'Golop' },
      { value: '772', label: 'Gönc' },
      { value: '773', label: 'Göncruszka' },
      { value: '806', label: 'Györgytarló' },
      { value: '846', label: 'Halmaj' },
      { value: '849', label: 'Hangony' },
      { value: '855', label: 'Háromhuta' },
      { value: '856', label: 'Harsány' },
      { value: '866', label: 'Hegymeg' },
      { value: '869', label: 'Hejce' },
      { value: '870', label: 'Hejőbába' },
      { value: '871', label: 'Hejőkeresztúr' },
      { value: '872', label: 'Hejőkürt' },
      { value: '873', label: 'Hejőpapi' },
      { value: '874', label: 'Hejőszalonta' },
      { value: '880', label: 'Hercegkút' },
      { value: '889', label: 'Hernádbőd' },
      { value: '890', label: 'Hernádcéce' },
      { value: '891', label: 'Hernádkak' },
      { value: '892', label: 'Hernádkércs' },
      { value: '893', label: 'Hernádnémeti' },
      { value: '894', label: 'Hernádszentandrás' },
      { value: '895', label: 'Hernádszurdok' },
      { value: '896', label: 'Hernádvécse' },
      { value: '897', label: 'Hét' },
      { value: '907', label: 'Hidasnémeti' },
      { value: '910', label: 'Hidvégardó' },
      { value: '915', label: 'Hollóháza' },
      { value: '921', label: 'Homrogd' },
      { value: '940', label: 'Igrici' },
      { value: '950', label: 'Imola' },
      { value: '952', label: 'Ináncs' },
      { value: '975', label: 'Izsófalva' },
      { value: '986', label: 'Járdánháza' },
      { value: '1010', label: 'Jósvafő' },
      { value: '1013', label: 'Kács' },
      { value: '1059', label: 'Karcsa' },
      { value: '1063', label: 'Karos' },
      { value: '1074', label: 'Kazincbarcika' },
      { value: '1075', label: 'Kázsmárk' },
      { value: '1083', label: 'Kéked' },
      { value: '1087', label: 'Kelemér' },
      { value: '1102', label: 'Kenézlő' },
      { value: '1118', label: 'Kesznyéten' },
      { value: '1134', label: 'Királd' },
      { value: '1153', label: 'Kisgyőr' },
      { value: '1157', label: 'Kishuta' },
      { value: '1159', label: 'Kiskinizs' },
      { value: '1181', label: 'Kisrozvágy' },
      { value: '1182', label: 'Kissikátor' },
      { value: '1188', label: 'Kistokaj' },
      { value: '1212', label: 'Komjáti' },
      { value: '1214', label: 'Komlóska' },
      { value: '1217', label: 'Kondó' },
      { value: '1224', label: 'Korlát' },
      { value: '1229', label: 'Kovácsvágás' },
      { value: '1244', label: 'Köröm' },
      { value: '1276', label: 'Kupa' },
      { value: '1278', label: 'Kurityán' },
      { value: '1286', label: 'Lácacséke' },
      { value: '1289', label: 'Ládbesenyő' },
      { value: '1303', label: 'Legyesbénye' },
      { value: '1304', label: 'Léh' },
      { value: '1305', label: 'Lénárddaróc' },
      { value: '1337', label: 'Mád' },
      { value: '1364', label: 'Makkoshotyka' },
      { value: '1368', label: 'Mályi' },
      { value: '1369', label: 'Mályinka' },
      { value: '1393', label: 'Martonyi' },
      { value: '1414', label: 'Megyaszó' },
      { value: '1424', label: 'Méra' },
      { value: '1432', label: 'Meszes' },
      { value: '1435', label: 'Mezőcsát' },
      { value: '1441', label: 'Mezőkeresztes' },
      { value: '1444', label: 'Mezőkövesd' },
      { value: '1447', label: 'Mezőnagymihály' },
      { value: '1448', label: 'Mezőnyárád' },
      { value: '1457', label: 'Mezőzombor' },
      { value: '1474', label: 'Miskolc' },
      { value: '1479', label: 'Mogyoróska' },
      { value: '1484', label: 'Monok' },
      { value: '1501', label: 'Múcsony' },
      { value: '1502', label: 'Muhi' },
      { value: '1520', label: 'Nagybarca' },
      { value: '1525', label: 'Nagycsécs' },
      { value: '1553', label: 'Nagykinizs' },
      { value: '1606', label: 'Négyes' },
      { value: '1607', label: 'Nekézseny' },
      { value: '1609', label: 'Nemesbikk' },
      { value: '1648', label: 'Novajidrány' },
      { value: '1655', label: 'Nyékládháza' },
      { value: '1672', label: 'Nyíri' },
      { value: '1692', label: 'Nyomár' },
      { value: '1706', label: 'Olaszliszka' },
      { value: '1710', label: 'Onga' },
      { value: '1711', label: 'Ónod' },
      { value: '1718', label: 'Ormosbánya' },
      { value: '1728', label: 'Oszlár' },
      { value: '1730', label: 'Ózd' },
      { value: '1757', label: 'Pácin' },
      { value: '1776', label: 'Pányok' },
      { value: '1789', label: 'Parasznya' },
      { value: '1815', label: 'Perecse' },
      { value: '1820', label: 'Perkupa' },
      { value: '1870', label: 'Prügy' },
      { value: '1875', label: 'Pusztafalu' },
      { value: '1890', label: 'Putnok' },
      { value: '1912', label: 'Radostyán' },
      { value: '1914', label: 'Rakacaszend' },
      { value: '1925', label: 'Rásonysápberencs' },
      { value: '1926', label: 'Rátka' },
      { value: '1934', label: 'Répáshuta' },
      { value: '1944', label: 'Révleányvár' },
      { value: '1946', label: 'Ricse' },
      { value: '1962', label: 'Rudabánya' },
      { value: '1963', label: 'Rudolftelep' },
      { value: '1967', label: 'Sajóbábony' },
      { value: '1968', label: 'Sajóecseg' },
      { value: '1969', label: 'Sajógalgóc' },
      { value: '1970', label: 'Sajóhidvég' },
      { value: '1971', label: 'Sajókápolna' },
      { value: '1972', label: 'Sajókaza' },
      { value: '1973', label: 'Sajókeresztúr' },
      { value: '1974', label: 'Sajólád' },
      { value: '1975', label: 'Sajónémeti' },
      { value: '1976', label: 'Sajóörös' },
      { value: '1977', label: 'Sajópálfala' },
      { value: '1978', label: 'Sajópetri' },
      { value: '1979', label: 'Sajópüspöki' },
      { value: '1980', label: 'Sajószentpéter' },
      { value: '1981', label: 'Sajószöged' },
      { value: '1982', label: 'Sajóvámos' },
      { value: '1983', label: 'Sajóvelezd' },
      { value: '1989', label: 'Sály' },
      { value: '1996', label: 'Sárazsadány' },
      { value: '2008', label: 'Sárospatak' },
      { value: '2019', label: 'Sáta' },
      { value: '2020', label: 'Sátoraljaújhely' },
      { value: '2029', label: 'Serényfalva' },
      { value: '2031', label: 'Sima' },
      { value: '2082', label: 'Sóstófalva' },
      { value: '2110', label: 'Szakáld' },
      { value: '2118', label: 'Szalaszend' },
      { value: '2158', label: 'Szegilong' },
      { value: '2171', label: 'Szemere' },
      { value: '2173', label: 'Szendrőlád' },
      { value: '2188', label: 'Szentistván' },
      { value: '2204', label: 'Szerencs' },
      { value: '2217', label: 'Szikszó' },
      { value: '2224', label: 'Szinpetri' },
      { value: '2226', label: 'Szirmabesenyő' },
      { value: '2234', label: 'Szomolya' },
      { value: '2241', label: 'Szögliget' },
      { value: '2247', label: 'Szuhakálló' },
      { value: '2248', label: 'Szuhogy' },
      { value: '2262', label: 'Taktabáj' },
      { value: '2263', label: 'Taktaharkány' },
      { value: '2264', label: 'Taktakenéz' },
      { value: '2265', label: 'Taktaszada' },
      { value: '2267', label: 'Tállya' },
      { value: '2283', label: 'Tarcal' },
      { value: '2284', label: 'Tard' },
      { value: '2285', label: 'Tardona' },
      { value: '2317', label: 'Telkibánya' },
      { value: '2333', label: 'Tibolddaróc' },
      { value: '2341', label: 'Tiszabábolna' },
      { value: '2349', label: 'Tiszacsermely' },
      { value: '2353', label: 'Tiszadorogma' },
      { value: '2363', label: 'Tiszakarád' },
      { value: '2366', label: 'Tiszakeszi' },
      { value: '2369', label: 'Tiszaladány' },
      { value: '2371', label: 'Tiszalúc' },
      { value: '2376', label: 'Tiszapalkonya' },
      { value: '2387', label: 'Tiszatardos' },
      { value: '2388', label: 'Tiszatarján' },
      { value: '2392', label: 'Tiszaújváros' },
      { value: '2393', label: 'Tiszavalk' },
      { value: '2401', label: 'Tokaj' },
      { value: '2404', label: 'Tolcsva' },
      { value: '2410', label: 'Tomor' },
      { value: '2415', label: 'Tornanádaska' },
      { value: '2416', label: 'Tornaszentjakab' },
      { value: '2418', label: 'Tornyosnémeti' },
      { value: '2470', label: 'Uppony' },
      { value: '2490', label: 'Vajdácska' },
      { value: '2503', label: 'Vámosújfalu' },
      { value: '2512', label: 'Varbó' },
      { value: '2541', label: 'Vatta' },
      { value: '2574', label: 'Vilmány' },
      { value: '2576', label: 'Vilyvitány' },
      { value: '2585', label: 'Viss' },
      { value: '2587', label: 'Viszló' },
      { value: '2592', label: 'Vizsoly' },
      { value: '2600', label: 'Zádorfalva' },
      { value: '2629', label: 'Zalkod' },
      { value: '2636', label: 'Zemplénagárd' },
      { value: '2639', label: 'Ziliz' },
      { value: '2644', label: 'Zubogy' },
      { value: '2657', label: 'Zsujta' }

    ]
  },
  {
    label: 'Csongrád-Csanád',
    options: [
      { value: '58', label: 'Ambrózfalva' },
      { value: '67', label: 'Apátfalva' },
      { value: '78', label: 'Árpádhalom' },
      { value: '81', label: 'Ásotthalom' },
      { value: '132', label: 'Baks' },
      { value: '137', label: 'Balástya' },
      { value: '302', label: 'Bordány' },
      { value: '395', label: 'Csanádalberti' },
      { value: '397', label: 'Csanádpalota' },
      { value: '401', label: 'Csanytelek' },
      { value: '422', label: 'Csengele' },
      { value: '460', label: 'Csongrád' },
      { value: '503', label: 'Derekegyház' },
      { value: '504', label: 'Deszk' },
      { value: '518', label: 'Dóc' },
      { value: '519', label: 'Domaszék' },
      { value: '612', label: 'Eperjes' },
      { value: '640', label: 'Fábiánsebestyén' },
      { value: '659', label: 'Felgyő' },
      { value: '684', label: 'Ferencszállás' },
      { value: '697', label: 'Forráskút' },
      { value: '700', label: 'Földeák' },
      { value: '914', label: 'Hódmezővásárhely' },
      { value: '1136', label: 'Királyhegyes' },
      { value: '1187', label: 'Kistelek' },
      { value: '1196', label: 'Kiszombor' },
      { value: '1202', label: 'Klárafalva' },
      { value: '1259', label: 'Kövegy' },
      { value: '1281', label: 'Kübekháza' },
      { value: '1346', label: 'Magyarcsanád' },
      { value: '1366', label: 'Makó' },
      { value: '1389', label: 'Maroslele' },
      { value: '1390', label: 'Mártély' },
      { value: '1471', label: 'Mindszent' },
      { value: '1491', label: 'Mórahalom' },
      { value: '1533', label: 'Nagyér' },
      { value: '1562', label: 'Nagylak' },
      { value: '1567', label: 'Nagymágocs' },
      { value: '1592', label: 'Nagytőke' },
      { value: '1699', label: 'Óföldeák' },
      { value: '1713', label: 'Ópusztaszer' },
      { value: '1755', label: 'Öttömös' },
      { value: '1849', label: 'Pitvaros' },
      { value: '1879', label: 'Pusztamérges' },
      { value: '1886', label: 'Pusztaszer' },
      { value: '1961', label: 'Röszke' },
      { value: '1964', label: 'Ruzsa' },
      { value: '1992', label: 'Sándorfalva' },
      { value: '2145', label: 'Szatymaz' },
      { value: '2154', label: 'Szeged' },
      { value: '2155', label: 'Szeged' },
      { value: '2156', label: 'Szeged' },
      { value: '2159', label: 'Szegvár' },
      { value: '2164', label: 'Székkutas' },
      { value: '2181', label: 'Szentes' },
      { value: '2385', label: 'Tiszasziget' },
      { value: '2429', label: 'Tömörkény' },
      { value: '2460', label: 'Újszentiván' },
      { value: '2479', label: 'Üllés' },
      { value: '2606', label: 'Zákányszék' },
      { value: '2656', label: 'Zsombó' }

    ]
  },
  {
    label: 'Fejér',
    options: [
      { value: '1', label: 'Aba' },
      { value: '19', label: 'Adony' },
      { value: '30', label: 'Alap' },
      { value: '33', label: 'Alcsútdoboz' },
      { value: '52', label: 'Alsószentiván' },
      { value: '116', label: 'Bakonycsernye' },
      { value: '120', label: 'Bakonykúti' },
      { value: '168', label: 'Balinka' },
      { value: '184', label: 'Baracs' },
      { value: '185', label: 'Baracska' },
      { value: '228', label: 'Beloiannisz' },
      { value: '250', label: 'Besnyő' },
      { value: '257', label: 'Bicske' },
      { value: '274', label: 'Bodajk' },
      { value: '275', label: 'Bodmér' },
      { value: '374', label: 'Cece' },
      { value: '387', label: 'Csabdi' },
      { value: '393', label: 'Csákberény' },
      { value: '394', label: 'Csákvár' },
      { value: '454', label: 'Csókakő' },
      { value: '461', label: 'Csór' },
      { value: '475', label: 'Csősz' },
      { value: '490', label: 'Daruszentmiklós' },
      { value: '496', label: 'Dég' },
      { value: '568', label: 'Dunaújváros' },
      { value: '605', label: 'Előszállás' },
      { value: '611', label: 'Enying' },
      { value: '616', label: 'Ercsi' },
      { value: '638', label: 'Etyek' },
      { value: '654', label: 'Fehérvárcsurgó' },
      { value: '657', label: 'Felcsút' },
      { value: '704', label: 'Füle' },
      { value: '733', label: 'Gánt' },
      { value: '738', label: 'Gárdony' },
      { value: '824', label: 'Gyúró' },
      { value: '850', label: 'Hantos' },
      { value: '939', label: 'Igar' },
      { value: '965', label: 'Iszkaszentgyörgy' },
      { value: '967', label: 'Isztimér' },
      { value: '971', label: 'Iváncsa' },
      { value: '1007', label: 'Jenő' },
      { value: '1015', label: 'Kajászó' },
      { value: '1030', label: 'Káloz' },
      { value: '1038', label: 'Kápolnásnyék' },
      { value: '1133', label: 'Kincsesbánya' },
      { value: '1138', label: 'Kisapostag' },
      { value: '1167', label: 'Kisláng' },
      { value: '1253', label: 'Kőszárhegy' },
      { value: '1261', label: 'Kulcs' },
      { value: '1290', label: 'Lajoskomárom' },
      { value: '1309', label: 'Lepsény' },
      { value: '1326', label: 'Lovasberény' },
      { value: '1344', label: 'Magyaralmás' },
      { value: '1372', label: 'Mány' },
      { value: '1392', label: 'Martonvásár' },
      { value: '1405', label: 'Mátyásdomb' },
      { value: '1437', label: 'Mezőfalva' },
      { value: '1442', label: 'Mezőkomárom' },
      { value: '1453', label: 'Mezőszentgyörgy' },
      { value: '1454', label: 'Mezőszilas' },
      { value: '1480', label: 'Moha' },
      { value: '1489', label: 'Mór' },
      { value: '1508', label: 'Nadap' },
      { value: '1510', label: 'Nádasdladány' },
      { value: '1550', label: 'Nagykarácsony' },
      { value: '1565', label: 'Nagylók' },
      { value: '1598', label: 'Nagyveleg' },
      { value: '1599', label: 'Nagyvenyim' },
      { value: '1761', label: 'Pákozd' },
      { value: '1795', label: 'Pátka' },
      { value: '1801', label: 'Pázmánd' },
      { value: '1819', label: 'Perkáta' },
      { value: '1857', label: 'Polgárdi' },
      { value: '1883', label: 'Pusztaszabolcs' },
      { value: '1888', label: 'Pusztavám' },
      { value: '1908', label: 'Rácalmás' },
      { value: '1909', label: 'Ráckeresztúr' },
      { value: '1997', label: 'Sárbogárd' },
      { value: '1998', label: 'Sáregres' },
      { value: '2003', label: 'Sárkeresztes' },
      { value: '2004', label: 'Sárkeresztúr' },
      { value: '2005', label: 'Sárkeszi' },
      { value: '2007', label: 'Sárosd' },
      { value: '2012', label: 'Sárszentágota' },
      { value: '2014', label: 'Sárszentmihály' },
      { value: '2028', label: 'Seregélyes' },
      { value: '2072', label: 'Soponya' },
      { value: '2086', label: 'Söréd' },
      { value: '2087', label: 'Sukoró' },
      { value: '2094', label: 'Szabadbattyán' },
      { value: '2095', label: 'Szabadegyháza' },
      { value: '2096', label: 'Szabadhidvég' },
      { value: '2134', label: 'Szár' },
      { value: '2137', label: 'Szárliget' },
      { value: '2162', label: 'Székesfehérvár' },
      { value: '2163', label: 'Székesfehérvár' },
      { value: '2253', label: 'Tabajd' },
      { value: '2256', label: 'Tác' },
      { value: '2412', label: 'Tordas' },
      { value: '2473', label: 'Úrhida' },
      { value: '2492', label: 'Vajta' },
      { value: '2493', label: 'Vál' },
      { value: '2549', label: 'Velence' },
      { value: '2553', label: 'Vereb' },
      { value: '2559', label: 'Vértesacsa' },
      { value: '2560', label: 'Vértesboglár' },
      { value: '2631', label: 'Zámoly' },
      { value: '2637', label: 'Zichyújfalu' }

    ]
  },
  {
    label: 'Győr-Moson-Sopron',
    options: [
      { value: '7', label: 'Abda' },
      { value: '14', label: 'Acsalag' },
      { value: '22', label: 'Ágfalva' },
      { value: '24', label: 'Agyagosszergény' },
      { value: '79', label: 'Árpás' },
      { value: '82', label: 'Ásványráró' },
      { value: '93', label: 'Babót' },
      { value: '103', label: 'Bágyogszovát' },
      { value: '189', label: 'Barbacs' },
      { value: '224', label: 'Beled' },
      { value: '253', label: 'Bezenye' },
      { value: '255', label: 'Bezi' },
      { value: '277', label: 'Bodonhely' },
      { value: '290', label: 'Bogyoszló' },
      { value: '323', label: 'Bőny' },
      { value: '324', label: 'Börcs' },
      { value: '326', label: 'Bősárkány' },
      { value: '373', label: 'Cakóháza' },
      { value: '384', label: 'Cirák' },
      { value: '403', label: 'Csapod' },
      { value: '447', label: 'Csikvánd' },
      { value: '462', label: 'Csorna' },
      { value: '489', label: 'Darnózseli' },
      { value: '501', label: 'Dénesfa' },
      { value: '537', label: 'Dör' },
      { value: '557', label: 'Dunakiliti' },
      { value: '559', label: 'Dunaremete' },
      { value: '560', label: 'Dunaszeg' },
      { value: '565', label: 'Dunaszentpál' },
      { value: '566', label: 'Dunasziget' },
      { value: '574', label: 'Écs' },
      { value: '592', label: 'Egyed' },
      { value: '595', label: 'Egyházasfalu' },
      { value: '610', label: 'Enese' },
      { value: '646', label: 'Farád' },
      { value: '653', label: 'Fehértó' },
      { value: '656', label: 'Feketeerdő' },
      { value: '660', label: 'Felpéc' },
      { value: '685', label: 'Fertőboz' },
      { value: '686', label: 'Fertőd' },
      { value: '687', label: 'Fertőendréd' },
      { value: '688', label: 'Fertőhomok' },
      { value: '689', label: 'Fertőrákos' },
      { value: '690', label: 'Fertőszentmiklós' },
      { value: '691', label: 'Fertőszéplak' },
      { value: '774', label: 'Gönyő' },
      { value: '783', label: 'Gyalóka' },
      { value: '785', label: 'Gyarmat' },
      { value: '791', label: 'Gyóró' },
      { value: '792', label: 'Gyömöre' },
      { value: '803', label: 'Győr' },
      { value: '804', label: 'Győrasszonyfa' },
      { value: '808', label: 'Győrladamér' },
      { value: '810', label: 'Győrság' },
      { value: '811', label: 'Győrsövényház' },
      { value: '812', label: 'Győrszemere' },
      { value: '814', label: 'Győrújbarát' },
      { value: '815', label: 'Győrújfalu' },
      { value: '817', label: 'Győrzámoly' },
      { value: '843', label: 'Halászi' },
      { value: '852', label: 'Harka' },
      { value: '860', label: 'Hédervár' },
      { value: '862', label: 'Hegyeshalom' },
      { value: '864', label: 'Hegykő' },
      { value: '909', label: 'Hidegség' },
      { value: '911', label: 'Himod' },
      { value: '932', label: 'Hövej' },
      { value: '947', label: 'Ikrény' },
      { value: '969', label: 'Iván' },
      { value: '985', label: 'Jánossomorja' },
      { value: '1008', label: 'Jobaháza' },
      { value: '1014', label: 'Kajárpéc' },
      { value: '1048', label: 'Kapuvár' },
      { value: '1062', label: 'Károlyháza' },
      { value: '1132', label: 'Kimle' },
      { value: '1141', label: 'Kisbajcs' },
      { value: '1145', label: 'Kisbodak' },
      { value: '1150', label: 'Kisfalud' },
      { value: '1220', label: 'Kóny' },
      { value: '1222', label: 'Kópháza' },
      { value: '1225', label: 'Koroncó' },
      { value: '1274', label: 'Kunsziget' },
      { value: '1301', label: 'Lébény' },
      { value: '1314', label: 'Levél' },
      { value: '1319', label: 'Lipót' },
      { value: '1331', label: 'Lövő' },
      { value: '1376', label: 'Máriakálnok' },
      { value: '1383', label: 'Markotabödöge' },
      { value: '1410', label: 'Mecsér' },
      { value: '1425', label: 'Mérges' },
      { value: '1449', label: 'Mezőörs' },
      { value: '1462', label: 'Mihályi' },
      { value: '1493', label: 'Mórichida' },
      { value: '1495', label: 'Mosonmagyaróvár' },
      { value: '1496', label: 'Mosonszentmiklós' },
      { value: '1497', label: 'Mosonszolnok' },
      { value: '1516', label: 'Nagybajcs' },
      { value: '1524', label: 'Nagycenk' },
      { value: '1566', label: 'Nagylózs' },
      { value: '1585', label: 'Nagyszentjános' },
      { value: '1619', label: 'Nemeskér' },
      { value: '1650', label: 'Nyalka' },
      { value: '1694', label: 'Nyúl' },
      { value: '1724', label: 'Osli' },
      { value: '1754', label: 'Öttevény' },
      { value: '1765', label: 'Páli' },
      { value: '1775', label: 'Pannonhalma' },
      { value: '1792', label: 'Pásztori' },
      { value: '1802', label: 'Pázmándfalu' },
      { value: '1813', label: 'Pér' },
      { value: '1818', label: 'Pereszteg' },
      { value: '1829', label: 'Petőháza' },
      { value: '1846', label: 'Pinnye' },
      { value: '1872', label: 'Pusztacsalád' },
      { value: '1895', label: 'Rábacsanak' },
      { value: '1898', label: 'Rábakecöl' },
      { value: '1899', label: 'Rábapatona' },
      { value: '1901', label: 'Rábapordány' },
      { value: '1902', label: 'Rábasebes' },
      { value: '1903', label: 'Rábaszentandrás' },
      { value: '1904', label: 'Rábaszentmihály' },
      { value: '1905', label: 'Rábaszentmiklós' },
      { value: '1906', label: 'Rábatamási' },
      { value: '1913', label: 'Rajka' },
      { value: '1928', label: 'Ravazd' },
      { value: '1936', label: 'Répceszemere' },
      { value: '1938', label: 'Répcevis' },
      { value: '1940', label: 'Rétalap' },
      { value: '1959', label: 'Röjtökmuzsaj' },
      { value: '2011', label: 'Sarród' },
      { value: '2040', label: 'Sobor' },
      { value: '2041', label: 'Sokorópátka' },
      { value: '2073', label: 'Sopron' },
      { value: '2074', label: 'Sopron' },
      { value: '2075', label: 'Sopronhorpács' },
      { value: '2076', label: 'Sopronkövesd' },
      { value: '2077', label: 'Sopronnémeti' },
      { value: '2132', label: 'Szany' },
      { value: '2136', label: 'Szárföld' },
      { value: '2202', label: 'Szerecseny' },
      { value: '2218', label: 'Szil' },
      { value: '2221', label: 'Szilsárkány' },
      { value: '2270', label: 'Táp' },
      { value: '2280', label: 'Tápszentmiklós' },
      { value: '2289', label: 'Tarjánpuszta' },
      { value: '2321', label: 'Tényő' },
      { value: '2330', label: 'Tét' },
      { value: '2427', label: 'Töltéstava' },
      { value: '2450', label: 'Újkér' },
      { value: '2456', label: 'Újrónafő' },
      { value: '2468', label: 'Und' },
      { value: '2504', label: 'Vámosszabadi' },
      { value: '2511', label: 'Várbalog' },
      { value: '2566', label: 'Veszkény' },
      { value: '2589', label: 'Vitnyéd' },
      { value: '2595', label: 'Völcsej' },
      { value: '2651', label: 'Zsebeháza' },
      { value: '2655', label: 'Zsira' }

    ]
  },
  {
    label: 'Hajdú-Bihar',
    options: [
      { value: '39', label: 'Álmosd' },
      { value: '80', label: 'Ártánd' },
      { value: '102', label: 'Bagamér' },
      { value: '112', label: 'Bakonszeg' },
      { value: '171', label: 'Balmazújváros' },
      { value: '186', label: 'Báránd' },
      { value: '212', label: 'Bedő' },
      { value: '237', label: 'Berekböszörmény' },
      { value: '240', label: 'Berettyóújfalu' },
      { value: '258', label: 'Bihardancsháza' },
      { value: '259', label: 'Biharkeresztes' },
      { value: '260', label: 'Biharnagybajom' },
      { value: '261', label: 'Bihartorda' },
      { value: '272', label: 'Bocskaikert' },
      { value: '291', label: 'Bojt' },
      { value: '467', label: 'Csökmő' },
      { value: '491', label: 'Darvas' },
      { value: '493', label: 'Debrecen' },
      { value: '502', label: 'Derecske' },
      { value: '573', label: 'Ebes' },
      { value: '593', label: 'Egyek' },
      { value: '632', label: 'Esztár' },
      { value: '701', label: 'Földes' },
      { value: '703', label: 'Furta' },
      { value: '706', label: 'Fülöp' },
      { value: '716', label: 'Gáborján' },
      { value: '775', label: 'Görbeháza' },
      { value: '830', label: 'Hajdúbagos' },
      { value: '831', label: 'Hajdúböszörmény' },
      { value: '832', label: 'Hajdúdorog' },
      { value: '833', label: 'Hajdúhadház' },
      { value: '834', label: 'Hajdúnánás' },
      { value: '835', label: 'Hajdúsámson' },
      { value: '836', label: 'Hajdúszoboszló' },
      { value: '837', label: 'Hajdúszoboszló' },
      { value: '838', label: 'Hajdúszovát' },
      { value: '877', label: 'Hencida' },
      { value: '924', label: 'Hortobágy' },
      { value: '927', label: 'Hosszúpályi' },
      { value: '1012', label: 'Kaba' },
      { value: '1171', label: 'Kismarja' },
      { value: '1208', label: 'Kokad' },
      { value: '1210', label: 'Komádi' },
      { value: '1221', label: 'Konyár' },
      { value: '1251', label: 'Körösszakál' },
      { value: '1252', label: 'Körösszegapáti' },
      { value: '1312', label: 'Létavértes' },
      { value: '1352', label: 'Magyarhomorog' },
      { value: '1450', label: 'Mezőpeterd' },
      { value: '1451', label: 'Mezősas' },
      { value: '1466', label: 'Mikepércs' },
      { value: '1487', label: 'Monostorpályi' },
      { value: '1511', label: 'Nádudvar' },
      { value: '1542', label: 'Nagyhegyes' },
      { value: '1552', label: 'Nagykereki' },
      { value: '1574', label: 'Nagyrábé' },
      { value: '1658', label: 'Nyírábrány' },
      { value: '1659', label: 'Nyíracsád' },
      { value: '1661', label: 'Nyíradony' },
      { value: '1681', label: 'Nyírmártonfalva' },
      { value: '1850', label: 'Pocsaj' },
      { value: '1856', label: 'Polgár' },
      { value: '1892', label: 'Püspökladány' },
      { value: '1994', label: 'Sáp' },
      { value: '1995', label: 'Sáránd' },
      { value: '2010', label: 'Sárrétudvari' },
      { value: '2198', label: 'Szentpéterszeg' },
      { value: '2205', label: 'Szerep' },
      { value: '2292', label: 'Tarnalelesz' },
      { value: '2310', label: 'Téglás' },
      { value: '2322', label: 'Tépe' },
      { value: '2331', label: 'Tetétlen' },
      { value: '2348', label: 'Tiszacsege' },
      { value: '2358', label: 'Tiszagyulaháza' },
      { value: '2405', label: 'Told' },
      { value: '2448', label: 'Újiráz' },
      { value: '2453', label: 'Újléta' },
      { value: '2461', label: 'Újszentmargita' },
      { value: '2464', label: 'Újtikos' },
      { value: '2502', label: 'Vámospércs' },
      { value: '2505', label: 'Váncsod' },
      { value: '2547', label: 'Vekerd' },
      { value: '2646', label: 'Zsáka' }

    ]
  },
  {
    label: 'Heves',
    options: [
      { value: '4', label: 'Abasár' },
      { value: '16', label: 'Adács' },
      { value: '34', label: 'Aldebrő' },
      { value: '61', label: 'Andornaktálya' },
      { value: '70', label: 'Apc' },
      { value: '87', label: 'Átány' },
      { value: '88', label: 'Atkár' },
      { value: '138', label: 'Balaton' },
      { value: '202', label: 'Bátor' },
      { value: '220', label: 'Bekölce' },
      { value: '221', label: 'Bélapátfalva' },
      { value: '248', label: 'Besenyőtelek' },
      { value: '269', label: 'Boconád' },
      { value: '278', label: 'Bodony' },
      { value: '293', label: 'Boldog' },
      { value: '368', label: 'Bükkszék' },
      { value: '369', label: 'Bükkszenterzsébet' },
      { value: '399', label: 'Csány' },
      { value: '500', label: 'Demjén' },
      { value: '506', label: 'Detk' },
      { value: '525', label: 'Domoszló' },
      { value: '526', label: 'Dormánd' },
      { value: '575', label: 'Ecséd' },
      { value: '581', label: 'Eger' },
      { value: '582', label: 'Eger' },
      { value: '584', label: 'Egerbakta' },
      { value: '585', label: 'Egerbocs' },
      { value: '586', label: 'Egerfarmos' },
      { value: '588', label: 'Egerszalók' },
      { value: '589', label: 'Egerszólát' },
      { value: '621', label: 'Erdőkövesd' },
      { value: '625', label: 'Erdőtelek' },
      { value: '626', label: 'Erk' },
      { value: '650', label: 'Fedémes' },
      { value: '658', label: 'Feldebrő' },
      { value: '677', label: 'Felsőtárkány' },
      { value: '714', label: 'Füzesabony' },
      { value: '794', label: 'Gyöngyös' },
      { value: '796', label: 'Gyöngyöshalász' },
      { value: '798', label: 'Gyöngyösoroszi' },
      { value: '799', label: 'Gyöngyöspata' },
      { value: '800', label: 'Gyöngyössolymos' },
      { value: '801', label: 'Gyöngyöstarján' },
      { value: '847', label: 'Halmajugra' },
      { value: '859', label: 'Hatvan' },
      { value: '882', label: 'Heréd' },
      { value: '901', label: 'Heves' },
      { value: '902', label: 'Hevesaranyos' },
      { value: '903', label: 'Hevesvezekény' },
      { value: '923', label: 'Hort' },
      { value: '963', label: 'Istenmezeje' },
      { value: '968', label: 'Ivád' },
      { value: '1020', label: 'Kál' },
      { value: '1037', label: 'Kápolna' },
      { value: '1050', label: 'Karácsond' },
      { value: '1107', label: 'Kerecsend' },
      { value: '1151', label: 'Kisfüzes' },
      { value: '1161', label: 'Kisköre' },
      { value: '1174', label: 'Kisnána' },
      { value: '1216', label: 'Kompolt' },
      { value: '1238', label: 'Kömlő' },
      { value: '1330', label: 'Lőrinci' },
      { value: '1335', label: 'Ludas' },
      { value: '1365', label: 'Maklár' },
      { value: '1380', label: 'Markaz' },
      { value: '1396', label: 'Mátraballa' },
      { value: '1397', label: 'Mátraderecske' },
      { value: '1401', label: 'Mátraszentimre' },
      { value: '1452', label: 'Mezőszemere' },
      { value: '1455', label: 'Mezőtárkány' },
      { value: '1468', label: 'Mikófalva' },
      { value: '1486', label: 'Mónosbél' },
      { value: '1535', label: 'Nagyfüged' },
      { value: '1557', label: 'Nagykökényes' },
      { value: '1577', label: 'Nagyréde' },
      { value: '1587', label: 'Nagytálya' },
      { value: '1593', label: 'Nagyút' },
      { value: '1600', label: 'Nagyvisnyó' },
      { value: '1646', label: 'Noszvaj' },
      { value: '1647', label: 'Novaj' },
      { value: '1726', label: 'Ostoros' },
      { value: '1787', label: 'Parád' },
      { value: '1788', label: 'Parádsasvár' },
      { value: '1808', label: 'Pély' },
      { value: '1824', label: 'Pétervására' },
      { value: '1827', label: 'Petőfibánya' },
      { value: '1861', label: 'Poroszló' },
      { value: '1929', label: 'Recsk' },
      { value: '1958', label: 'Rózsaszentmárton' },
      { value: '2015', label: 'Sarud' },
      { value: '2038', label: 'Sirok' },
      { value: '2107', label: 'Szajla' },
      { value: '2140', label: 'Szarvaskő' },
      { value: '2178', label: 'Szentdomonkos' },
      { value: '2216', label: 'Szihalom' },
      { value: '2222', label: 'Szilvásvárad' },
      { value: '2238', label: 'Szőcsi' },
      { value: '2245', label: 'Szúcs' },
      { value: '2291', label: 'Tarnabod' },
      { value: '2293', label: 'Tarnalelesz' },
      { value: '2294', label: 'Tarnaméra' },
      { value: '2295', label: 'Tarnaörs' },
      { value: '2296', label: 'Tarnaszentmária' },
      { value: '2297', label: 'Tarnaszentmiklós' },
      { value: '2298', label: 'Tarnazsadány' },
      { value: '2320', label: 'Tenk' },
      { value: '2374', label: 'Tiszanána' },
      { value: '2399', label: 'Tófalu' },
      { value: '2454', label: 'Újlőrincfalva' },
      { value: '2499', label: 'Vámosgyörk' },
      { value: '2510', label: 'Váraszó' },
      { value: '2542', label: 'Vécs' },
      { value: '2556', label: 'Verpelét' },
      { value: '2584', label: 'Visonta' },
      { value: '2588', label: 'Visznek' },
      { value: '2602', label: 'Zagyvaszántó' },
      { value: '2633', label: 'Zaránk' }

    ]
  },
  {
    label: 'Jász-Nagykun-Szolnok',
    options: [
      { value: '2', label: 'Abádszalók' },
      { value: '31', label: 'Alattyán' },
      { value: '238', label: 'Berekfürdő' },
      { value: '249', label: 'Besenyszög' },
      { value: '381', label: 'Cibakháza' },
      { value: '411', label: 'Csataszög' },
      { value: '429', label: 'Csépa' },
      { value: '435', label: 'Cserkeszőlő' },
      { value: '651', label: 'Fegyvernek' },
      { value: '935', label: 'Hunyadfalva' },
      { value: '984', label: 'Jánoshida' },
      { value: '989', label: 'Jászágó' },
      { value: '990', label: 'Jászalsószentgyörgy' },
      { value: '991', label: 'Jászapáti' },
      { value: '992', label: 'Jászárokszállás' },
      { value: '993', label: 'Jászberény' },
      { value: '994', label: 'Jászboldogháza' },
      { value: '995', label: 'Jászdózsa' },
      { value: '996', label: 'Jászfelsőszentgyörgy' },
      { value: '997', label: 'Jászfényszaru' },
      { value: '998', label: 'Jászivány' },
      { value: '999', label: 'Jászjákóhalma' },
      { value: '1001', label: 'Jászkisér' },
      { value: '1002', label: 'Jászladány' },
      { value: '1003', label: 'Jászszentandrás' },
      { value: '1005', label: 'Jásztelek' },
      { value: '1058', label: 'Karcag' },
      { value: '1100', label: 'Kenderes' },
      { value: '1103', label: 'Kengyel' },
      { value: '1126', label: 'Kétpó' },
      { value: '1191', label: 'Kisújszállás' },
      { value: '1258', label: 'Kőtelek' },
      { value: '1266', label: 'Kuncsorba' },
      { value: '1268', label: 'Kunhegyes' },
      { value: '1269', label: 'Kunmadaras' },
      { value: '1272', label: 'Kunszentmárton' },
      { value: '1391', label: 'Martfő' },
      { value: '1431', label: 'Mesterszállás' },
      { value: '1440', label: 'Mezőhék' },
      { value: '1456', label: 'Mezőtúr' },
      { value: '1544', label: 'Nagyiván' },
      { value: '1559', label: 'Nagykörő' },
      { value: '1578', label: 'Nagyrév' },
      { value: '1736', label: 'Öcsöd' },
      { value: '1747', label: 'Örményes' },
      { value: '1881', label: 'Pusztamonostor' },
      { value: '1917', label: 'Rákóczifalva' },
      { value: '1918', label: 'Rákócziújfalu' },
      { value: '2108', label: 'Szajol' },
      { value: '2141', label: 'Szászberek' },
      { value: '2167', label: 'Szelevény' },
      { value: '2230', label: 'Szolnok' },
      { value: '2345', label: 'Tiszabő' },
      { value: '2346', label: 'Tiszabura' },
      { value: '2351', label: 'Tiszaderzs' },
      { value: '2355', label: 'Tiszaföldvár' },
      { value: '2356', label: 'Tiszafüred' },
      { value: '2357', label: 'Tiszagyenda' },
      { value: '2359', label: 'Tiszaigar' },
      { value: '2360', label: 'Tiszainoka' },
      { value: '2361', label: 'Tiszajenő' },
      { value: '2368', label: 'Tiszakürt' },
      { value: '2375', label: 'Tiszaörs' },
      { value: '2377', label: 'Tiszapüspöki' },
      { value: '2379', label: 'Tiszaroff' },
      { value: '2380', label: 'Tiszasas' },
      { value: '2381', label: 'Tiszasüly' },
      { value: '2383', label: 'Tiszaszentimre' },
      { value: '2386', label: 'Tiszaszőlős' },
      { value: '2390', label: 'Tiszatenyő' },
      { value: '2391', label: 'Tiszaug' },
      { value: '2394', label: 'Tiszavárkony' },
      { value: '2409', label: 'Tomajmonostora' },
      { value: '2420', label: 'Tószeg' },
      { value: '2431', label: 'Törökszentmiklós' },
      { value: '2437', label: 'Túrkeve' },
      { value: '2459', label: 'Újszász' },
      { value: '2571', label: 'Vezseny' },
      { value: '2601', label: 'Zagyvarékas' }

    ]
  },
  {
    label: 'Komárom-Esztergom',
    options: [
      { value: '11', label: 'Ács' },
      { value: '15', label: 'Ácsteszér' },
      { value: '27', label: 'Aka' },
      { value: '36', label: 'Almásfüzitő' },
      { value: '63', label: 'Annavölgy' },
      { value: '84', label: 'Ászár' },
      { value: '91', label: 'Bábolna' },
      { value: '104', label: 'Baj' },
      { value: '107', label: 'Bajna' },
      { value: '108', label: 'Bajót' },
      { value: '114', label: 'Bakonybánk' },
      { value: '126', label: 'Bakonysárkány' },
      { value: '129', label: 'Bakonyszombathely' },
      { value: '176', label: 'Bana' },
      { value: '194', label: 'Bársonyos' },
      { value: '292', label: 'Bokod' },
      { value: '407', label: 'Császár' },
      { value: '412', label: 'Csatka' },
      { value: '419', label: 'Csém' },
      { value: '428', label: 'Csép' },
      { value: '457', label: 'Csolnok' },
      { value: '481', label: 'Dad' },
      { value: '482', label: 'Dág' },
      { value: '527', label: 'Dorog' },
      { value: '535', label: 'Dömös' },
      { value: '550', label: 'Dunaalmás' },
      { value: '564', label: 'Dunaszentmiklós' },
      { value: '615', label: 'Epöl' },
      { value: '635', label: 'Esztergom' },
      { value: '636', label: 'Ete' },
      { value: '789', label: 'Gyermely' },
      { value: '883', label: 'Héreg' },
      { value: '1078', label: 'Kecskéd' },
      { value: '1111', label: 'Kerékteleki' },
      { value: '1122', label: 'Kesztölc' },
      { value: '1143', label: 'Kisbér' },
      { value: '1158', label: 'Kisigmánd' },
      { value: '1203', label: 'Kocs' },
      { value: '1211', label: 'Komárom' },
      { value: '1239', label: 'Kömlőd' },
      { value: '1243', label: 'Környe' },
      { value: '1284', label: 'Lábatlan' },
      { value: '1300', label: 'Leányvár' },
      { value: '1375', label: 'Máriahalom' },
      { value: '1476', label: 'Mocsa' },
      { value: '1478', label: 'Mogyorósbánya' },
      { value: '1543', label: 'Nagyigmánd' },
      { value: '1579', label: 'Nagysáp' },
      { value: '1605', label: 'Naszály' },
      { value: '1634', label: 'Neszmély' },
      { value: '1656', label: 'Nyergesújfalu' },
      { value: '1721', label: 'Oroszlány' },
      { value: '1836', label: 'Piliscsév' },
      { value: '1838', label: 'Pilismarót' },
      { value: '1930', label: 'Réde' },
      { value: '2000', label: 'Sárisáp' },
      { value: '2088', label: 'Súr' },
      { value: '2093', label: 'Süttő' },
      { value: '2116', label: 'Szákszend' },
      { value: '2233', label: 'Szomód' },
      { value: '2235', label: 'Szomor' },
      { value: '2286', label: 'Tardos' },
      { value: '2288', label: 'Tarján' },
      { value: '2290', label: 'Tárkány' },
      { value: '2304', label: 'Tát' },
      { value: '2305', label: 'Tata' },
      { value: '2306', label: 'Tatabánya' },
      { value: '2402', label: 'Tokod' },
      { value: '2403', label: 'Tokodaltáró' },
      { value: '2469', label: 'Úny' },
      { value: '2516', label: 'Várgesztes' },
      { value: '2561', label: 'Vérteskethely' },
      { value: '2562', label: 'Vértessomló' },
      { value: '2563', label: 'Vértestolna' },
      { value: '2564', label: 'Vértesszőlős' }

    ]
  },
  {
    label: 'Nógrád',
    options: [
      { value: '49', label: 'Alsópetény' },
      { value: '54', label: 'Alsótold' },
      { value: '136', label: 'Balassagyarmat' },
      { value: '179', label: 'Bánk' },
      { value: '193', label: 'Bárna' },
      { value: '201', label: 'Bátonyterenye' },
      { value: '209', label: 'Becske' },
      { value: '232', label: 'Bér' },
      { value: '234', label: 'Bercel' },
      { value: '242', label: 'Berkenye' },
      { value: '312', label: 'Borsosberény' },
      { value: '362', label: 'Buják' },
      { value: '379', label: 'Cered' },
      { value: '414', label: 'Csécse' },
      { value: '433', label: 'Cserhátsurány' },
      { value: '434', label: 'Cserhátszentiván' },
      { value: '441', label: 'Csesztve' },
      { value: '450', label: 'Csitár' },
      { value: '497', label: 'Dejtár' },
      { value: '512', label: 'Diósjenő' },
      { value: '528', label: 'Dorogháza' },
      { value: '546', label: 'Drégelypalánk' },
      { value: '594', label: 'Egyházasdengeleg' },
      { value: '596', label: 'Egyházasgerge' },
      { value: '609', label: 'Endrefalva' },
      { value: '622', label: 'Erdőkürt' },
      { value: '624', label: 'Erdőtarcsa' },
      { value: '630', label: 'Érsekvadkert' },
      { value: '637', label: 'Etes' },
      { value: '673', label: 'Felsőpetény' },
      { value: '679', label: 'Felsőtold' },
      { value: '726', label: 'Galgaguta' },
      { value: '868', label: 'Héhalom' },
      { value: '884', label: 'Herencsény' },
      { value: '916', label: 'Hollókő' },
      { value: '922', label: 'Hont' },
      { value: '933', label: 'Hugyag' },
      { value: '957', label: 'Ipolytarnóc' },
      { value: '959', label: 'Ipolyvece' },
      { value: '1009', label: 'Jobbágyi' },
      { value: '1023', label: 'Kálló' },
      { value: '1054', label: 'Karancsalja' },
      { value: '1055', label: 'Karancsberény' },
      { value: '1056', label: 'Karancskeszi' },
      { value: '1057', label: 'Karancslapujtő' },
      { value: '1073', label: 'Kazár' },
      { value: '1140', label: 'Kisbágyon' },
      { value: '1149', label: 'Kisecset' },
      { value: '1155', label: 'Kishartyán' },
      { value: '1230', label: 'Kozárd' },
      { value: '1302', label: 'Legénd' },
      { value: '1321', label: 'Litke' },
      { value: '1333', label: 'Lucfalva' },
      { value: '1334', label: 'Ludányhalászi' },
      { value: '1350', label: 'Magyargéc' },
      { value: '1356', label: 'Magyarnándor' },
      { value: '1381', label: 'Márkháza' },
      { value: '1398', label: 'Mátramindszent' },
      { value: '1399', label: 'Mátranovák' },
      { value: '1400', label: 'Mátraszele' },
      { value: '1402', label: 'Mátraszőlős' },
      { value: '1403', label: 'Mátraterenye' },
      { value: '1404', label: 'Mátraverebély' },
      { value: '1460', label: 'Mihálygerge' },
      { value: '1482', label: 'Mohora' },
      { value: '1564', label: 'Nagylóc' },
      { value: '1571', label: 'Nagyoroszi' },
      { value: '1633', label: 'Nemti' },
      { value: '1635', label: 'Nézsa' },
      { value: '1638', label: 'Nógrád' },
      { value: '1639', label: 'Nógrádkövesd' },
      { value: '1640', label: 'Nógrádmarcal' },
      { value: '1641', label: 'Nógrádmegyer' },
      { value: '1642', label: 'Nógrádsáp' },
      { value: '1643', label: 'Nógrádsipek' },
      { value: '1644', label: 'Nógrádszakál' },
      { value: '1649', label: 'Nőtincs' },
      { value: '1744', label: 'őrhalom' },
      { value: '1751', label: 'ősagárd' },
      { value: '1770', label: 'Palotás' },
      { value: '1791', label: 'Pásztó' },
      { value: '1793', label: 'Patak' },
      { value: '1798', label: 'Patvarc' },
      { value: '1832', label: 'Piliny' },
      { value: '1871', label: 'Pusztaberki' },
      { value: '1916', label: 'Rákóczibánya' },
      { value: '1942', label: 'Rétság' },
      { value: '1948', label: 'Rimóc' },
      { value: '1956', label: 'Romhány' },
      { value: '1965', label: 'Ságújfalu' },
      { value: '1986', label: 'Salgótarján' },
      { value: '1990', label: 'Sámsonháza' },
      { value: '2080', label: 'Sóshartyán' },
      { value: '2122', label: 'Szalmatercs' },
      { value: '2129', label: 'Szanda' },
      { value: '2139', label: 'Szarvasgede' },
      { value: '2144', label: 'Szátok' },
      { value: '2149', label: 'Szécsénke' },
      { value: '2150', label: 'Szécsény' },
      { value: '2151', label: 'Szécsényfelfalu' },
      { value: '2172', label: 'Szendehely' },
      { value: '2220', label: 'Szilaspogony' },
      { value: '2225', label: 'Szirák' },
      { value: '2246', label: 'Szuha' },
      { value: '2250', label: 'Szurdokpüspöki' },
      { value: '2251', label: 'Szügy' },
      { value: '2281', label: 'Tar' },
      { value: '2324', label: 'Terény' },
      { value: '2325', label: 'Tereske' },
      { value: '2406', label: 'Tolmács' },
      { value: '2506', label: 'Vanyarc' },
      { value: '2521', label: 'Varsány' },
      { value: '2591', label: 'Vizslás' },
      { value: '2598', label: 'Zabar' }

    ]
  },
  {
    label: 'Pest',
    options: [
      { value: '9', label: 'Abony' },
      { value: '12', label: 'Acsa' },
      { value: '32', label: 'Albertirsa' },
      { value: '44', label: 'Alsónémedi' },
      { value: '66', label: 'Apaj' },
      { value: '71', label: 'Áporka' },
      { value: '85', label: 'Aszód' },
      { value: '101', label: 'Bag' },
      { value: '231', label: 'Bénye' },
      { value: '244', label: 'Bernecebaráti' },
      { value: '256', label: 'Biatorbágy' },
      { value: '331', label: 'Budajenő' },
      { value: '332', label: 'Budakalász' },
      { value: '333', label: 'Budakeszi' },
      { value: '334', label: 'Budaörs' },
      { value: '335', label: 'Budaörs' },
      { value: '360', label: 'Bugyi' },
      { value: '376', label: 'Cegléd' },
      { value: '377', label: 'Ceglédbercel' },
      { value: '420', label: 'Csemő' },
      { value: '443', label: 'Csévharaszt' },
      { value: '453', label: 'Csobánka' },
      { value: '459', label: 'Csomád' },
      { value: '470', label: 'Csömör' },
      { value: '473', label: 'Csörög' },
      { value: '476', label: 'Csővár' },
      { value: '478', label: 'Dabas' },
      { value: '486', label: 'Dánszentmiklós' },
      { value: '487', label: 'Dány' },
      { value: '498', label: 'Délegyháza' },
      { value: '511', label: 'Diósd' },
      { value: '515', label: 'Dobogókő' },
      { value: '524', label: 'Domony' },
      { value: '536', label: 'Dömsöd' },
      { value: '551', label: 'Dunabogdány' },
      { value: '555', label: 'Dunaharaszti' },
      { value: '556', label: 'Dunakeszi' },
      { value: '569', label: 'Dunavarsány' },
      { value: '578', label: 'Ecser' },
      { value: '617', label: 'Érd' },
      { value: '620', label: 'Erdőkertes' },
      { value: '648', label: 'Farmos' },
      { value: '672', label: 'Felsőpakony' },
      { value: '699', label: 'Fót' },
      { value: '727', label: 'Galgagyörk' },
      { value: '728', label: 'Galgahévíz' },
      { value: '729', label: 'Galgamácsa' },
      { value: '762', label: 'Gomba' },
      { value: '767', label: 'Göd' },
      { value: '768', label: 'Göd' },
      { value: '769', label: 'Gödöllő' },
      { value: '782', label: 'Gyál' },
      { value: '793', label: 'Gyömrő' },
      { value: '844', label: 'Halásztelek' },
      { value: '879', label: 'Herceghalom' },
      { value: '888', label: 'Hernád' },
      { value: '905', label: 'Hévízgyörk' },
      { value: '944', label: 'Iklad' },
      { value: '953', label: 'Inárcs' },
      { value: '956', label: 'Ipolydamásd' },
      { value: '958', label: 'Ipolytölgyes' },
      { value: '961', label: 'Isaszeg' },
      { value: '1000', label: 'Jászkarajenő' },
      { value: '1019', label: 'Kakucs' },
      { value: '1064', label: 'Kartal' },
      { value: '1071', label: 'Káva' },
      { value: '1090', label: 'Kemence' },
      { value: '1112', label: 'Kerepes' },
      { value: '1165', label: 'Kiskunlacháza' },
      { value: '1172', label: 'Kismaros' },
      { value: '1175', label: 'Kisnémedi' },
      { value: '1177', label: 'Kisoroszi' },
      { value: '1186', label: 'Kistarcsa' },
      { value: '1204', label: 'Kocsér' },
      { value: '1207', label: 'Kóka' },
      { value: '1226', label: 'Kosd' },
      { value: '1227', label: 'Kóspallag' },
      { value: '1249', label: 'Kőröstetétlen' },
      { value: '1299', label: 'Leányfalu' },
      { value: '1313', label: 'Letkés' },
      { value: '1324', label: 'Lórév' },
      { value: '1340', label: 'Maglód' },
      { value: '1361', label: 'Majosháza' },
      { value: '1363', label: 'Makád' },
      { value: '1378', label: 'Márianosztra' },
      { value: '1423', label: 'Mende' },
      { value: '1464', label: 'Mikebuda' },
      { value: '1477', label: 'Mogyoród' },
      { value: '1485', label: 'Monor' },
      { value: '1523', label: 'Nagybörzsöny' },
      { value: '1551', label: 'Nagykáta' },
      { value: '1556', label: 'Nagykovácsi' },
      { value: '1560', label: 'Nagykőrös' },
      { value: '1569', label: 'Nagymaros' },
      { value: '1588', label: 'Nagytarcsa' },
      { value: '1652', label: 'Nyáregyháza' },
      { value: '1654', label: 'Nyársapát' },
      { value: '1695', label: 'Ócsa' },
      { value: '1741', label: 'őrbottyán' },
      { value: '1746', label: 'Örkény' },
      { value: '1773', label: 'Pánd' },
      { value: '1799', label: 'Páty' },
      { value: '1803', label: 'Pécel' },
      { value: '1809', label: 'Penc' },
      { value: '1814', label: 'Perbál' },
      { value: '1821', label: 'Perőcsény' },
      { value: '1823', label: 'Péteri' },
      { value: '1833', label: 'Pilis' },
      { value: '1834', label: 'Pilisborosjenő' },
      { value: '1835', label: 'Piliscsaba' },
      { value: '1837', label: 'Pilisjászfalu' },
      { value: '1839', label: 'Pilisvörösvár' },
      { value: '1840', label: 'Pilisszántó' },
      { value: '1841', label: 'Pilisszentiván' },
      { value: '1842', label: 'Pilisszentkereszt' },
      { value: '1843', label: 'Pilisszentlászló' },
      { value: '1851', label: 'Pócsmegyer' },
      { value: '1858', label: 'Pomáz' },
      { value: '1887', label: 'Pusztavacs' },
      { value: '1889', label: 'Pusztazámor' },
      { value: '1891', label: 'Püspökhatvan' },
      { value: '1894', label: 'Püspökszilágy' },
      { value: '1910', label: 'Ráckeve' },
      { value: '1911', label: 'Rád' },
      { value: '1933', label: 'Remeteszőlős' },
      { value: '2046', label: 'Solymár' },
      { value: '2081', label: 'Sóskút' },
      { value: '2090', label: 'Sülysáp' },
      { value: '2104', label: 'Szada' },
      { value: '2147', label: 'Százhalombatta' },
      { value: '2180', label: 'Szentendre' },
      { value: '2194', label: 'Szentlőrinckáta' },
      { value: '2195', label: 'Szentmártonkáta' },
      { value: '2207', label: 'Szigetbecse' },
      { value: '2208', label: 'Szigetcsép' },
      { value: '2209', label: 'Szigethalom' },
      { value: '2210', label: 'Szigetmonostor' },
      { value: '2211', label: 'Szigetszentmárton' },
      { value: '2212', label: 'Szigetszentmiklós' },
      { value: '2213', label: 'Szigetújfalu' },
      { value: '2227', label: 'Szob' },
      { value: '2228', label: 'Szokolya' },
      { value: '2239', label: 'Sződ' },
      { value: '2240', label: 'Sződliget' },
      { value: '2255', label: 'Táborfalva' },
      { value: '2258', label: 'Tahitótfalu' },
      { value: '2261', label: 'Taksony' },
      { value: '2271', label: 'Tápióbicske' },
      { value: '2272', label: 'Tápiógyörgye' },
      { value: '2273', label: 'Tápióság' },
      { value: '2274', label: 'Tápiószecső' },
      { value: '2275', label: 'Tápiószele' },
      { value: '2276', label: 'Tápiószentmárton' },
      { value: '2277', label: 'Tápiószőlős' },
      { value: '2299', label: 'Tárnok' },
      { value: '2308', label: 'Tatárszentgyörgy' },
      { value: '2316', label: 'Telki' },
      { value: '2327', label: 'Tésa' },
      { value: '2338', label: 'Tinnye' },
      { value: '2398', label: 'Tóalmás' },
      { value: '2425', label: 'Tök' },
      { value: '2426', label: 'Tököl' },
      { value: '2430', label: 'Törökbálint' },
      { value: '2432', label: 'Törtel' },
      { value: '2435', label: 'Tura' },
      { value: '2447', label: 'Újhartyán' },
      { value: '2452', label: 'Újlengyel' },
      { value: '2462', label: 'Újszilvás' },
      { value: '2474', label: 'Úri' },
      { value: '2480', label: 'Üllő' },
      { value: '2481', label: 'Üröm' },
      { value: '2482', label: 'Vác' },
      { value: '2483', label: 'Vácduka' },
      { value: '2484', label: 'Vácegres' },
      { value: '2485', label: 'Váchartyán' },
      { value: '2486', label: 'Váckisújfalu' },
      { value: '2487', label: 'Vácrátót' },
      { value: '2488', label: 'Vácszentlászló' },
      { value: '2494', label: 'Valkó' },
      { value: '2500', label: 'Vámosmikola' },
      { value: '2522', label: 'Vasad' },
      { value: '2543', label: 'Vecsés' },
      { value: '2544', label: 'Vecsés' },
      { value: '2554', label: 'Veresegyház' },
      { value: '2555', label: 'Verőce' },
      { value: '2557', label: 'Verseg' },
      { value: '2582', label: 'Visegrád' },
      { value: '2635', label: 'Zebegény' },
      { value: '2647', label: 'Zsámbék' },
      { value: '2648', label: 'Zsámbok' }

    ]
  },
  {
    label: 'Somogy',
    options: [
      { value: '17', label: 'Ádánd' },
      { value: '60', label: 'Andocs' },
      { value: '92', label: 'Bábonymegyer' },
      { value: '110', label: 'Bakháza' },
      { value: '142', label: 'Balatonberény' },
      { value: '143', label: 'Balatonboglár' },
      { value: '145', label: 'Balatonendréd' },
      { value: '146', label: 'Balatonfenyves' },
      { value: '148', label: 'Balatonföldvár' },
      { value: '154', label: 'Balatonkeresztúr' },
      { value: '155', label: 'Balatonlelle' },
      { value: '157', label: 'Balatonmáriafürdő' },
      { value: '158', label: 'Balatonőszöd' },
      { value: '160', label: 'Balatonszabadi' },
      { value: '161', label: 'Balatonszárszó' },
      { value: '162', label: 'Balatonszemes' },
      { value: '163', label: 'Balatonszentgyörgy' },
      { value: '166', label: 'Balatonújlak' },
      { value: '175', label: 'Bálványos' },
      { value: '190', label: 'Barcs' },
      { value: '191', label: 'Bárdudvarnok' },
      { value: '199', label: 'Baté' },
      { value: '211', label: 'Bedegkér' },
      { value: '222', label: 'Bélavár' },
      { value: '225', label: 'Beleg' },
      { value: '246', label: 'Berzence' },
      { value: '266', label: 'Birján' },
      { value: '279', label: 'Bodrog' },
      { value: '296', label: 'Bolhás' },
      { value: '297', label: 'Bolhó' },
      { value: '301', label: 'Bonnya' },
      { value: '320', label: 'Böhönye' },
      { value: '327', label: 'Bőszénfa' },
      { value: '363', label: 'Buzsák' },
      { value: '372', label: 'Büssü' },
      { value: '391', label: 'Csákány' },
      { value: '455', label: 'Csokonyavisonta' },
      { value: '468', label: 'Csököly' },
      { value: '469', label: 'Csömend' },
      { value: '477', label: 'Csurgó' },
      { value: '488', label: 'Darány' },
      { value: '545', label: 'Drávatamási' },
      { value: '577', label: 'Ecseny' },
      { value: '579', label: 'Edde' },
      { value: '667', label: 'Felsőmocsolád' },
      { value: '692', label: 'Fiad' },
      { value: '695', label: 'Fonó' },
      { value: '696', label: 'Fonyód' },
      { value: '719', label: 'Gadács' },
      { value: '731', label: 'Gamás' },
      { value: '771', label: 'Gölle' },
      { value: '777', label: 'Görgeteg' },
      { value: '786', label: 'Gyékényes' },
      { value: '818', label: 'Gyugy' },
      { value: '827', label: 'Hács' },
      { value: '839', label: 'Hajmás' },
      { value: '878', label: 'Hencse' },
      { value: '886', label: 'Heresznye' },
      { value: '899', label: 'Hetes' },
      { value: '919', label: 'Homokszentgyörgy' },
      { value: '929', label: 'Hosszúvíz' },
      { value: '938', label: 'Igal' },
      { value: '941', label: 'Iharos' },
      { value: '942', label: 'Iharosberény' },
      { value: '954', label: 'Inke' },
      { value: '964', label: 'Istvándi' },
      { value: '979', label: 'Jákó' },
      { value: '1011', label: 'Juta' },
      { value: '1026', label: 'Kálmáncsa' },
      { value: '1035', label: 'Kánya' },
      { value: '1039', label: 'Kapoly' },
      { value: '1040', label: 'Kaposfő' },
      { value: '1041', label: 'Kaposmérő' },
      { value: '1043', label: 'Kaposújlak' },
      { value: '1044', label: 'Kaposvár' },
      { value: '1046', label: 'Kaposszerdahely' },
      { value: '1049', label: 'Kára' },
      { value: '1051', label: 'Karád' },
      { value: '1066', label: 'Kastélyosdombó' },
      { value: '1068', label: 'Kaszó' },
      { value: '1076', label: 'Kazsok' },
      { value: '1106', label: 'Kercseliget' },
      { value: '1110', label: 'Kereki' },
      { value: '1125', label: 'Kéthely' },
      { value: '1142', label: 'Kisbajom' },
      { value: '1152', label: 'Kisgyalán' },
      { value: '1160', label: 'Kiskorpád' },
      { value: '1234', label: 'Kőkút' },
      { value: '1245', label: 'Kőröshegy' },
      { value: '1256', label: 'Kötcse' },
      { value: '1280', label: 'Kutas' },
      { value: '1285', label: 'Lábod' },
      { value: '1287', label: 'Lad' },
      { value: '1293', label: 'Lakócsa' },
      { value: '1297', label: 'Látrány' },
      { value: '1307', label: 'Lengyeltóti' },
      { value: '1316', label: 'Libickozma' },
      { value: '1349', label: 'Magyaregres' },
      { value: '1427', label: 'Mernye' },
      { value: '1436', label: 'Mezőcsokonya' },
      { value: '1463', label: 'Mike' },
      { value: '1467', label: 'Miklósi' },
      { value: '1494', label: 'Mosdós' },
      { value: '1512', label: 'Nágocs' },
      { value: '1515', label: 'Nagyatád' },
      { value: '1521', label: 'Nagyberény' },
      { value: '1522', label: 'Nagyberki' },
      { value: '1526', label: 'Nagycsepely' },
      { value: '1555', label: 'Nagykorpád' },
      { value: '1581', label: 'Nagyszakácsi' },
      { value: '1614', label: 'Nemesdéd' },
      { value: '1628', label: 'Nemesvid' },
      { value: '1637', label: 'Nikla' },
      { value: '1657', label: 'Nyim' },
      { value: '1714', label: 'Ordacsehi' },
      { value: '1729', label: 'Osztopán' },
      { value: '1743', label: 'Öreglak' },
      { value: '1749', label: 'őrtilos' },
      { value: '1756', label: 'Ötvöskónyi' },
      { value: '1767', label: 'Pálmajor' },
      { value: '1772', label: 'Pamuk' },
      { value: '1794', label: 'Patalom' },
      { value: '1796', label: 'Patosfa' },
      { value: '1822', label: 'Péterhida' },
      { value: '1854', label: 'Pogányszentpéter' },
      { value: '1855', label: 'Polány' },
      { value: '1884', label: 'Pusztaszemes' },
      { value: '1919', label: 'Ráksi' },
      { value: '1949', label: 'Rinyabesenyő' },
      { value: '1950', label: 'Rinyakovácsi' },
      { value: '1951', label: 'Rinyaszentkirály' },
      { value: '1952', label: 'Rinyaújlak' },
      { value: '1953', label: 'Rinyaújnép' },
      { value: '1966', label: 'Ságvár' },
      { value: '1993', label: 'Sántos' },
      { value: '2022', label: 'Sávoly' },
      { value: '2024', label: 'Segesd' },
      { value: '2035', label: 'Siófok' },
      { value: '2036', label: 'Siófok' },
      { value: '2037', label: 'Siójut' },
      { value: '2047', label: 'Som' },
      { value: '2051', label: 'Somodor' },
      { value: '2052', label: 'Somogyacsa' },
      { value: '2053', label: 'Somogyaszaló' },
      { value: '2054', label: 'Somogybabod' },
      { value: '2055', label: 'Somogybükkösd' },
      { value: '2056', label: 'Somogydöröcske' },
      { value: '2057', label: 'Somogyfajsz' },
      { value: '2058', label: 'Somogygeszti' },
      { value: '2061', label: 'Somogymeggyes' },
      { value: '2062', label: 'Somogysámson' },
      { value: '2063', label: 'Somogysárd' },
      { value: '2064', label: 'Somogysimonyi' },
      { value: '2065', label: 'Somogyszentpál' },
      { value: '2066', label: 'Somogyszob' },
      { value: '2067', label: 'Somogytúr' },
      { value: '2068', label: 'Somogyudvarhely' },
      { value: '2069', label: 'Somogyvámos' },
      { value: '2070', label: 'Somogyzsitfa' },
      { value: '2097', label: 'Szabadi' },
      { value: '2100', label: 'Szabás' },
      { value: '2131', label: 'Szántód' },
      { value: '2174', label: 'Szenta' },
      { value: '2175', label: 'Szentbalázs' },
      { value: '2183', label: 'Szentgáloskér' },
      { value: '2200', label: 'Szenyér' },
      { value: '2223', label: 'Szilvásszentmárton' },
      { value: '2229', label: 'Szólád' },
      { value: '2243', label: 'Szőkedencs' },
      { value: '2249', label: 'Szulok' },
      { value: '2252', label: 'Tab' },
      { value: '2279', label: 'Tapsony' },
      { value: '2282', label: 'Tarany' },
      { value: '2301', label: 'Táska' },
      { value: '2303', label: 'Taszár' },
      { value: '2315', label: 'Teleki' },
      { value: '2319', label: 'Tengőd' },
      { value: '2336', label: 'Tikos' },
      { value: '2466', label: 'Újvárfalva' },
      { value: '2509', label: 'Varászló' },
      { value: '2513', label: 'Várda' },
      { value: '2565', label: 'Vése' },
      { value: '2583', label: 'Visnye' },
      { value: '2590', label: 'Vízvár' },
      { value: '2597', label: 'Vörs' },
      { value: '2604', label: 'Zákány' },
      { value: '2605', label: 'Zákányfalu' },
      { value: '2630', label: 'Zamárdi' },
      { value: '2638', label: 'Zics' },
      { value: '2640', label: 'Zimány' },
      { value: '2653', label: 'Zselickislak' },
      { value: '2654', label: 'Zselicszentpál' }

    ]
  },
  {
    label: 'Szabolcs-Szatmár-Bereg',
    options: [
      { value: '25', label: 'Ajak' },
      { value: '59', label: 'Anarcs' },
      { value: '65', label: 'Apagy' },
      { value: '73', label: 'Aranyosapáti' },
      { value: '134', label: 'Baktalórántháza' },
      { value: '169', label: 'Balkány' },
      { value: '174', label: 'Balsa' },
      { value: '183', label: 'Barabás' },
      { value: '203', label: 'Bátorliget' },
      { value: '230', label: 'Benk' },
      { value: '235', label: 'Beregdaróc' },
      { value: '236', label: 'Beregsurány' },
      { value: '243', label: 'Berkesz' },
      { value: '247', label: 'Besenyőd' },
      { value: '251', label: 'Beszterec' },
      { value: '265', label: 'Biri' },
      { value: '315', label: 'Botpalád' },
      { value: '321', label: 'Bököny' },
      { value: '361', label: 'Buj' },
      { value: '375', label: 'Cégénydányád' },
      { value: '389', label: 'Csaholc' },
      { value: '406', label: 'Csaroda' },
      { value: '409', label: 'Császló' },
      { value: '415', label: 'Csegöld' },
      { value: '423', label: 'Csenger' },
      { value: '424', label: 'Csengersima' },
      { value: '425', label: 'Csengerújfalu' },
      { value: '499', label: 'Demecser' },
      { value: '523', label: 'Dombrád' },
      { value: '534', label: 'Döge' },
      { value: '608', label: 'Encsencs' },
      { value: '613', label: 'Eperjeske' },
      { value: '627', label: 'Érpatak' },
      { value: '639', label: 'Fábiánháza' },
      { value: '652', label: 'Fehérgyarmat' },
      { value: '682', label: 'Fényeslitke' },
      { value: '705', label: 'Fülesd' },
      { value: '710', label: 'Fülpösdaróc' },
      { value: '718', label: 'Gacsály' },
      { value: '737', label: 'Garbolc' },
      { value: '742', label: 'Gávavencsellő' },
      { value: '745', label: 'Gégény' },
      { value: '747', label: 'Gelénes' },
      { value: '748', label: 'Gemzse' },
      { value: '757', label: 'Geszteréd' },
      { value: '780', label: 'Gulács' },
      { value: '809', label: 'Győröcske' },
      { value: '813', label: 'Győrtelek' },
      { value: '821', label: 'Gyulaháza' },
      { value: '825', label: 'Gyügye' },
      { value: '826', label: 'Gyüre' },
      { value: '887', label: 'Hermánszeg' },
      { value: '898', label: 'Hetefejércse' },
      { value: '913', label: 'Hodász' },
      { value: '937', label: 'Ibrány' },
      { value: '948', label: 'Ilk' },
      { value: '980', label: 'Jánd' },
      { value: '981', label: 'Jánkmajtis' },
      { value: '987', label: 'Jármi' },
      { value: '1006', label: 'Jéke' },
      { value: '1025', label: 'Kállósemjén' },
      { value: '1027', label: 'Kálmánháza' },
      { value: '1034', label: 'Kántorjánosi' },
      { value: '1081', label: 'Kék' },
      { value: '1082', label: 'Kékcse' },
      { value: '1089', label: 'Kemecse' },
      { value: '1115', label: 'Kérsemjén' },
      { value: '1139', label: 'Kisar' },
      { value: '1156', label: 'Kishódos' },
      { value: '1168', label: 'Kisléta' },
      { value: '1173', label: 'Kisnamény' },
      { value: '1178', label: 'Kispalád' },
      { value: '1193', label: 'Kisvárda' },
      { value: '1194', label: 'Kisvarsány' },
      { value: '1199', label: 'Kisszekeres' },
      { value: '1206', label: 'Kocsord' },
      { value: '1215', label: 'Komoró' },
      { value: '1228', label: 'Kótaj' },
      { value: '1235', label: 'Kölcse' },
      { value: '1240', label: 'Kömörő' },
      { value: '1295', label: 'Laskod' },
      { value: '1315', label: 'Levelek' },
      { value: '1323', label: 'Lónya' },
      { value: '1332', label: 'Lövőpetri' },
      { value: '1342', label: 'Magosliget' },
      { value: '1343', label: 'Magy' },
      { value: '1370', label: 'Mándok' },
      { value: '1379', label: 'Máriapócs' },
      { value: '1388', label: 'Márokpapi' },
      { value: '1394', label: 'Mátészalka' },
      { value: '1407', label: 'Mátyus' },
      { value: '1419', label: 'Méhtelek' },
      { value: '1426', label: 'Mérk' },
      { value: '1445', label: 'Mezőladány' },
      { value: '1470', label: 'Milota' },
      { value: '1507', label: 'Nábrád' },
      { value: '1514', label: 'Nagyar' },
      { value: '1527', label: 'Nagycserkesz' },
      { value: '1529', label: 'Nagydobos' },
      { value: '1532', label: 'Nagyecsed' },
      { value: '1540', label: 'Nagyhalász' },
      { value: '1545', label: 'Nagykálló' },
      { value: '1583', label: 'Nagyszekeres' },
      { value: '1594', label: 'Nagyvarsány' },
      { value: '1602', label: 'Napkor' },
      { value: '1610', label: 'Nemesborzova' },
      { value: '1662', label: 'Nyírbátor' },
      { value: '1663', label: 'Nyírbéltek' },
      { value: '1664', label: 'Nyírbogát' },
      { value: '1665', label: 'Nyírbogdány' },
      { value: '1666', label: 'Nyírcsaholy' },
      { value: '1667', label: 'Nyírcsászári' },
      { value: '1668', label: 'Nyírderzs' },
      { value: '1669', label: 'Nyíregyháza' },
      { value: '1670', label: 'Nyírgelse' },
      { value: '1671', label: 'Nyírgyulaj' },
      { value: '1673', label: 'Nyíribrony' },
      { value: '1674', label: 'Nyírjákó' },
      { value: '1675', label: 'Nyírkarász' },
      { value: '1676', label: 'Nyírkáta' },
      { value: '1677', label: 'Nyírkércs' },
      { value: '1678', label: 'Nyírlövő' },
      { value: '1679', label: 'Nyírlugos' },
      { value: '1680', label: 'Nyírmada' },
      { value: '1682', label: 'Nyírmeggyes' },
      { value: '1683', label: 'Nyírmihálydi' },
      { value: '1684', label: 'Nyírparasznya' },
      { value: '1685', label: 'Nyírpazony' },
      { value: '1686', label: 'Nyírpilis' },
      { value: '1687', label: 'Nyírtass' },
      { value: '1688', label: 'Nyírtelek' },
      { value: '1689', label: 'Nyírtét' },
      { value: '1690', label: 'Nyírtura' },
      { value: '1691', label: 'Nyírvasvári' },
      { value: '1698', label: 'Ófehértó' },
      { value: '1707', label: 'Olcsva' },
      { value: '1708', label: 'Olcsvaapáti' },
      { value: '1712', label: 'Ópályi' },
      { value: '1737', label: 'Ököritófülpös' },
      { value: '1739', label: 'Ömböly' },
      { value: '1740', label: 'őr' },
      { value: '1777', label: 'Panyola' },
      { value: '1778', label: 'Pap' },
      { value: '1786', label: 'Papos' },
      { value: '1790', label: 'Paszab' },
      { value: '1797', label: 'Pátroha' },
      { value: '1800', label: 'Pátyod' },
      { value: '1810', label: 'Penészlek' },
      { value: '1812', label: 'Penyige' },
      { value: '1826', label: 'Petneháza' },
      { value: '1847', label: 'Piricse' },
      { value: '1852', label: 'Pócspetri' },
      { value: '1859', label: 'Porcsalma' },
      { value: '1874', label: 'Pusztadobos' },
      { value: '1915', label: 'Rakamaz' },
      { value: '1921', label: 'Ramocsaháza' },
      { value: '1922', label: 'Rápolt' },
      { value: '1941', label: 'Rétközberencs' },
      { value: '1954', label: 'Rohod' },
      { value: '1957', label: 'Rozsály' },
      { value: '2027', label: 'Sényő' },
      { value: '2071', label: 'Sonkád' },
      { value: '2101', label: 'Szabolcs' },
      { value: '2102', label: 'Szabolcsbáka' },
      { value: '2103', label: 'Szabolcsveresmart' },
      { value: '2114', label: 'Szakoly' },
      { value: '2123', label: 'Szamosangyalos' },
      { value: '2124', label: 'Szamosbecs' },
      { value: '2125', label: 'Szamoskér' },
      { value: '2126', label: 'Szamostatárfalva' },
      { value: '2127', label: 'Szamosújlak' },
      { value: '2128', label: 'Szamosszeg' },
      { value: '2143', label: 'Szatmárcseke' },
      { value: '2160', label: 'Székely' },
      { value: '2236', label: 'Szorgalmas' },
      { value: '2260', label: 'Tákos' },
      { value: '2300', label: 'Tarpa' },
      { value: '2323', label: 'Terem' },
      { value: '2334', label: 'Tiborszállás' },
      { value: '2337', label: 'Timár' },
      { value: '2339', label: 'Tiszaadony' },
      { value: '2342', label: 'Tiszabecs' },
      { value: '2343', label: 'Tiszabercel' },
      { value: '2344', label: 'Tiszabezdéd' },
      { value: '2347', label: 'Tiszacsécse' },
      { value: '2350', label: 'Tiszadada' },
      { value: '2352', label: 'Tiszadob' },
      { value: '2354', label: 'Tiszaeszlár' },
      { value: '2362', label: 'Tiszakanyár' },
      { value: '2365', label: 'Tiszakerecseny' },
      { value: '2367', label: 'Tiszakóród' },
      { value: '2370', label: 'Tiszalök' },
      { value: '2372', label: 'Tiszamogyorós' },
      { value: '2373', label: 'Tiszanagyfalu' },
      { value: '2378', label: 'Tiszarád' },
      { value: '2382', label: 'Tiszaszalka' },
      { value: '2384', label: 'Tiszaszentmárton' },
      { value: '2389', label: 'Tiszatelek' },
      { value: '2395', label: 'Tiszavasvári' },
      { value: '2396', label: 'Tiszavid' },
      { value: '2397', label: 'Tisztaberek' },
      { value: '2419', label: 'Tornyospálca' },
      { value: '2434', label: 'Tunyogmatolcs' },
      { value: '2436', label: 'Túristvándi' },
      { value: '2438', label: 'Túrricse' },
      { value: '2439', label: 'Tuzsér' },
      { value: '2441', label: 'Tyukod' },
      { value: '2445', label: 'Újdombrád' },
      { value: '2446', label: 'Újfehértó' },
      { value: '2449', label: 'Újkenéz' },
      { value: '2471', label: 'Ura' },
      { value: '2476', label: 'Uszka' },
      { value: '2489', label: 'Vaja' },
      { value: '2495', label: 'Vállaj' },
      { value: '2497', label: 'Vámosatya' },
      { value: '2501', label: 'Vámosoroszi' },
      { value: '2527', label: 'Vásárosnamény' },
      { value: '2534', label: 'Vasmegyer' },
      { value: '2603', label: 'Zajta' },
      { value: '2650', label: 'Zsarolyán' },
      { value: '2658', label: 'Zsurk' }

    ]
  },
  {
    label: 'Tolna',
    options: [
      { value: '43', label: 'Alsónána' },
      { value: '46', label: 'Alsónyék' },
      { value: '89', label: 'Attala' },
      { value: '196', label: 'Báta' },
      { value: '197', label: 'Bátaapáti' },
      { value: '198', label: 'Bátaszék' },
      { value: '223', label: 'Belecska' },
      { value: '263', label: 'Bikács' },
      { value: '289', label: 'Bogyiszló' },
      { value: '299', label: 'Bonyhád' },
      { value: '300', label: 'Bonyhádvarasd' },
      { value: '322', label: 'Bölcske' },
      { value: '383', label: 'Cikó' },
      { value: '444', label: 'Csibrák' },
      { value: '446', label: 'Csikóstőttős' },
      { value: '484', label: 'Dalmand' },
      { value: '494', label: 'Decs' },
      { value: '510', label: 'Diósberény' },
      { value: '522', label: 'Dombóvár' },
      { value: '532', label: 'Döbrököz' },
      { value: '554', label: 'Dunaföldvár' },
      { value: '563', label: 'Dunaszentgyörgy' },
      { value: '572', label: 'Dúzs' },
      { value: '631', label: 'Értény' },
      { value: '641', label: 'Fácánkert' },
      { value: '642', label: 'Fadd' },
      { value: '668', label: 'Felsőnána' },
      { value: '669', label: 'Felsőnyék' },
      { value: '711', label: 'Fürged' },
      { value: '753', label: 'Gerjen' },
      { value: '779', label: 'Grábóc' },
      { value: '802', label: 'Gyönk' },
      { value: '805', label: 'Györe' },
      { value: '807', label: 'Györköny' },
      { value: '822', label: 'Gyulaj' },
      { value: '851', label: 'Harc' },
      { value: '931', label: 'Hőgyész' },
      { value: '960', label: 'Iregszemcse' },
      { value: '973', label: 'Izmény' },
      { value: '1016', label: 'Kajdacs' },
      { value: '1017', label: 'Kakasd' },
      { value: '1021', label: 'Kalaznó' },
      { value: '1042', label: 'Kapospula' },
      { value: '1045', label: 'Kaposszekcső' },
      { value: '1119', label: 'Keszőhidegkút' },
      { value: '1129', label: 'Kéty' },
      { value: '1148', label: 'Kisdorog' },
      { value: '1170', label: 'Kismányok' },
      { value: '1190', label: 'Kistormás' },
      { value: '1195', label: 'Kisvejke' },
      { value: '1198', label: 'Kisszékely' },
      { value: '1205', label: 'Kocsola' },
      { value: '1223', label: 'Koppányszántó' },
      { value: '1236', label: 'Kölesd' },
      { value: '1277', label: 'Kurd' },
      { value: '1294', label: 'Lápafő' },
      { value: '1339', label: 'Madocsa' },
      { value: '1353', label: 'Magyarkeszi' },
      { value: '1413', label: 'Medina' },
      { value: '1475', label: 'Miszla' },
      { value: '1490', label: 'Mórágy' },
      { value: '1498', label: 'Mőcsény' },
      { value: '1499', label: 'Mucsfa' },
      { value: '1500', label: 'Mucsi' },
      { value: '1505', label: 'Murga' },
      { value: '1531', label: 'Nagydorog' },
      { value: '1554', label: 'Nagykónyi' },
      { value: '1568', label: 'Nagymányok' },
      { value: '1582', label: 'Nagyszékely' },
      { value: '1586', label: 'Nagyszokoly' },
      { value: '1597', label: 'Nagyvejke' },
      { value: '1601', label: 'Nak' },
      { value: '1632', label: 'Németkér' },
      { value: '1733', label: 'Ozora' },
      { value: '1735', label: 'őcsény' },
      { value: '1762', label: 'Paks' },
      { value: '1763', label: 'Pálfa' },
      { value: '1844', label: 'Pincehely' },
      { value: '1868', label: 'Pörböly' },
      { value: '1877', label: 'Pusztahencse' },
      { value: '1931', label: 'Regöly' },
      { value: '2009', label: 'Sárpilis' },
      { value: '2013', label: 'Sárszentlőrinc' },
      { value: '2033', label: 'Simontornya' },
      { value: '2034', label: 'Sióagárd' },
      { value: '2109', label: 'Szakadát' },
      { value: '2111', label: 'Szakály' },
      { value: '2112', label: 'Szakcs' },
      { value: '2120', label: 'Szálka' },
      { value: '2135', label: 'Szárazd' },
      { value: '2153', label: 'Szedres' },
      { value: '2165', label: 'Szekszárd' },
      { value: '2268', label: 'Tamási' },
      { value: '2318', label: 'Tengelic' },
      { value: '2332', label: 'Tevel' },
      { value: '2407', label: 'Tolna' },
      { value: '2408', label: 'Tolnanémedi' },
      { value: '2443', label: 'Udvari' },
      { value: '2508', label: 'Váralja' },
      { value: '2514', label: 'Várdomb' },
      { value: '2520', label: 'Varsád' },
      { value: '2634', label: 'Závod' },
      { value: '2643', label: 'Zomba' }

    ]
  },
  {
    label: 'Vas',
    options: [
      { value: '13', label: 'Acsád' },
      { value: '55', label: 'Alsóújlak' },
      { value: '62', label: 'Andrásfa' },
      { value: '68', label: 'Apátistvánfalva' },
      { value: '106', label: 'Bajánsenye' },
      { value: '172', label: 'Balogunyom' },
      { value: '213', label: 'Bejcgyertyános' },
      { value: '233', label: 'Bérbaltavár' },
      { value: '267', label: 'Boba' },
      { value: '316', label: 'Bozsok' },
      { value: '319', label: 'Bögöte' },
      { value: '329', label: 'Bucsu' },
      { value: '364', label: 'Bük' },
      { value: '378', label: 'Celldömölk' },
      { value: '380', label: 'Chernelházadamonya' },
      { value: '392', label: 'Csákánydoroszló' },
      { value: '398', label: 'Csánig' },
      { value: '417', label: 'Csehi' },
      { value: '418', label: 'Csehimindszent' },
      { value: '421', label: 'Csempeszkopács' },
      { value: '427', label: 'Csénye' },
      { value: '430', label: 'Csepreg' },
      { value: '449', label: 'Csipkerek' },
      { value: '471', label: 'Csönge' },
      { value: '474', label: 'Csörötnek' },
      { value: '529', label: 'Dozmat' },
      { value: '530', label: 'Döbörhegy' },
      { value: '539', label: 'Döröske' },
      { value: '549', label: 'Duka' },
      { value: '591', label: 'Egervölgy' },
      { value: '598', label: 'Egyházashetye' },
      { value: '599', label: 'Egyházashollós' },
      { value: '602', label: 'Egyházasrádóc' },
      { value: '661', label: 'Felsőcsatár' },
      { value: '663', label: 'Felsőjánosfa' },
      { value: '666', label: 'Felsőmarác' },
      { value: '676', label: 'Felsőszölnök' },
      { value: '740', label: 'Gasztony' },
      { value: '749', label: 'Gencsapáti' },
      { value: '750', label: 'Gérce' },
      { value: '754', label: 'Gersekarát' },
      { value: '764', label: 'Gór' },
      { value: '784', label: 'Gyanógeregye' },
      { value: '795', label: 'Gyöngyösfalu' },
      { value: '816', label: 'Győrvár' },
      { value: '842', label: 'Halastó' },
      { value: '848', label: 'Halogy' },
      { value: '863', label: 'Hegyfalu' },
      { value: '925', label: 'Horvátzsidány' },
      { value: '928', label: 'Hosszúpereszteg' },
      { value: '943', label: 'Ikervár' },
      { value: '945', label: 'Iklanberény' },
      { value: '962', label: 'Ispánk' },
      { value: '970', label: 'Ivánc' },
      { value: '976', label: 'Ják' },
      { value: '978', label: 'Jákfa' },
      { value: '983', label: 'Jánosháza' },
      { value: '1022', label: 'Káld' },
      { value: '1031', label: 'Kám' },
      { value: '1052', label: 'Karakó' },
      { value: '1086', label: 'Keléd' },
      { value: '1093', label: 'Kemenesmagasi' },
      { value: '1094', label: 'Kemenesmihályfa' },
      { value: '1095', label: 'Kemenespálfa' },
      { value: '1096', label: 'Kemenessömjén' },
      { value: '1097', label: 'Kemenesszentmárton' },
      { value: '1099', label: 'Kemestaródfa' },
      { value: '1101', label: 'Kenéz' },
      { value: '1104', label: 'Kenyeri' },
      { value: '1105', label: 'Kercaszomor' },
      { value: '1180', label: 'Kisrákos' },
      { value: '1183', label: 'Kissomlyó' },
      { value: '1192', label: 'Kisunyom' },
      { value: '1218', label: 'Kondorfa' },
      { value: '1233', label: 'Köcsk' },
      { value: '1242', label: 'Körmend' },
      { value: '1254', label: 'Kőszeg' },
      { value: '1255', label: 'Kőszegdoroszló' },
      { value: '1336', label: 'Lukácsháza' },
      { value: '1355', label: 'Magyarnádalja' },
      { value: '1358', label: 'Magyarszecsőd' },
      { value: '1360', label: 'Magyarszombatfa' },
      { value: '1415', label: 'Megyehid' },
      { value: '1417', label: 'Meggyeskovácsi' },
      { value: '1428', label: 'Mersevát' },
      { value: '1429', label: 'Mesterháza' },
      { value: '1430', label: 'Mesteri' },
      { value: '1433', label: 'Meszlen' },
      { value: '1469', label: 'Mikosszéplak' },
      { value: '1509', label: 'Nádasd' },
      { value: '1536', label: 'Nagygeresd' },
      { value: '1558', label: 'Nagykölked' },
      { value: '1576', label: 'Nagyrákos' },
      { value: '1580', label: 'Nagysimonyi' },
      { value: '1590', label: 'Nagytilaj' },
      { value: '1603', label: 'Nárai' },
      { value: '1604', label: 'Narda' },
      { value: '1611', label: 'Nemesbőd' },
      { value: '1613', label: 'Nemescsó' },
      { value: '1620', label: 'Nemeskeresztúr' },
      { value: '1621', label: 'Nemeskolta' },
      { value: '1622', label: 'Nemesládony' },
      { value: '1623', label: 'Nemesmedves' },
      { value: '1627', label: 'Nemesrempehollós' },
      { value: '1636', label: 'Nick' },
      { value: '1693', label: 'Nyőgér' },
      { value: '1704', label: 'Olaszfa' },
      { value: '1725', label: 'Ostffyasszonyfa' },
      { value: '1727', label: 'Oszkó' },
      { value: '1738', label: 'Ölbő' },
      { value: '1745', label: 'őrimagyarósd' },
      { value: '1758', label: 'Pácsony' },
      { value: '1774', label: 'Pankasz' },
      { value: '1785', label: 'Pápoc' },
      { value: '1816', label: 'Perenye' },
      { value: '1817', label: 'Peresznye' },
      { value: '1830', label: 'Petőmihályfa' },
      { value: '1845', label: 'Pinkamindszent' },
      { value: '1860', label: 'Pornóapáti' },
      { value: '1862', label: 'Porpác' },
      { value: '1865', label: 'Pósfa' },
      { value: '1873', label: 'Pusztacsó' },
      { value: '1893', label: 'Püspökmolnári' },
      { value: '1896', label: 'Rábagyarmat' },
      { value: '1897', label: 'Rábahidvég' },
      { value: '1900', label: 'Rábapaty' },
      { value: '1907', label: 'Rábatöttös' },
      { value: '1927', label: 'Rátót' },
      { value: '1935', label: 'Répcelak' },
      { value: '1937', label: 'Répceszentgyörgy' },
      { value: '1960', label: 'Rönök' },
      { value: '1984', label: 'Sajtoskál' },
      { value: '1987', label: 'Salköveskút' },
      { value: '2016', label: 'Sárvár' },
      { value: '2017', label: 'Sárvár' },
      { value: '2023', label: 'Sé' },
      { value: '2032', label: 'Simaság' },
      { value: '2039', label: 'Sitke' },
      { value: '2079', label: 'Sorokpolány' },
      { value: '2083', label: 'Sótony' },
      { value: '2085', label: 'Söpte' },
      { value: '2115', label: 'Szakonyfalu' },
      { value: '2117', label: 'Szalafő' },
      { value: '2166', label: 'Szeleste' },
      { value: '2170', label: 'Szemenye' },
      { value: '2184', label: 'Szentgotthárd' },
      { value: '2196', label: 'Szentpéterfa' },
      { value: '2206', label: 'Szergény' },
      { value: '2231', label: 'Szombathely' },
      { value: '2232', label: 'Szombathely' },
      { value: '2237', label: 'Szőce' },
      { value: '2269', label: 'Tanakajd' },
      { value: '2278', label: 'Táplánszentkereszt' },
      { value: '2313', label: 'Telekes' },
      { value: '2414', label: 'Tormásliget' },
      { value: '2428', label: 'Tömörd' },
      { value: '2472', label: 'Uraiújfalu' },
      { value: '2498', label: 'Vámoscsalád' },
      { value: '2523', label: 'Vasalja' },
      { value: '2526', label: 'Vásárosmiske' },
      { value: '2528', label: 'Vasasszonyfa' },
      { value: '2530', label: 'Vasegerszeg' },
      { value: '2531', label: 'Vashosszúfalu' },
      { value: '2532', label: 'Vaskeresztes' },
      { value: '2535', label: 'Vassurány' },
      { value: '2536', label: 'Vasvár' },
      { value: '2538', label: 'Vasszécseny' },
      { value: '2539', label: 'Vasszilvágy' },
      { value: '2540', label: 'Vát' },
      { value: '2548', label: 'Velem' },
      { value: '2552', label: 'Vép' },
      { value: '2577', label: 'Vinár' },
      { value: '2586', label: 'Viszák' },
      { value: '2596', label: 'Vönöck' },
      { value: '2652', label: 'Zsédeny' }

    ]
  },
  {
    label: 'Veszprém',
    options: [
      { value: '10', label: 'Ábrahámhegy' },
      { value: '18', label: 'Adásztevel' },
      { value: '20', label: 'Adorjánháza' },
      { value: '26', label: 'Ajka' },
      { value: '47', label: 'Alsóörs' },
      { value: '64', label: 'Apácatorna' },
      { value: '86', label: 'Aszófő' },
      { value: '99', label: 'Badacsonytomaj' },
      { value: '100', label: 'Badacsonytördemic' },
      { value: '115', label: 'Bakonybél' },
      { value: '117', label: 'Bakonygyirót' },
      { value: '118', label: 'Bakonyjákó' },
      { value: '119', label: 'Bakonykoppány' },
      { value: '121', label: 'Bakonynána' },
      { value: '122', label: 'Bakonyoszlop' },
      { value: '123', label: 'Bakonypéterd' },
      { value: '124', label: 'Bakonypölöske' },
      { value: '125', label: 'Bakonyság' },
      { value: '127', label: 'Bakonyszentkirály' },
      { value: '128', label: 'Bakonyszentlászló' },
      { value: '130', label: 'Bakonyszücs' },
      { value: '131', label: 'Bakonytamási' },
      { value: '139', label: 'Balatonakali' },
      { value: '140', label: 'Balatonalmádi' },
      { value: '141', label: 'Balatonalmádi' },
      { value: '144', label: 'Balatonederics' },
      { value: '147', label: 'Balatonfőkajár' },
      { value: '149', label: 'Balatonfőzfő' },
      { value: '150', label: 'Balatonfüred' },
      { value: '152', label: 'Balatonhenye' },
      { value: '153', label: 'Balatonkenese' },
      { value: '159', label: 'Balatonrendes' },
      { value: '164', label: 'Balatonszepezd' },
      { value: '165', label: 'Balatonszőlős' },
      { value: '167', label: 'Balatonvilágos' },
      { value: '177', label: 'Bánd' },
      { value: '207', label: 'Bazsi' },
      { value: '208', label: 'Béb' },
      { value: '214', label: 'Békás' },
      { value: '241', label: 'Berhida' },
      { value: '313', label: 'Borszörcsök' },
      { value: '314', label: 'Borzavár' },
      { value: '388', label: 'Csabrendek' },
      { value: '390', label: 'Csajág' },
      { value: '416', label: 'Csehbánya' },
      { value: '440', label: 'Csesznek' },
      { value: '442', label: 'Csetény' },
      { value: '464', label: 'Csót' },
      { value: '466', label: 'Csögle' },
      { value: '479', label: 'Dabronc' },
      { value: '480', label: 'Dabrony' },
      { value: '483', label: 'Dáka' },
      { value: '508', label: 'Devecser' },
      { value: '514', label: 'Doba' },
      { value: '533', label: 'Döbrönte' },
      { value: '538', label: 'Dörgicse' },
      { value: '548', label: 'Dudar' },
      { value: '600', label: 'Egyházaskesző' },
      { value: '614', label: 'Eplény' },
      { value: '647', label: 'Farkasgyepő' },
      { value: '670', label: 'Felsőörs' },
      { value: '683', label: 'Fenyőfő' },
      { value: '732', label: 'Ganna' },
      { value: '743', label: 'Gecse' },
      { value: '758', label: 'Gic' },
      { value: '760', label: 'Gógánfa' },
      { value: '788', label: 'Gyepükaján' },
      { value: '823', label: 'Gyulakeszi' },
      { value: '840', label: 'Hajmáskér' },
      { value: '845', label: 'Halimba' },
      { value: '857', label: 'Hárskút' },
      { value: '861', label: 'Hegyesd' },
      { value: '865', label: 'Hegymagas' },
      { value: '885', label: 'Herend' },
      { value: '900', label: 'Hetyefő' },
      { value: '908', label: 'Hidegkút' },
      { value: '917', label: 'Homokbödöge' },
      { value: '966', label: 'Iszkáz' },
      { value: '988', label: 'Jásd' },
      { value: '1032', label: 'Kamond' },
      { value: '1036', label: 'Kapolcs' },
      { value: '1047', label: 'Káptalantóti' },
      { value: '1053', label: 'Karakószörcsök' },
      { value: '1084', label: 'Kékkút' },
      { value: '1092', label: 'Kemeneshőgyész' },
      { value: '1098', label: 'Kemenesszentpéter' },
      { value: '1116', label: 'Kerta' },
      { value: '1137', label: 'Királyszentistván' },
      { value: '1146', label: 'Kiscsősz' },
      { value: '1169', label: 'Kislőd' },
      { value: '1179', label: 'Kispirit' },
      { value: '1201', label: 'Kisszőlős' },
      { value: '1209', label: 'Kolontár' },
      { value: '1260', label: 'Köveskál' },
      { value: '1275', label: 'Kup' },
      { value: '1282', label: 'Külsővat' },
      { value: '1283', label: 'Küngös' },
      { value: '1298', label: 'Lázi' },
      { value: '1310', label: 'Lesenceistvánd' },
      { value: '1311', label: 'Lesencetomaj' },
      { value: '1320', label: 'Litér' },
      { value: '1322', label: 'Lókút' },
      { value: '1325', label: 'Lovas' },
      { value: '1328', label: 'Lovászpatona' },
      { value: '1351', label: 'Magyargencs' },
      { value: '1357', label: 'Magyarpolány' },
      { value: '1367', label: 'Malomsok' },
      { value: '1373', label: 'Marcaltő' },
      { value: '1382', label: 'Márkó' },
      { value: '1416', label: 'Megyer' },
      { value: '1422', label: 'Mencshely' },
      { value: '1446', label: 'Mezőlak' },
      { value: '1461', label: 'Mihályháza' },
      { value: '1472', label: 'Mindszentkálla' },
      { value: '1488', label: 'Monoszló' },
      { value: '1513', label: 'Nagyacsád' },
      { value: '1528', label: 'Nagydém' },
      { value: '1534', label: 'Nagyesztergár' },
      { value: '1538', label: 'Nagygyimót' },
      { value: '1589', label: 'Nagytevel' },
      { value: '1596', label: 'Nagyvázsony' },
      { value: '1615', label: 'Nemesgörzsöny' },
      { value: '1616', label: 'Nemesgulács' },
      { value: '1617', label: 'Nemeshany' },
      { value: '1629', label: 'Nemesvita' },
      { value: '1630', label: 'Nemesszalók' },
      { value: '1645', label: 'Noszlop' },
      { value: '1651', label: 'Nyárád' },
      { value: '1660', label: 'Nyirád' },
      { value: '1705', label: 'Olaszfalu' },
      { value: '1720', label: 'Oroszi' },
      { value: '1734', label: 'Öcs' },
      { value: '1750', label: 'Örvényes' },
      { value: '1752', label: 'ősi' },
      { value: '1753', label: 'Öskü' },
      { value: '1771', label: 'Paloznak' },
      { value: '1779', label: 'Pápa' },
      { value: '1780', label: 'Pápadereske' },
      { value: '1781', label: 'Pápakovácsi' },
      { value: '1782', label: 'Pápasalamon' },
      { value: '1783', label: 'Pápateszér' },
      { value: '1784', label: 'Papkeszi' },
      { value: '1805', label: 'Pécsely' },
      { value: '1811', label: 'Pénzesgyőr' },
      { value: '1825', label: 'Pétfürdő' },
      { value: '1864', label: 'Porva' },
      { value: '1880', label: 'Pusztamiske' },
      { value: '1923', label: 'Raposka' },
      { value: '1924', label: 'Raposka' },
      { value: '1943', label: 'Révfülöp' },
      { value: '1955', label: 'Románd' },
      { value: '1985', label: 'Salföld' },
      { value: '2018', label: 'Sáska' },
      { value: '2030', label: 'Sikátor' },
      { value: '2045', label: 'Sóly' },
      { value: '2048', label: 'Somlójenő' },
      { value: '2049', label: 'Somlóvásárhely' },
      { value: '2050', label: 'Somlóvecse' },
      { value: '2091', label: 'Sümeg' },
      { value: '2092', label: 'Sümegprága' },
      { value: '2133', label: 'Szápár' },
      { value: '2176', label: 'Szentbékkálla' },
      { value: '2182', label: 'Szentgál' },
      { value: '2187', label: 'Szentimrefalva' },
      { value: '2191', label: 'Szentkirályszabadja' },
      { value: '2215', label: 'Szigliget' },
      { value: '2257', label: 'Tagyon' },
      { value: '2259', label: 'Takácsi' },
      { value: '2266', label: 'Taliándörögd' },
      { value: '2326', label: 'Tés' },
      { value: '2335', label: 'Tihany' },
      { value: '2424', label: 'Tótvázsony' },
      { value: '2444', label: 'Ugod' },
      { value: '2467', label: 'Ukk' },
      { value: '2475', label: 'Úrkút' },
      { value: '2478', label: 'Uzsa' },
      { value: '2507', label: 'Vanyola' },
      { value: '2517', label: 'Várkesző' },
      { value: '2519', label: 'Várpalota' },
      { value: '2537', label: 'Vaszar' },
      { value: '2567', label: 'Veszprém' },
      { value: '2568', label: 'Veszprémfajsz' },
      { value: '2569', label: 'Veszprémvarsány' },
      { value: '2575', label: 'Vilonya' },
      { value: '2578', label: 'Vinár' },
      { value: '2612', label: 'Zalagyömörő' },
      { value: '2619', label: 'Zalaszegvár' },
      { value: '2632', label: 'Zánka' },
      { value: '2641', label: 'Zirc' }

    ]
  },
  {
    label: 'Zala',
    options: [
      { value: '45', label: 'Alsónemesapáti' },
      { value: '48', label: 'Alsópáhok' },
      { value: '50', label: 'Alsórajk' },
      { value: '109', label: 'Bak' },
      { value: '135', label: 'Baktüttös' },
      { value: '151', label: 'Balatongyörök' },
      { value: '156', label: 'Balatonmagyaród' },
      { value: '180', label: 'Bánokszentgyörgy' },
      { value: '192', label: 'Barlahida' },
      { value: '206', label: 'Batyk' },
      { value: '210', label: 'Becsvölgye' },
      { value: '226', label: 'Belezna' },
      { value: '254', label: 'Bezeréd' },
      { value: '268', label: 'Bocfölde' },
      { value: '271', label: 'Bocska' },
      { value: '305', label: 'Borsfa' },
      { value: '318', label: 'Böde' },
      { value: '325', label: 'Börzönce' },
      { value: '330', label: 'Bucsuta' },
      { value: '402', label: 'Csapi' },
      { value: '438', label: 'Cserszegtomaj' },
      { value: '465', label: 'Csöde' },
      { value: '472', label: 'Csörnyeföld' },
      { value: '513', label: 'Dióskál' },
      { value: '517', label: 'Dobri' },
      { value: '531', label: 'Döbröce' },
      { value: '583', label: 'Egeraracsa' },
      { value: '633', label: 'Eszteregnye' },
      { value: '634', label: 'Esztergályhorváti' },
      { value: '671', label: 'Felsőpáhok' },
      { value: '693', label: 'Fityeház' },
      { value: '717', label: 'Gáborjánháza' },
      { value: '725', label: 'Galambok' },
      { value: '735', label: 'Garabonc' },
      { value: '763', label: 'Gombosszeg' },
      { value: '766', label: 'Gosztola' },
      { value: '778', label: 'Gősfa' },
      { value: '781', label: 'Gutorfölde' },
      { value: '787', label: 'Gyenesdiás' },
      { value: '828', label: 'Hagyárosbörönd' },
      { value: '829', label: 'Hahót' },
      { value: '904', label: 'Hévíz' },
      { value: '930', label: 'Hosszúvölgy' },
      { value: '946', label: 'Iklódbördőce' },
      { value: '1024', label: 'Kallósd' },
      { value: '1072', label: 'Kávás' },
      { value: '1080', label: 'Kehidakustány' },
      { value: '1091', label: 'Kemendollár' },
      { value: '1108', label: 'Kerecseny' },
      { value: '1113', label: 'Kerkabarabás' },
      { value: '1114', label: 'Kerkateskánd' },
      { value: '1120', label: 'Keszthely' },
      { value: '1121', label: 'Keszthely' },
      { value: '1131', label: 'Kilimán' },
      { value: '1189', label: 'Kistolmács' },
      { value: '1200', label: 'Kissziget' },
      { value: '1232', label: 'Kozmadombja' },
      { value: '1279', label: 'Kustánszeg' },
      { value: '1296', label: 'Lasztonya' },
      { value: '1308', label: 'Lenti' },
      { value: '1317', label: 'Lickóvadamos' },
      { value: '1327', label: 'Lovászi' },
      { value: '1384', label: 'Maróc' },
      { value: '1387', label: 'Márokföld' },
      { value: '1458', label: 'Miháld' },
      { value: '1459', label: 'Mihályfa' },
      { value: '1465', label: 'Mikekarácsonyfa' },
      { value: '1483', label: 'Molnári' },
      { value: '1503', label: 'Murakeresztúr' },
      { value: '1504', label: 'Muraszemenye' },
      { value: '1517', label: 'Nagybakónak' },
      { value: '1537', label: 'Nagygörbő' },
      { value: '1547', label: 'Nagykanizsa' },
      { value: '1548', label: 'Nagykanizsa' },
      { value: '1549', label: 'Nagykapornak' },
      { value: '1561', label: 'Nagykutas' },
      { value: '1563', label: 'Nagylengyel' },
      { value: '1572', label: 'Nagypáli' },
      { value: '1575', label: 'Nagyrada' },
      { value: '1608', label: 'Nemesapáti' },
      { value: '1612', label: 'Nemesbük' },
      { value: '1618', label: 'Nemeshetés' },
      { value: '1625', label: 'Nemespátró' },
      { value: '1626', label: 'Nemesrádó' },
      { value: '1631', label: 'Németfalu' },
      { value: '1700', label: 'Óhid' },
      { value: '1709', label: 'Oltárc' },
      { value: '1722', label: 'Orosztony' },
      { value: '1723', label: 'Ortaháza' },
      { value: '1732', label: 'Ozmánbük' },
      { value: '1760', label: 'Pakod' },
      { value: '1764', label: 'Pálfiszeg' },
      { value: '1831', label: 'Petrivente' },
      { value: '1863', label: 'Pórszombat' },
      { value: '1866', label: 'Pölöske' },
      { value: '1867', label: 'Pölöskefő' },
      { value: '1869', label: 'Pördefölde' },
      { value: '1878', label: 'Pusztamagyaród' },
      { value: '1885', label: 'Pusztaszentlászló' },
      { value: '1920', label: 'Ramocsa' },
      { value: '1939', label: 'Resznek' },
      { value: '1945', label: 'Rezi' },
      { value: '1947', label: 'Rigyác' },
      { value: '1988', label: 'Salomvár' },
      { value: '1991', label: 'Sand' },
      { value: '1999', label: 'Sárhida' },
      { value: '2006', label: 'Sármellék' },
      { value: '2025', label: 'Semjénháza' },
      { value: '2026', label: 'Sénye' },
      { value: '2078', label: 'Sormás' },
      { value: '2084', label: 'Söjtör' },
      { value: '2185', label: 'Szentgyörgyvár' },
      { value: '2186', label: 'Szentgyörgyvölgy' },
      { value: '2197', label: 'Szentpéterfölde' },
      { value: '2199', label: 'Szentpéterúr' },
      { value: '2201', label: 'Szepetnek' },
      { value: '2413', label: 'Tormafölde' },
      { value: '2417', label: 'Tornyiszentmiklós' },
      { value: '2422', label: 'Tótszentmárton' },
      { value: '2423', label: 'Tótszerdahely' },
      { value: '2440', label: 'Türje' },
      { value: '2465', label: 'Újudvar' },
      { value: '2496', label: 'Vállus' },
      { value: '2529', label: 'Vasboldogasszony' },
      { value: '2579', label: 'Vindornyafok' },
      { value: '2580', label: 'Vindornyalak' },
      { value: '2581', label: 'Vindornyaszőlős' },
      { value: '2594', label: 'Vonyarcvashegy' },
      { value: '2607', label: 'Zalaapáti' },
      { value: '2608', label: 'Zalabér' },
      { value: '2609', label: 'Zalacsány' },
      { value: '2610', label: 'Zalacséb' },
      { value: '2611', label: 'Zalaegerszeg' },
      { value: '2613', label: 'Zalaháshágy' },
      { value: '2614', label: 'Zalaigrice' },
      { value: '2615', label: 'Zalaistvánd' },
      { value: '2616', label: 'Zalakaros' },
      { value: '2617', label: 'Zalakomár' },
      { value: '2618', label: 'Zalaszabar' },
      { value: '2620', label: 'Zalaszentgrót' },
      { value: '2621', label: 'Zalaszentiván' },
      { value: '2622', label: 'Zalaszentjakab' },
      { value: '2623', label: 'Zalaszentmihály' },
      { value: '2625', label: 'Zalatárnok' },
      { value: '2626', label: 'Zalaújlak' },
      { value: '2627', label: 'Zalavár' },
      { value: '2628', label: 'Zalavég' }

    ]
  }

  ]

  const offerEventType = [
    { value: 'wending', label: t('common:filter.offerEventType.wending') },
    { value: 'bachelor', label: t('common:filter.offerEventType.bachelor') },
    { value: 'conference', label: t('common:filter.offerEventType.conference') },
    { value: 'team-building', label: t('common:filter.offerEventType.team-building') },
    { value: 'other', label: t('common:filter.offerEventType.other') }
  ]

  const offerEventCategory = [
    { value: 'spring', label: t('common:filter.offerEventCategory.spring') },
    { value: 'summer', label: t('common:filter.offerEventCategory.summer') },
    { value: 'autumn', label: t('common:filter.offerEventCategory.autumn') },
    { value: 'winter', label: t('common:filter.offerEventCategory.winter') },
    { value: 'corporate', label: t('common:filter.offerEventCategory.corporate') },
    { value: 'christmas', label: t('common:filter.offerEventCategory.christmas') },
    { value: 'new-year', label: t('common:filter.offerEventCategory.new-year') },
    { value: 'carnival', label: t('common:filter.offerEventCategory.carnival') },
    { value: 'easter', label: t('common:filter.offerEventCategory.easter') }

  ]

  const offerType = [
    { value: 'accomodation-and-catering', label: t('common:filter.offerType.accomodation-and-catering') },
    { value: 'menu', label: t('common:filter.offerType.menu') },
    { value: 'room-rental', label: t('common:filter.offerType.room-rental') },
    { value: 'accessories-tools', label: t('common:filter.offerType.accessories-tools') },
    { value: 'total-sum-billed', label: t('common:filter.offerType.total-sum-billed') },
    { value: 'other', label: t('common:filter.offerType.other') },
    { value: 'event-package', label: t('common:filter.offerType.event-package') },
    { value: 'accommodation', label: t('common:filter.offerType.accommodation') }

  ]
  return {
    specialPlacesSelect,
    regionTypes,
    eventTypes,
    placeTypes,
    cityTypes,
    conditionTypes,
    locationTypes,
    cateringTypes,
    allCityTypes,
    offerEventType,
    offerEventCategory,
    offerType
  }
}

export default useFilterType
