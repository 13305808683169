"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserLoginToJSON = exports.UserLoginFromJSONTyped = exports.UserLoginFromJSON = exports.instanceOfUserLogin = void 0;
/**
 * Check if a given object implements the UserLogin interface.
 */
function instanceOfUserLogin(value) {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "password" in value;
    return isInstance;
}
exports.instanceOfUserLogin = instanceOfUserLogin;
function UserLoginFromJSON(json) {
    return UserLoginFromJSONTyped(json, false);
}
exports.UserLoginFromJSON = UserLoginFromJSON;
function UserLoginFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'email': json['email'],
        'password': json['password'],
    };
}
exports.UserLoginFromJSONTyped = UserLoginFromJSONTyped;
function UserLoginToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'email': value.email,
        'password': value.password,
    };
}
exports.UserLoginToJSON = UserLoginToJSON;
