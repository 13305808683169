"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpecialOfferResourceToJSON = exports.SpecialOfferResourceFromJSONTyped = exports.SpecialOfferResourceFromJSON = exports.instanceOfSpecialOfferResource = void 0;
const SpecialOfferResourceDataInner_1 = require("./SpecialOfferResourceDataInner");
const SpecialOfferResourceMeta_1 = require("./SpecialOfferResourceMeta");
/**
 * Check if a given object implements the SpecialOfferResource interface.
 */
function instanceOfSpecialOfferResource(value) {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;
    return isInstance;
}
exports.instanceOfSpecialOfferResource = instanceOfSpecialOfferResource;
function SpecialOfferResourceFromJSON(json) {
    return SpecialOfferResourceFromJSONTyped(json, false);
}
exports.SpecialOfferResourceFromJSON = SpecialOfferResourceFromJSON;
function SpecialOfferResourceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'data': (json['data'].map(SpecialOfferResourceDataInner_1.SpecialOfferResourceDataInnerFromJSON)),
        'meta': (0, SpecialOfferResourceMeta_1.SpecialOfferResourceMetaFromJSON)(json['meta']),
    };
}
exports.SpecialOfferResourceFromJSONTyped = SpecialOfferResourceFromJSONTyped;
function SpecialOfferResourceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'data': (value.data.map(SpecialOfferResourceDataInner_1.SpecialOfferResourceDataInnerToJSON)),
        'meta': (0, SpecialOfferResourceMeta_1.SpecialOfferResourceMetaToJSON)(value.meta),
    };
}
exports.SpecialOfferResourceToJSON = SpecialOfferResourceToJSON;
