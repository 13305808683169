"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VenueResourceAccommedationToJSON = exports.VenueResourceAccommedationFromJSONTyped = exports.VenueResourceAccommedationFromJSON = exports.instanceOfVenueResourceAccommedation = void 0;
const runtime_1 = require("../runtime");
const VenueResourceAccommedationDetailsInner_1 = require("./VenueResourceAccommedationDetailsInner");
/**
 * Check if a given object implements the VenueResourceAccommedation interface.
 */
function instanceOfVenueResourceAccommedation(value) {
    let isInstance = true;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "details" in value;
    isInstance = isInstance && "bedSum" in value;
    return isInstance;
}
exports.instanceOfVenueResourceAccommedation = instanceOfVenueResourceAccommedation;
function VenueResourceAccommedationFromJSON(json) {
    return VenueResourceAccommedationFromJSONTyped(json, false);
}
exports.VenueResourceAccommedationFromJSON = VenueResourceAccommedationFromJSON;
function VenueResourceAccommedationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'description': json['description'],
        'details': (json['details'].map(VenueResourceAccommedationDetailsInner_1.VenueResourceAccommedationDetailsInnerFromJSON)),
        'bedSum': json['bed_sum'],
        'roomSum': !(0, runtime_1.exists)(json, 'room_sum') ? undefined : json['room_sum'],
    };
}
exports.VenueResourceAccommedationFromJSONTyped = VenueResourceAccommedationFromJSONTyped;
function VenueResourceAccommedationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'description': value.description,
        'details': (value.details.map(VenueResourceAccommedationDetailsInner_1.VenueResourceAccommedationDetailsInnerToJSON)),
        'bed_sum': value.bedSum,
        'room_sum': value.roomSum,
    };
}
exports.VenueResourceAccommedationToJSON = VenueResourceAccommedationToJSON;
