"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VenueResourcePlaceEventsInnerToJSON = exports.VenueResourcePlaceEventsInnerFromJSONTyped = exports.VenueResourcePlaceEventsInnerFromJSON = exports.instanceOfVenueResourcePlaceEventsInner = void 0;
const runtime_1 = require("../runtime");
const VenueResourcePlaceEventsInnerItemsInner_1 = require("./VenueResourcePlaceEventsInnerItemsInner");
/**
 * Check if a given object implements the VenueResourcePlaceEventsInner interface.
 */
function instanceOfVenueResourcePlaceEventsInner(value) {
    let isInstance = true;
    return isInstance;
}
exports.instanceOfVenueResourcePlaceEventsInner = instanceOfVenueResourcePlaceEventsInner;
function VenueResourcePlaceEventsInnerFromJSON(json) {
    return VenueResourcePlaceEventsInnerFromJSONTyped(json, false);
}
exports.VenueResourcePlaceEventsInnerFromJSON = VenueResourcePlaceEventsInnerFromJSON;
function VenueResourcePlaceEventsInnerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'items': !(0, runtime_1.exists)(json, 'items') ? undefined : (json['items'].map(VenueResourcePlaceEventsInnerItemsInner_1.VenueResourcePlaceEventsInnerItemsInnerFromJSON)),
    };
}
exports.VenueResourcePlaceEventsInnerFromJSONTyped = VenueResourcePlaceEventsInnerFromJSONTyped;
function VenueResourcePlaceEventsInnerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'items': value.items === undefined ? undefined : (value.items.map(VenueResourcePlaceEventsInnerItemsInner_1.VenueResourcePlaceEventsInnerItemsInnerToJSON)),
    };
}
exports.VenueResourcePlaceEventsInnerToJSON = VenueResourcePlaceEventsInnerToJSON;
