"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilteredVenuesResourceDataInnerRoomsToJSON = exports.FilteredVenuesResourceDataInnerRoomsFromJSONTyped = exports.FilteredVenuesResourceDataInnerRoomsFromJSON = exports.instanceOfFilteredVenuesResourceDataInnerRooms = void 0;
/**
 * Check if a given object implements the FilteredVenuesResourceDataInnerRooms interface.
 */
function instanceOfFilteredVenuesResourceDataInnerRooms(value) {
    let isInstance = true;
    isInstance = isInstance && "benches" in value;
    isInstance = isInstance && "uShaped" in value;
    isInstance = isInstance && "standing" in value;
    isInstance = isInstance && "banquet" in value;
    return isInstance;
}
exports.instanceOfFilteredVenuesResourceDataInnerRooms = instanceOfFilteredVenuesResourceDataInnerRooms;
function FilteredVenuesResourceDataInnerRoomsFromJSON(json) {
    return FilteredVenuesResourceDataInnerRoomsFromJSONTyped(json, false);
}
exports.FilteredVenuesResourceDataInnerRoomsFromJSON = FilteredVenuesResourceDataInnerRoomsFromJSON;
function FilteredVenuesResourceDataInnerRoomsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'benches': json['benches'],
        'uShaped': json['u_shaped'],
        'standing': json['standing'],
        'banquet': json['banquet'],
    };
}
exports.FilteredVenuesResourceDataInnerRoomsFromJSONTyped = FilteredVenuesResourceDataInnerRoomsFromJSONTyped;
function FilteredVenuesResourceDataInnerRoomsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'benches': value.benches,
        'u_shaped': value.uShaped,
        'standing': value.standing,
        'banquet': value.banquet,
    };
}
exports.FilteredVenuesResourceDataInnerRoomsToJSON = FilteredVenuesResourceDataInnerRoomsToJSON;
